import { useTranslation } from 'react-i18next';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';

import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import IntentCheckboxList from '../../IntentCheckboxList/IntentCheckboxList';

import './feature-intent-hero-block.scss';

export interface IStat {
    id: string;
    title: JSX.Element;
    subtitle: JSX.Element;
}
interface Props {
    title: string;
    subtitle: string;
    imgUrl: string;
}

const FeatureIntentHeroBlock = ({ title, subtitle, imgUrl }: Props) => {
    const { t } = useTranslation();
    return (
        <FeatureBlockSection customClass="feature-intent-hero-block">
            <FeatureBlockContent customClass="feature-intent-hero-block__content">
                <div className="feature-intent-hero-block__split-layout">
                    <div className="feature-intent-hero-block__text-container">
                        <HBText
                            tag="h1"
                            type="title-m"
                            customClass="feature-intent-hero-block__title"
                        >
                            {title}
                        </HBText>
                        <HBText
                            tag="p"
                            type="body-xl"
                            customClass="feature-intent-hero-block__subtitle"
                        >
                            {subtitle}
                        </HBText>
                        <IntentCheckboxList
                            title={t('featureIntentHeroBlock.intentTitle')}
                        />
                    </div>

                    <HBMediaElement
                        customClass="feature-intent-hero-block__illustration"
                        name={imgUrl}
                    />
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureIntentHeroBlock;

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';

import Layout from '../components/Layout/Layout';
import MobileStickyCTA from '../components/MobileStickyCTA/MobileStickyCTA';
import VerticalsPaidHero from '../components/Hero/VerticalsPaidHero/VerticalPaidHero';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlockNew';
import TestimonialCardsBlock from '../components/FeatureBlocks/TestimonialCardsBlock/TestimonialCardsBlock';
import ShowcaseServicesBlock from '../components/FeatureBlocks/ShowcaseServicesBlock/ShowcaseServicesBlock';
import FeaturedIn from '../components/FeaturedIn/FeaturedIn';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';

import verticalPaidStore from '../stores/verticalPaidStore.js';

import '../styles/vertical-paid.scss';

const GENERIC_SHOWCASE_VIDEO_URL =
    'marketing_site/vertical-lps/generic-showcase-desktop';
const GENERIC_SHOWCASE_VIDEO_MOBILE_URL =
    'marketing_site/vertical-lps/generic-showcase-mobile';

const VerticalsPaidPage = ({ pageContext: { contentKey } }) => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const { values } = verticalPaidStore['generic'];
    const { ogTags, quotes, showcaseServices } = verticalPaidStore[contentKey];

    return (
        <div className={`vertical-paid vertical-paid--${contentKey}`}>
            <Layout ogTags={ogTags} slimNav slimFooter>
                <MobileStickyCTA />
                <VerticalsPaidHero contentKey={contentKey} />
                <FeatureValuePropsBlock
                    values={values}
                    title={t('featureValuePropNew.title')}
                    titleTop
                    minimal
                />
                <TestimonialCardsBlock
                    quotes={quotes}
                    title={t(`${contentKey}.testimonialCardsBlock.title`)}
                    hideReviewsLink
                    showStarReviews
                />
                <ShowcaseServicesBlock
                    videoUrl={
                        showcaseServices?.videoUrl || GENERIC_SHOWCASE_VIDEO_URL
                    }
                    videoMobileUrl={
                        showcaseServices?.videoMobileUrl ||
                        GENERIC_SHOWCASE_VIDEO_MOBILE_URL
                    }
                    showRegistration
                />
                <FeaturedIn />
                <PromoBlockLeft
                    customClass="vertical-paid__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
            </Layout>
        </div>
    );
};

export default VerticalsPaidPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "verticalPaid"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

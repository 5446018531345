import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout/Layout';
import HBButton, { ButtonStyles } from '@honeybook-ui/HBButton/HBButton';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import PictureAndListBlock from '../components/FeatureBlocks/PictureAndListBlock/PictureAndListBlock';
import TextLinksBlock from '../components/FeatureBlocks/TextLinksBlock/TextLinksBlock';
import FeatureGridBlock from '../components/FeatureBlocks/FeatureGridBlock/FeatureGridBlock';
import BlogLinkBlock from '../components/BlogLinkBlock/BlogLinkBlock';

import '../styles/resources.scss';

const blogLinks = [
    {
        titleId: 'resourcesPage.blogLinks.coronaSteps',
        imagePath: 'marketing_site/resources/blog1.jpg',
        url: '/risingtide/business-continuity-plan-for-small-business',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.coronaResponding',
        imagePath: 'marketing_site/resources/blog2.jpg',
        url: '/risingtide/responding-to-coronavirus',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.coronaClause',
        imagePath: 'marketing_site/resources/blog3.jpg',
        url: '/risingtide/force-majeure-clause-sample',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.coronaHealth',
        imagePath: 'marketing_site/resources/blog4.jpg',
        url: '/risingtide/coronavirus-mental-health',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.coronaRelief',
        imagePath: 'marketing_site/resources/blog5.jpg',
        url: '/risingtide/small-business-relief',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.coronaCancellations',
        imagePath: 'marketing_site/resources/cancel_events.jpg',
        url: '/risingtide/how-to-prevent-event-cancellations',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.reopen',
        imagePath: 'marketing_site/blog-images/reopening.jpg',
        url: '/risingtide/reopening-business-after-covid-19',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.ppp',
        imagePath: 'marketing_site/blog-images/PPP.jpg',
        url: '/risingtide/second-round-ppp',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    },
    {
        titleId: 'resourcesPage.blogLinks.stimulus',
        imagePath: 'marketing_site/blog-images/self_employed_unemploymen.jpg',
        url: '/risingtide/self-employed-unemployment-coronavirus-stimulus-package',
        mediaFlags: {
            width: 300,
            height: 200,
            crop: 'scale'
        }
    }
];

const toolsLinks = [
    {
        text: 'resourcesPage.freeTools.personalityTest',
        url: '/business-personality-test'
    },
    {
        text: 'resourcesPage.freeTools.email',
        url: '/email-signature'
    },
    {
        text: 'resourcesPage.freeTools.missionStatement',
        url: '/mission-statement'
    },
    {
        text: 'resourcesPage.freeTools.invoices',
        url: '/invoice-template'
    },
    {
        text: 'resourcesPage.freeTools.chromeExtension',
        url: 'https://chrome.google.com/webstore/detail/daily-quote-tab/gbfojppgnjfmoemnjfjineipciehlbdi'
    },
    {
        text: 'resourcesPage.freeTools.businessGuide',
        url: '/risingtide/tuesdaystogether-guides'
    },
    {
        text: 'resourcesPage.invoiceHub',
        url: '/free-invoice-templates'
    },
    {
        text: 'resourcesPage.contractsFor.photoContract',
        url: '/photography-contract-template'
    },
    {
        text: 'resourcesPage.contractsFor.consultantContract',
        url: '/freelance-consulting-contract-template'
    },
    {
        text: 'resourcesPage.contractsFor.designerContract',
        url: '/designer-contract-template'
    },
    {
        text: 'resourcesPage.contractsHub',
        url: '/free-contract-templates'
    }
];

const businessTypesLinks = [
    {
        text: 'resourcesPage.businessTypes.DJ',
        url: '/dj-booking-software'
    },
    {
        text: 'resourcesPage.businessTypes.venues',
        url: '/venue-management-software'
    },
    {
        text: 'resourcesPage.businessTypes.florists',
        url: '/software-for-florists'
    },
    {
        text: 'resourcesPage.businessTypes.consultants',
        url: '/crm-for-consultants'
    },
    {
        text: 'resourcesPage.businessTypes.freelancers',
        url: '/crm-for-freelancers'
    },
    {
        text: 'resourcesPage.businessTypes.healthCoaches',
        url: '/health-coach-software'
    },
    {
        text: 'resourcesPage.businessTypes.caterers',
        url: '/catering-management-software'
    },
    {
        text: 'resourcesPage.businessTypes.doulas',
        url: '/doula-business-software'
    },
    {
        text: 'resourcesPage.businessTypes.eventPlanners',
        url: '/software-for-event-planners'
    },
    {
        text: 'resourcesPage.businessTypes.marketing',
        url: '/marketing-consultant-software'
    },
    {
        text: 'resourcesPage.businessTypes.designers',
        url: '/crm-for-designers'
    },
    {
        text: 'resourcesPage.businessTypes.graphicDesigners',
        url: '/crm-for-graphic-designers'
    },
    {
        text: 'resourcesPage.businessTypes.photographers',
        url: '/crm-for-photographers'
    },
    {
        text: 'resourcesPage.businessTypes.contractors',
        url: '/contractor-billing-software'
    },
    {
        text: 'resourcesPage.businessTypes.education',
        url: '/tutor-software'
    },
    {
        text: 'resourcesPage.businessTypes.virtualAssistant',
        url: '/virtual-assistant-software'
    },
    {
        text: 'resourcesPage.businessTypes.realEstate',
        url: '/real-estate-crm-software'
    },
    {
        text: 'resourcesPage.businessTypes.socialMedia',
        url: '/social-media-crm'
    },
    {
        text: 'resourcesPage.businessTypes.lawn',
        url: '/crm-for-landscapers'
    },
    {
        text: 'resourcesPage.businessTypes.homeAccountant',
        url: '/software-for-home-accountants'
    },
    {
        text: 'resourcesPage.businessTypes.interiorDesign',
        url: '/interior-design-business-software'
    },
    {
        text: 'resourcesPage.businessTypes.digitalMarketing',
        url: '/tools-for-digital-marketers'
    },
    {
        text: 'resourcesPage.businessTypes.copywriting',
        url: '/software-for-copywriters'
    },
    {
        text: 'resourcesPage.businessTypes.lifeCoach',
        url: '/life-coach-software'
    },
    {
        text: 'resourcesPage.businessTypes.cleaning',
        url: '/software-for-cleaning-business'
    },
    {
        text: 'resourcesPage.businessTypes.onlineCoaching',
        url: '/software-for-online-coaching'
    },
    {
        text: 'resourcesPage.businessTypes.dogTrainers',
        url: '/software-for-dog-trainers'
    },
    {
        text: 'resourcesPage.businessTypes.visualArtists',
        url: '/software-for-visual-artists'
    },
    {
        text: 'resourcesPage.businessTypes.PRCompanies',
        url: '/software-for-pr-companies'
    },
    {
        text: 'resourcesPage.businessTypes.bandManagers',
        url: '/software-for-band-managers'
    },
    {
        text: 'resourcesPage.businessTypes.webDesigners',
        url: '/software-for-web-designers'
    },
    {
        text: 'resourcesPage.businessTypes.agencies',
        url: '/software-for-agencies'
    },
    {
        text: 'resourcesPage.businessTypes.franchises',
        url: '/software-for-franchises'
    }
];

const invoicesFor = [
    {
        text: 'resourcesPage.invoicesFor.doula',
        url: '/free-invoice-templates/doula'
    },
    {
        text: 'resourcesPage.invoicesFor.design',
        url: '/free-invoice-templates/designers'
    },
    {
        text: 'resourcesPage.invoicesFor.graphicDesign',
        url: '/free-invoice-templates/graphic-design'
    },
    {
        text: 'resourcesPage.invoicesFor.interiorDesign',
        url: '/free-invoice-templates/interior-design'
    },
    {
        text: 'resourcesPage.invoicesFor.coach',
        url: '/free-invoice-templates/coach'
    },
    {
        text: 'resourcesPage.invoicesFor.consultant',
        url: '/free-invoice-templates/consultants'
    },
    {
        text: 'resourcesPage.invoicesFor.photographers',
        url: '/free-invoice-templates/photographers'
    },
    {
        text: 'resourcesPage.invoicesFor.tutors',
        url: '/free-invoice-templates/tutors'
    },
    {
        text: 'resourcesPage.invoicesFor.cleaners',
        url: '/free-invoice-templates/cleaners'
    },
    {
        text: 'resourcesPage.invoicesFor.virtualAssistant',
        url: '/free-invoice-templates/virtual-assistant'
    }
];

const versusLinks = [
    {
        text: 'resourcesPage.versus.dubsado',
        url: '/honeybook-vs-dubsado'
    },
    {
        text: 'resourcesPage.versus.freshbooks',
        url: 'honeybook-vs-freshbooks'
    },
    {
        text: 'resourcesPage.versus.seventeenHats',
        url: 'honeybook-vs-17hats'
    },
    {
        text: 'resourcesPage.versus.monday',
        url: 'honeybook-vs-monday'
    },
    {
        text: 'resourcesPage.versus.bonsai',
        url: 'honeybook-vs-bonsai'
    },
    {
        text: 'resourcesPage.versus.vcita',
        url: 'honeybook-vs-vcita'
    }
];

function ResourcesTemplate() {
    const { t } = useTranslation();

    const ogTags = {
        url: 'https://www.honeybook.com/resources',
        title: 'Small Business Resources',
        description: `From strategies, resources, tools, tips, and free invoice templates, HoneyBook's small business resources are designed to help your business flourish.`
    };

    return (
        <div className="resources-lp">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    showRegistration
                    image="marketing_site/resources/hero-prod-edu-img_2x.png"
                    mainTitle={t('resourcesPage.hero.title')}
                    description={t('resourcesPage.hero.subtitle')}
                />

                <TextLinksBlock
                    lightBackground
                    title={t('resourcesPage.freeTools.title')}
                    links={toolsLinks}
                />

                <PictureAndListBlock
                    title="resourcesPage.featureTitleList.corona.title"
                    listName="resourcesPage.featureTitleList.corona.list"
                    image="marketing_site/resources/prepare_for_corona.jpg"
                    imageWidth={500}
                >
                    <a href="/risingtide/business-continuity-plan-for-small-business">
                        <HBButton
                            size="small"
                            text={t(
                                'resourcesPage.featureTitleList.corona.ctaText'
                            )}
                            buttonStyle={ButtonStyles.PLAIN_PRIMARY}
                        />
                    </a>
                </PictureAndListBlock>

                <TextLinksBlock
                    lightBackground
                    title={t('resourcesPage.invoicesFor.title')}
                    links={invoicesFor}
                />

                <BlogLinkBlock
                    title={t('resourcesPage.blogLinks.title')}
                    blogs={blogLinks}
                />

                <TextLinksBlock
                    lightBackground
                    title={t('resourcesPage.businessTypes.title')}
                    links={businessTypesLinks}
                />

                <TextLinksBlock
                    lightBackground
                    title={t('resourcesPage.versus.title')}
                    links={versusLinks}
                />

                <FeatureGridBlock title={t('resourcesPage.featureGridTitle')} />
            </Layout>
        </div>
    );
}

export default ResourcesTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "resources", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

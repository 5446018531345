import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../FeatureBlockElements';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../honeybook-ui/HBText/HBText';
import HBButton from '../honeybook-ui/HBButton/HBButton';

import ArrowLeftIcon from '../../svg/common/arrow-left.inline.svg';
import ArrowRightIcon from '../../svg/common/arrow-right.inline.svg';

import AnalyticsService from '../../Services/AnalyticsService';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './email-signature-template-gallery.scss';

interface TTemplateData {
    templateName: string;
    imgUrl: string;
}

const TEMPLATE_DATA_DESKTOP: TTemplateData[] = [
    {
        templateName: 'crisp',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-1.png'
    },
    {
        templateName: 'conservative',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-2.png'
    },
    {
        templateName: 'cool',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-3.png'
    },
    {
        templateName: 'romantic',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-4.png'
    },
    {
        templateName: 'cool',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-5.png'
    },
    {
        templateName: 'cute',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-6.png'
    }
];

const TEMPLATE_DATA_MWEB: TTemplateData[] = [
    {
        templateName: 'crisp',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-mweb-1.png'
    },
    {
        templateName: 'conservative',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-mweb-2.png'
    },
    {
        templateName: 'cool',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-mweb-3.png'
    },
    {
        templateName: 'romantic',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-mweb-4.png'
    },
    {
        templateName: 'cute',
        imgUrl: 'marketing_site/email-signature/template-gallery/Signature_preview-mweb-5.png'
    }
];

interface Props {
    setSelectedTemplateName: (selectedTemplateName: string) => void;
    isMobile?: boolean;
}

function EmailSignatureTemplateGallery({
    setSelectedTemplateName,
    isMobile = false
}: Props) {
    const { t } = useTranslation();

    const onSelectTemplate = templateName => {
        const previewTop = document
            .querySelector('.email-signature-block__preview-container')
            ?.getBoundingClientRect()?.top;
        if (previewTop) {
            window.scrollTo({
                top: window.scrollY + previewTop - 90,
                behavior: 'smooth'
            });
        }

        setSelectedTemplateName(templateName);
    };

    return (
        <FeatureBlockSection
            customClass={`email-signature-template-gallery email-signature-template-gallery--${
                isMobile ? 'mobile' : 'desktop'
            }`}
        >
            <FeatureBlockContent customClass="email-signature-template-gallery__content">
                {!isMobile && (
                    <>
                        <HBText
                            customClass="email-signature-template-gallery__title"
                            tag="h2"
                            type="title-m"
                        >
                            {t('emailSignatureTemplateGallery.title')}
                        </HBText>
                        <HBText
                            customClass="email-signature-template-gallery__subtitle"
                            tag="p"
                            type="body-l"
                        >
                            {t('emailSignatureTemplateGallery.subtitle')}
                        </HBText>
                    </>
                )}

                {isMobile ? (
                    <TemplateList
                        customClass="mobile"
                        onSelectTemplate={onSelectTemplate}
                        templates={TEMPLATE_DATA_MWEB}
                        slidesPerView={1}
                    />
                ) : (
                    <TemplateList
                        customClass="desktop"
                        onSelectTemplate={onSelectTemplate}
                        templates={TEMPLATE_DATA_DESKTOP}
                        slidesPerView={3}
                    />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

interface TTemplateList {
    customClass?: string;
    onSelectTemplate: (template) => void;
    templates: TTemplateData[];
    slidesPerView: number;
}

const TemplateList = ({
    customClass,
    onSelectTemplate,
    templates,
    slidesPerView
}: TTemplateList) => {
    const className = `
      email-signature-template-gallery__template-list
      email-signature-template-gallery__template-list--${customClass}
    `;

    return (
        <div className={className}>
            <Swiper
                loop
                pagination
                navigation={{
                    prevEl: '.swiper-navigation--prev',
                    nextEl: '.swiper-navigation--next'
                }}
                slidesPerView={slidesPerView}
                modules={[Pagination, Navigation]}
                color="#131416"
            >
                {templates.map(template => (
                    <SwiperSlide
                        className="email-signature-template-gallery__template-preview-slide"
                        key={template.imgUrl}
                    >
                        <div className="email-signature-template-gallery__template-preview">
                            <HBMediaElement
                                customClass="email-signature-template-gallery__template-img"
                                name={template.imgUrl}
                                lazyLoad={false}
                            />
                            <HBButton
                                customClass="email-signature-template-gallery__template-btn"
                                text="Try this template"
                                buttonStyle="primary"
                                onClick={() =>
                                    onSelectTemplate(template.templateName)
                                }
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <ArrowLeftIcon className="swiper-navigation swiper-navigation--prev" />
            <ArrowRightIcon className="swiper-navigation swiper-navigation--next" />
        </div>
    );
};

export default EmailSignatureTemplateGallery;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTestExist = exports.buildPath = exports.getPathPrefix = exports.generatePath = exports.getSortedStrings = exports.getPageTests = exports.shouldAffectBuild = exports.getVariantName = void 0;
const ab_test_shared_constants_1 = require("./ab-test-shared-constants");
const tests_config_1 = require("./tests-config");
const getVariantName = (testResult, testName) => {
    if (!testResult.isPassedGate) {
        return tests_config_1.AB_TESTS_CONFIG[testName].controlVariantName;
    }
    else {
        return testResult.variantName;
    }
};
exports.getVariantName = getVariantName;
const shouldAffectBuild = testName => {
    return tests_config_1.AB_TESTS_CONFIG[testName].shouldAffectBuild;
};
exports.shouldAffectBuild = shouldAffectBuild;
const getPageTests = (path, testsConfig = tests_config_1.AB_TESTS_CONFIG) => {
    if (ab_test_shared_constants_1.PATHS_TO_EXCLUDE.includes(path)) {
        return {};
    }
    return Object.keys(testsConfig).reduce((pageTests, testName) => {
        //check if global test has pages filter , if no so we add it and if yes we check if this global test run on this page
        if (!tests_config_1.AB_TESTS_CONFIG[testName].pages ||
            tests_config_1.AB_TESTS_CONFIG[testName].pages?.includes(path)) {
            pageTests[testName] = testsConfig[testName];
        }
        return pageTests;
    }, {});
};
exports.getPageTests = getPageTests;
const getSortedStrings = array => {
    return array.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
};
exports.getSortedStrings = getSortedStrings;
const generatePath = variants => {
    const sortedCombination = exports.getSortedStrings(variants);
    return sortedCombination.join(ab_test_shared_constants_1.PATH_VARIANTS_SEPARATOR);
};
exports.generatePath = generatePath;
const getPathPrefix = pagePath => {
    return pagePath === '/'
        ? `${ab_test_shared_constants_1.HOME_PREFIX}${ab_test_shared_constants_1.PATH_VARIANTS_SEPARATOR}`
        : `${pagePath}${ab_test_shared_constants_1.PATH_VARIANTS_SEPARATOR}`;
};
exports.getPathPrefix = getPathPrefix;
const buildPath = (userTestsResults, path) => {
    const testsNames = Object.keys(userTestsResults);
    if (!testsNames.length) {
        return path;
    }
    const variants = testsNames
        .filter(exports.shouldAffectBuild)
        .reduce((paths, testName) => {
        const testResult = userTestsResults[testName];
        const variantName = exports.getVariantName(testResult, testName);
        paths.push(`${variantName}`);
        return paths;
    }, []);
    const pathPrefix = exports.getPathPrefix(path);
    const variantsPath = exports.generatePath(variants);
    return `${pathPrefix}${variantsPath}`;
};
exports.buildPath = buildPath;
const isTestExist = (testName, variantName, isFallbackVariant = false) => {
    const testConfig = tests_config_1.AB_TESTS_CONFIG[testName];
    if (isFallbackVariant) {
        return !!testConfig;
    }
    return !!testConfig?.variants[variantName];
};
exports.isTestExist = isTestExist;

import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import InvoiceTemplateDemo from '../components/FeatureBlocks/InvoiceTemplateDemo/InvoiceTemplateDemo';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import DiscoverLinkBlock from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import OnlineInvoiceStore from '../stores/OnlineInvoiceStore.js';
import { usePromoCode } from '../hooks/usePromoCode';
import { Trans, useTranslation } from 'react-i18next';
import PictureAndTextBlockNew from '../components/FeatureBlocks/PictureAndTextBlock/PictureAndTextBlockNew';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import type { LinkDataType } from '../components/FeatureBlocks/DiscoverLinkBlock/DiscoverLinkBlock';
import type { TFAQ } from '../types/util-types';

const { templateData } = OnlineInvoiceStore;

import '../styles/invoice-generator-template.scss';
import { TRANSLATION_COMPONENTS } from '../constants/constants';

const ogTags = {
    url: 'https://www.honeybook.com/invoice-template',
    title: 'Invoice Generator To Create Your Own Invoice',
    description:
        'Try our free invoice generator for small businesses. Customize your invoice and download.'
};

const linkData: LinkDataType[] = [
    {
        id: 'learnInvoicing',
        url: '/blog/invoicing',
        imageUrl:
            'marketing_site/invoice-generator-lp/blog%20posts/learn_invoicing_basics.png'
    },
    {
        id: 'masterInvoice',
        url: '/blog/how-to-send-an-invoice',
        imageUrl: 'marketing_site/invoice-generator-lp/image_2.png'
    },
    {
        id: 'compareInvoicing',
        url: '/blog/good-invoicing-software',
        imageUrl: 'marketing_site/invoice-generator-lp/image_3.png'
    },
    {
        id: 'increaseInvoice',
        url: '/blog/honeybook-invoice',
        imageUrl: 'marketing_site/invoice-generator-lp/image_1.png'
    }
];

const faqs: TFAQ[] = [
    {
        id: 'invoiceWhatIs',
        answer: `#TEXT# <a href="/online-payment-software">#LINK_TEXT#</a> #TEXT# <a href="/online-invoices">#LINK_TEXT#</a>`
    },
    {
        id: 'invoiceBinding'
    },
    {
        id: 'invoiceMake',
        answer: `#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-send-an-invoice">#LINK_TEXT#</a> #TEXT# <a href="/blog/invoicing">#LINK_TEXT#</a> #TEXT# <a href="http://www.tax-rates.org/taxtables/sales-tax-by-state">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'invoiceFormat',
        answer: `#TEXT# <a href="/free-invoice-templates/photographers">#LINK_TEXT#</a>#TEXT#`
    },
    {
        id: 'invoiceDifference',
        answer: `#TEXT# <a href="https://www.smartsheet.com/purchase-order-templates">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'invoiceLookFor',
        answer: '#TEXT# <a href="https://apps.apple.com/us/app/honeybook/id1104772757">#LINK_TEXT#</a> #TEXT# <a href="https://apps.apple.com/us/app/honeybook/id1104772757">#LINK_TEXT#</a> #TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software">#LINK_TEXT#</a> #TEXT# <a href="https://www.youtube.com/watch?v=KRWgmIupycM">#LINK_TEXT#</a> #TEXT# <a href="/blog/payment-reminder-email">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2209215-customizing-payment-reminder-email-settings">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/en/articles/2209038-quickbooks-integration-overview-faq">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'invoicePaid'
    }
];

function InvoiceTemplate(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const imageTextElements = [
        {
            title: t(`invoiceTemplateImageTextElements.buckets.one.title`),
            text: t(`invoiceTemplateImageTextElements.buckets.one.description`),
            imagePath: 'marketing_site/smart-files/best-practices-01.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`invoiceTemplateImageTextElements.buckets.two.title`),
            text: t(`invoiceTemplateImageTextElements.buckets.two.description`),
            imagePath: 'marketing_site/invoice-generator-lp/pencil-drawing.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`invoiceTemplateImageTextElements.buckets.three.title`),
            text: t(
                `invoiceTemplateImageTextElements.buckets.three.description`
            ),
            imagePath: 'marketing_site/invoice-generator-lp/inbox.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        }
    ];

    return (
        <Layout ogTags={ogTags} customClass="invoice-generator-template">
            <InvoiceTemplateDemo />
            <PictureAndTextBlockNew
                customClass="invoice-generator-template__invoice-sample"
                primary={t('invoiceSample.title')}
                secondary={
                    <Trans
                        i18nKey="invoiceSample.bodyText"
                        components={TRANSLATION_COMPONENTS}
                    />
                }
                image="marketing_site/invoice-generator-lp/Invoice_Template_Page-final.png"
                imageWidth={470}
                imageAltTag={t('invoiceSample.title')}
            />
            <PictureAndTextBlockNew
                customClass="invoice-generator-template__invoice-benefits"
                primary={t('invoiceBenefits.title')}
                secondary={
                    <Trans
                        i18nKey="invoiceBenefits.bodyText"
                        components={TRANSLATION_COMPONENTS}
                    />
                }
                image="marketing_site/invoice-generator-lp/Invoice.png"
                imageWidth={470}
                imageAltTag={t('invoiceBenefits.title')}
            />
            <FeatureTemplateGallery
                customTemplateData={templateData}
                ctaSource="invoice templates (seo) template gallery footer"
            />
            <ImageTextElementsBlock
                title={t(`invoiceTemplateImageTextElements.title`)}
                imageTextElements={imageTextElements}
            />
            <FeatureValuePropsBlock
                hideCta={true}
                title={t('featureValueProps.title')}
            />
            <PromoBlockLeft
                customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                title={t(`${promoCode}.promoBlockLeft.title`)}
                subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                    defaultValue: null
                })}
                source="promo block left - yellow"
                promoCode={promoCode}
            />
            <FAQBlock
                title={t('InvoiceTemplateFAQs.title')}
                subtitle={t('InvoiceTemplateFAQs.subtitle')}
                faqs={faqs}
            />
            <DiscoverLinkBlock
                customTitle={t('DiscoverLinkBlock.title')}
                customSubtitle={t('DiscoverLinkBlock.subtitle')}
                linkData={linkData}
            />
        </Layout>
    );
}

export default InvoiceTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "invoiceGeneratorTemplate"
                        "common"
                        "onlineInvoices"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

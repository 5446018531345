import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';
import './picture-and-text-block.scss';

const PictureAndTextBlock = ({
    image,
    imageMobile,
    primary,
    secondary,
    imageWidth,
    imageAltTag,
    customClass = '',
    children,
    lightBackground,
    darkBackground
}) => {
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection
            customClass={`${customClass} picture-and-text-block`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent customClass="picture-and-text-block__feature-text-container">
                <FeatureBlockText primary={primary} />
                {breakpoints.large &&
                    (imageMobile ? (
                        <HBMediaElement
                            width={imageWidth || 570}
                            name={imageMobile}
                            alt={imageAltTag}
                        />
                    ) : (
                        <HBMediaElement
                            width={imageWidth || 800}
                            name={image}
                            alt={primary}
                        />
                    ))}
                <FeatureBlockText secondary={secondary} />
                {children}
            </FeatureBlockContent>
            <FeatureBlockContent customClass="picture-and-text-block__feature-image-container">
                {breakpoints.largeUp && (
                    <HBMediaElement
                        crop="scale"
                        width={imageWidth || 800}
                        name={image}
                        alt={imageAltTag || primary}
                    />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

PictureAndTextBlock.defaultProps = {
    customClass: ''
};

PictureAndTextBlock.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    image: PropTypes.string,
    imageMobile: PropTypes.string,
    imageAltTag: PropTypes.string,
    imageWidth: PropTypes.number,
    customClass: PropTypes.string,
    children: PropTypes.node,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default PictureAndTextBlock;

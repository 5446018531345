import { memo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '@honeybook-ui/HBText/HBText';
import Hero from '../Hero/Hero';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import VideoModal from '../../Modals/VideoModal/VideoModal';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import WatchVideoButton from '../../WatchVideoButton/WatchVideoButtonNew';
import verticalPaidStore from '../../../stores/verticalPaidStore.js';
import HBVideo from '../../honeybook-ui/HBVideo/HBVideo';

import './vertical-paid-hero.scss';

const VerticalPaidPageHero = ({ contentKey }) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const { hero } = verticalPaidStore[contentKey];

    const [videoModalOpen, setVideoModalOpen] = useState(false);

    const toggleVideoModal = () => {
        AnalyticsService.trackClick(AnalyticsEvents.value_props_hero_video, {
            source: 'toggle hero video modal'
        });
        setVideoModalOpen(videoModalOpen => !videoModalOpen);
    };

    return (
        <Hero customClass="vertical-paid-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={2}
                    customClass="vertical-paid-hero__text-container"
                >
                    <HBText
                        customClass="vertical-paid-hero__title"
                        tag="h1"
                        type="title-m"
                    >
                        <Trans>
                            {t(`${contentKey}.verticalPaidHero.title`)}
                        </Trans>
                    </HBText>
                    <h2 className="vertical-paid-hero__subtitle">
                        <Trans>
                            {t(`${contentKey}.verticalPaidHero.subtitle`)}
                        </Trans>
                    </h2>
                    {!breakpoints.large && (
                        <RegistrationForm
                            size="large"
                            showNoCcRequired
                            source="vertical paid page hero"
                        />
                    )}
                </HeroColumn>

                <HeroColumn
                    columnWidth={3}
                    customClass="vertical-paid-hero__media-block"
                >
                    <div className="vertical-paid-hero__image-grid">
                        <ParallaxProvider>
                            <Parallax
                                className="vertical-paid-hero__image1"
                                speed={5}
                            >
                                <div>
                                    <HBMediaElement
                                        lazyLoad={false}
                                        name={hero.image1}
                                    />
                                </div>
                            </Parallax>
                            <Parallax
                                className="vertical-paid-hero__image2"
                                speed={8}
                            >
                                <div>
                                    <HBMediaElement
                                        lazyLoad={false}
                                        name={hero.image2}
                                    />
                                </div>
                            </Parallax>
                            <Parallax
                                className="vertical-paid-hero__video"
                                speed={8}
                            >
                                <div onClick={toggleVideoModal}>
                                    <HBVideo
                                        ratio={0.5625}
                                        videoId="824483128"
                                        muted
                                        autoplay
                                        poster="https://res.cloudinary.com/honeybook/image/upload/c_scale,f_auto,fl_lossy,q_auto,w_auto/v1/marketing_site/home_page/hero-video-preview.png"
                                        customClass="iframe-container"
                                        shouldLoadScript={false}
                                        shouldCursorPointer
                                        loop
                                    />
                                    {breakpoints.medium ? (
                                        <HBMediaElement
                                            customClass="vertical-paid-page-hero__play-button-mobile"
                                            name="marketing_site/vertical-lps/hero-play-btn-mobile.svg"
                                        />
                                    ) : (
                                        <WatchVideoButton />
                                    )}
                                </div>
                            </Parallax>
                        </ParallaxProvider>
                    </div>
                    {breakpoints.large && (
                        <RegistrationForm
                            size="large"
                            showNoCcRequired
                            source="vertical paid page hero"
                        />
                    )}
                </HeroColumn>
                <HBMediaElement
                    customClass="vertical-paid-hero__arrow"
                    name={
                        breakpoints.large
                            ? 'marketing_site/vertical-lps/arrow-mweb.png'
                            : 'marketing_site/vertical-lps/arrow-2x.png'
                    }
                    lazyLoad={false}
                />
            </HeroStrip>
            <VideoModal
                video={{
                    id: 'hero',
                    videoURL:
                        'https://www.youtube.com/embed/l9Fpa2vhwGc?autoplay=1&controls=1'
                }}
                isOpen={videoModalOpen}
                onClose={toggleVideoModal}
            />
        </Hero>
    );
};

export default memo(VerticalPaidPageHero);

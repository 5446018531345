import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import StatsAnimation, {
    TStats
} from '../../Hero/StatsAnimation/StatsAnimation';
import Star from '../../../svg/Star.inline.svg';

import './feature-hero-block.scss';

interface Props {
    title: string;
    subtitle: string;
    label: string;
    stats?: TStats[];
    iconUrl1?: string;
    iconUrl2?: string;
}

const FeatureHeroBlock = ({
    title,
    subtitle,
    label,
    stats,
    iconUrl1 = 'marketing_site/online-contracts/Contract.png',
    iconUrl2 = 'marketing_site/online-contracts/Lock.png'
}: Props) => {
    return (
        <FeatureBlockSection customClass="feature-hero-block">
            <FeatureBlockContent customClass="feature-hero-block__content">
                <HBText
                    tag="p"
                    type="body-s"
                    customClass="feature-hero-block__label"
                >
                    {label}
                </HBText>
                <HBText
                    tag="h1"
                    type="title-l"
                    customClass="feature-hero-block__title"
                >
                    {title}
                </HBText>
                <HBText
                    tag="p"
                    type="body-xl"
                    customClass="feature-hero-block__subtitle"
                >
                    {subtitle}
                </HBText>
                <RegistrationForm
                    customClass="feature-hero-block__button"
                    source="contract hero block"
                    buttonStyle="primary"
                    size="large"
                    showNoCcRequired
                />
                {!!stats && <StatsAnimation stats={stats} />}
                <HBMediaElement
                    customClass="feature-hero-block__contract-img"
                    name={iconUrl1}
                    forceImgTag
                />
                <HBMediaElement
                    customClass="feature-hero-block__lock-img"
                    name={iconUrl2}
                    forceImgTag
                />
                <Star className="feature-hero-block__star feature-hero-block__star--1" />
                <Star className="feature-hero-block__star feature-hero-block__star--2" />
                <Star className="feature-hero-block__star feature-hero-block__star--3" />
                <Star className="feature-hero-block__star feature-hero-block__star--4" />
                <Star className="feature-hero-block__star feature-hero-block__star--5" />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureHeroBlock;

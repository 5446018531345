import { useEffect, useRef } from 'react';

import './marquee-animation.scss';

interface Props {
    panels: JSX.Element[];
    options?: {
        reverse: boolean;
        speed: number;
        gap: number;
    };
}

const DEFAULT_OPTIONS = {
    reverse: false,
    speed: 20,
    gap: 10
};

const MarqueeAnimation = ({ panels, options = DEFAULT_OPTIONS }: Props) => {
    const marqueeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!!marqueeRef.current) {
            marqueeRef.current?.style?.setProperty(
                '--marquee-speed',
                `${options.speed}s`
            );
            marqueeRef.current?.style?.setProperty(
                '--marquee-gap',
                `${options.gap}px`
            );
            marqueeRef.current?.style?.setProperty(
                '--marquee-direction',
                options.reverse ? 'reverse' : 'normal'
            );
        }
    }, [marqueeRef.current]);

    return (
        <div className="marquee-animation" ref={marqueeRef}>
            <div className="marquee-animation__content">
                {panels.map(panel => panel)}
                {panels.map(panel => panel)}
            </div>
        </div>
    );
};

export default MarqueeAnimation;

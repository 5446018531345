import { useState, useEffect } from 'react';

interface IWindowSize {
    width: number;
    height: number;
}

const getSize = (isClient: boolean) => {
    return {
        width: isClient ? window.innerWidth : 0,
        height: isClient ? window.innerHeight : 0
    };
};

const breakpoints = {
    small: 426,
    medium: 768,
    large: 992,
    xLarge: 1200
};

export function useWindowSize() {
    const isClient = typeof window === 'object';

    const [windowSize, setWindowSize] = useState<IWindowSize>(() =>
        getSize(isClient)
    );

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize(isClient));
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isClient]);

    return {
        windowSize,
        setWindowSize,
        isUpToSmall: windowSize.width < breakpoints.small,
        isMediumUp: windowSize.width > breakpoints.medium,
        isLargeUp: windowSize.width > breakpoints.large,
        isXLargeUp: windowSize.width > breakpoints.xLarge
    };
}

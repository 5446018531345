import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import NavSubMenu from '../NavSubMenu/NavSubMenu';
import './navbar-btn.scss';
import type { MouseEventHandler } from 'react';
import type { NavBarSubItem } from '../NavBar/NavBar';
import NavigateLink from '../Link/NavigateLink';
import ArrowDown16 from '../../svg/navbar-icons/arrow-down-16.inline.svg';

interface Props {
    id: string;
    link?: string;
    subItems?: NavBarSubItem[];
    onClick?: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>;
    iconPath?: string;
    icon?: ReactNode;
    customClass?: string;
}

function NavBarBtn({
    id,
    link,
    subItems,
    onClick,
    onMouseEnter,
    customClass,
    icon
}: Props): JSX.Element {
    const { t } = useTranslation();

    const buttonClass = classnames('navbar-btn', {
        [`${customClass}`]: Boolean(customClass)
    });

    if (id && subItems) {
        return (
            <>
                {id && (
                    <div
                        className={buttonClass}
                        onMouseEnter={onMouseEnter}
                        data-item-link={link}
                        role="menuitem"
                        tabIndex={0}
                        aria-label={id}
                    >
                        {icon && (
                            <div className="media-element svg-content">
                                {icon}
                            </div>
                        )}
                        {!icon && t(`navBarButtons.${[id]}`)}
                        <span className="icon">
                            <ArrowDown16 />
                        </span>
                        {subItems && (
                            <NavSubMenu customClass={id} items={subItems} />
                        )}
                    </div>
                )}
            </>
        );
    }

    // If no dropdown return an anchor link
    // Otherwise we cannot use nested anchor links
    if (typeof link !== 'undefined') {
        return (
            <NavigateLink
                source={link === '/' ? 'logo' : 'navbar'}
                link={link}
                className={buttonClass}
                onClick={link ? onClick : undefined}
                onMouseEnter={onMouseEnter}
                role="menuitem"
                tabIndex={0}
            >
                {icon && (
                    <div className="media-element svg-content">{icon}</div>
                )}
                {!icon && t(`navBarButtons.${[id]}`)}
            </NavigateLink>
        );
    }

    return <></>;
}

export default memo(NavBarBtn);

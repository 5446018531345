import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';
import Layout from '../components/Layout/Layout';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import AnimatedDollarTextBlock from '../components/FeatureBlocks/AnimatedDollarTextBlock copy/AnimatedDollarTextBlock';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import MobileStickyCTA from '../components/MobileStickyCTA/MobileStickyCTA';
import OnlineInvoiceStore from '../stores/OnlineInvoiceStore.js';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TemplateTabsBlock from '../components/FeatureBlocks/TemplateTabsBlock/TemplateTabsBlock';
import TemplatePreviewSliderHero from '../components/Hero/TemplatePreviewSliderHero/TemplatePreviewSliderHero';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import CurvedSpacer from '../components/CurvedSpacer/CurvedSpacer';

import '../styles/online-template.scss';

const ogTags = {
    url: 'https://www.honeybook.com/get-invoice-templates',
    title: 'Custom Online Invoice for Small Business Owners',
    description:
        'Get paid faster with a professional online invoice. Use invoice templates that meet your business needs, including automatic payment reminders.',
    noIndex: true
};

const {
    stepBlocksOne,
    stepBlocksTwo,
    stepBlocksThree,
    signatureCardContents,
    templateData,
    quotes
} = OnlineInvoiceStore;

function InvoiceTemplates(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.paid.title')}
                    </Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.paid.subtitle')}
                    </Trans>
                )
            },
            {
                title: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.early.title')}
                    </Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.early.subtitle')}
                    </Trans>
                )
            },
            {
                title: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.payments.title')}
                    </Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('invoiceTemplateStatsAnimation.payments.subtitle')}
                    </Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags} slimNav slimFooter>
            <div className={'online-template online-template--get-invoices'}>
                <>
                    <TemplatePreviewSliderHero />
                    <StatsAnimation stats={stats} />
                    <CurvedSpacer bgColor="#f1f4f6" />
                    <FeatureTemplateGallery
                        customTemplateData={templateData}
                        ctaSource="invoice templates (paid) - template gallery footer"
                    />
                </>

                <AnimatedDollarTextBlock cardContents={signatureCardContents} />
                <StepFeatureBlock
                    customClass="online-template__step1"
                    transId="stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    reverse
                    transId="stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t('stepsFeatureBlock.linkText')}
                />

                <TemplateTabsBlock showScrollToArrow={false} />

                <TestimonialCarouselCardBlock
                    testimonials={quotes}
                    autoScroll={false}
                />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
                <MobileStickyCTA />
            </div>
        </Layout>
    );
}

export default InvoiceTemplates;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "common"
                        "translations"
                        "getInvoiceTemplates"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import WebinarTimeSlotsContainer from '../../WebinarTimeSlotsContainer/WebinarTimeSlotsContainer';
import WebinarRecordingsContainer from '../../WebinarRecordingsContainer/WebinarRecordingsContainer';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import './webinar-block.scss';

const WebinarBlock = ({ t, webinars }) => {
    return (
        <FeatureBlockSection>
            <FeatureBlockContent customClass="webinar-block">
                {webinars.map(webinar => {
                    return (
                        <div
                            key={webinar.id}
                            id={`webinar-block__${webinar.id}`}
                            className="webinar-block__column"
                        >
                            <div className="webinar-block__intro-text">
                                <h2 className="webinar-block__title">
                                    {t(
                                        `productEducationPage.webinars.${webinar.id}.title`
                                    )}
                                </h2>
                                <p className="webinar-block__description">
                                    {t(
                                        `productEducationPage.webinars.${webinar.id}.description`
                                    )}
                                </p>

                                {webinar.overviewKeys && (
                                    <ul className="webinar-block__list">
                                        {webinar.overviewKeys.map(item => {
                                            return (
                                                <li
                                                    className="webinar-block__list-item"
                                                    key={item}
                                                >
                                                    {t(
                                                        `productEducationPage.webinars.${webinar.id}.overviewList.${item}`
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {!webinar.recordings && (
                                    <>
                                        <p className="webinar-block__join">
                                            {t(
                                                `productEducationPage.webinars.${webinar.id}.chooseSession.title`
                                            )}
                                        </p>
                                        <a
                                            href="//www.bigmarker.com/honeybook/HoneyBook-Basics-Introduction-to-Your-New-Account?utm_bmcr_source=ProdEduLP"
                                            className="hbui-button hbui-button--small hbui-button--primary"
                                        >
                                            {t(
                                                `productEducationPage.webinars.${webinar.id}.chooseSession.ctaText`
                                            )}
                                        </a>
                                    </>
                                )}

                                {webinar.link && (
                                    <p className="webinar-block__link-text">
                                        {t(
                                            `productEducationPage.webinars.${webinar.id}.linkBodyText`
                                        )}
                                        <br />
                                        <a
                                            className="webinar-block__link"
                                            href={webinar.link}
                                        >
                                            {t(
                                                `productEducationPage.webinars.${webinar.id}.linkText`
                                            )}
                                        </a>
                                    </p>
                                )}
                            </div>

                            {webinar.recordings && (
                                <WebinarRecordingsContainer
                                    recordings={webinar.recordings}
                                />
                            )}

                            {webinar.webinarSlots && (
                                <WebinarTimeSlotsContainer
                                    webinarSlots={webinar.webinarSlots}
                                />
                            )}
                        </div>
                    );
                })}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

WebinarBlock.propTypes = {
    t: PropTypes.func,
    webinars: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withTranslation()(WebinarBlock);

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ImageTextElementsBlock from '../ImageTextElementsBlock/ImageTextElementsBlock';
import { FeatureBlockSection } from '../../FeatureBlockElements';

import './meet-our-instructors-block.scss';

import MeetOurInstructorsBlockStore from './MeetOurInstructorsBlockStore';

const MeetOurInstructorsBlock = ({ t }) => {
    return (
        <div className="meet-instructors">
            <ImageTextElementsBlock
                title={t(`productEducationPage.meetInstructors.title`)}
                imageTextElements={MeetOurInstructorsBlockStore.instructors}
            />
            <FeatureBlockSection>
                <p className="meet-instructors__body-text">
                    {t(`productEducationPage.meetInstructors.body`)}
                </p>
            </FeatureBlockSection>
        </div>
    );
};

MeetOurInstructorsBlock.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(MeetOurInstructorsBlock);

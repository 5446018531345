import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../components/FeatureBlockElements';
import ImageTextElement from '../components/ImageTextElement/ImageTextElement';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';

import '../styles/educators.scss';

import TestimonialCarousel from '../components/TestimonialCarousel/TestimonialCarousel';
import EducatorHero from '../components/Hero/EducatorHero/EducatorHero';
import EducatorStats from '../components/EducatorStats';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';

const imageTextElements = [
    {
        title: 'EducatorPage.perks.exclusive.title',
        text: 'EducatorPage.perks.exclusive.body',
        imagePath: 'marketing_site/educator/gift-email_1.svg',
        altText: 'EducatorPage.perks.exclusive.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'EducatorPage.perks.referral.title',
        text: 'EducatorPage.perks.referral.body',
        imagePath: 'marketing_site/educator/money.svg',
        altText: 'EducatorPage.perks.referral.title',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'EducatorPage.perks.potential.title',
        text: 'EducatorPage.perks.potential.body',
        imagePath: 'marketing_site/educator/unlimited_illustration_1.svg',
        altText: 'EducatorPage.perks.potential.title',
        mediaFlags: { forceImgTag: true }
    }
];

const testimonials = [
    {
        id: 'latasha',
        image: 'marketing_site/educator/Latasha_James.jpg'
    },
    {
        id: 'john',
        image: 'marketing_site/educator/john_branch.jpg'
    },
    {
        id: 'candice',
        image: 'marketing_site/educator/candice.jpg'
    },
    {
        id: 'brandi',
        image: 'marketing_site/educator/brandi.jpg'
    },
    {
        id: 'regan',
        image: 'marketing_site/educator/regan2.jpg'
    },
    {
        id: 'ellen',
        image: 'marketing_site/educator/ellen_yin.jpg'
    }
];

const educators = [
    {
        id: 'geomyra',
        image: 'marketing_site/educator/educator1.png',
        link: '/risingtide/host-podcast'
    },
    {
        id: 'events',
        image: 'marketing_site/educator/stockImage2.jpg',
        link: '/risingtide/honeybook-community-events-calendar'
    },
    {
        id: 'bluePine',
        image: 'marketing_site/educator/educator2.png',
        link: '/risingtide/jess-used-honeybook-to-organize-her-web-graphic-design-business'
    }
];

const faqs = [
    {
        id: 'educatorPaid'
    },
    {
        id: 'educatorAnyone',
        answer: '#TEXT# <a href="/risingtide/communityguidelines">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'educatorResources'
    },
    {
        id: 'educatorLimit'
    },
    {
        id: 'educatorTrack'
    },
    {
        id: 'educatorLink'
    },
    {
        id: 'educatorShare'
    },
    {
        id: 'educatorSay'
    },
    {
        id: 'educatorMinimum'
    }
];

const handleButtonClick = source => {
    AnalyticsService.trackClick(AnalyticsEvents.educator_apply_now, {
        source
    });
};

const ogTags = {
    url: 'https://www.honeybook.com/educators',
    title: 'HoneyBook Educator Program - Become an Affiliate',
    description: `Make money online by joining HoneyBook's affiliate marketing program. Refer new subscribers to earn cash. Apply today.`
};

function EducatorsTemplate() {
    const { t } = useTranslation();

    return (
        <div className="educator-lp">
            <Layout ogTags={ogTags}>
                <EducatorHero />

                <EducatorStats />

                <ImageTextElementsBlock
                    customClass="educator-lp__perks"
                    title={t('EducatorPage.perks.title')}
                    subtitle={t('EducatorPage.perks.subtitle')}
                    imageTextElements={imageTextElements}
                />
                <FeatureBlockSection customClass="educator-lp__cta">
                    <FeatureBlockContent>
                        <a
                            className="hbui-button hbui-button--medium hbui-button--primary"
                            onClick={() =>
                                handleButtonClick('become an educator')
                            }
                            href="https://form.typeform.com/to/i2u0n2"
                            rel="noopener noreferrer"
                        >
                            {t('EducatorHero.ctaText')}
                        </a>
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FeatureBlockSection
                    lightBackground
                    customClass="educator-lp__meet"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            primary={t(`EducatorPage.meet.title`)}
                            secondary={t(`EducatorPage.meet.subtitle`)}
                        />
                        <TestimonialCarousel
                            transKey="EducatorPage.meet.educators"
                            testimonials={testimonials}
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FeatureBlockSection customClass="educator-lp__educators">
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            primary={t(`EducatorPage.educators.title`)}
                            secondary={t(`EducatorPage.educators.subtitle`)}
                        />
                        {educators.map(educator => (
                            <ImageTextElement
                                key={educator.id}
                                title={t(
                                    `EducatorPage.educators.${educator.id}.name`
                                )}
                                description={t(
                                    `EducatorPage.educators.${educator.id}.body`
                                )}
                                imagePath={educator.image}
                                mediaFlags={{ height: 214 }}
                            >
                                <a
                                    className="educator-lp__educators-link"
                                    href={educator.link}
                                    rel="noreferrer noopener"
                                >
                                    {t(`EducatorPage.educators.readMore`)}
                                </a>
                            </ImageTextElement>
                        ))}
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FAQBlock
                    title={t('EducatorPage.faqTitle')}
                    faqs={faqs}
                    displayContactLink
                />

                <CTABlock
                    customClass="educator-lp__footer-cta"
                    primary={t('EducatorPage.footerCTA.title')}
                    imageUrl="marketing_site/member-love/trophy_2x.png"
                    imageAltTag="trophy"
                    mediaFlags={{ width: 130 }}
                    darkBackground
                    customCTA={
                        <a
                            className="hbui-button hbui-button--medium hbui-button--primary"
                            onClick={() => handleButtonClick('footer cta')}
                            href="https://form.typeform.com/to/i2u0n2"
                            rel="noopener noreferrer"
                        >
                            {t('EducatorHero.ctaText')}
                        </a>
                    }
                />
            </Layout>
        </div>
    );
}

export default EducatorsTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "educators", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

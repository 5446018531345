import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';

import './integrations-section.scss';

import type { IntegrationItem } from '../FeatureBlocks/IntegrationsBlock/IntegrationsBlock';

interface Props {
    title?: string;
    subtitle?: string;
    integrations: IntegrationItem[];
}

function IntegrationsSection({
    title,
    subtitle,
    integrations
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div id="integrations" className="integrations-section">
            {title ? (
                <h2 className="integrations-section__title">{title}</h2>
            ) : null}
            {subtitle ? (
                <h3 className="integrations-section__subtitle">{subtitle}</h3>
            ) : null}
            <div className="integrations-list">
                {integrations.map(integration => (
                    <div
                        key={integration.id}
                        className="integrations-list__item"
                    >
                        <p className="integrations-list__description">
                            {t(
                                `integrationsBlock.integrations.${integration.id}`
                            )}
                        </p>
                        <HBMediaElement
                            name={integration.imgUrl}
                            forceImgTag
                            alt=""
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

IntegrationsSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    integrations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            imgUrl: PropTypes.string.isRequired
        })
    )
};

export default memo(IntegrationsSection);

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import OnlinePaymentSoftwareHero from '../components/Hero/OnlinePaymentSoftwareHero/OnlinePaymentSoftwareHero';
import PaymentsBlock from '../components/FeatureBlocks/PaymentsBlock/PaymentsBlock';
import AcceptOnlinePaymentsBlock from '../components/FeatureBlocks/AcceptOnlinePaymentsBlock/AcceptOnlinePaymentsBlock';
import FrictionlessPaymentBlock from '../components/FeatureBlocks/FrictionlessPaymentBlock/FrictionlessPaymentBlock';
import TimelyPaymentBlock from '../components/FeatureBlocks/TimelyPaymentBlock/TimelyPaymentBlock';
import InstantPaymentsBlock from '../components/FeatureBlocks/InstantPaymentsBlock/InstantPaymentsBlock';
import TabbedFeaturesBlock from '../components/FeatureBlocks/TabbedFeaturesBlock/TabbedFeaturesBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import WereFirstClassBlock from '../components/FeatureBlocks/WereFirstClassBlock/WereFirstClassBlock';
import BlogLinkBlock from '../components/BlogLinkBlock/BlogLinkBlock';

import FeatureBlockSection from '../components/FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../components/FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../components/FeatureBlockElements/FeatureBlockText/FeatureBlockText';
import FeatureBlockDivider from '../components/FeatureBlockElements/FeatureBlockDivider/FeatureBlockDivider';

import '../styles/online-payment-software.scss';

const faqs = [
    {
        id: 'requiredPayment'
    },
    {
        id: 'processingFees',
        answer: '#TEXT# <a href="//intercom.help/honeybook-5891e2a409ff/payments-and-bank-info/getting-started-with-payments/getting-paid-through-honeybook#transaction-fees">#TEXT_LINK#</a>.'
    },
    {
        id: 'clientPayments',
        answer: '#TEXT# <a href="//help.honeybook.com/video-tutorials/getting-started-and-account-set-up/video-tutorial-using-honeybooks-bookkeeping-tool">#LINK_TEXT#</a>.'
    },
    {
        id: 'paymentProcessor'
    },
    {
        id: 'passTransactionFee',
        answer: '#TEXT <a href="//www.thebalance.com/can-businesses-charge-a-credit-card-convenience-fee-4155333">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'paymentDispute',
        answer: '#TEXT# <i>italic</i> #TEXT#'
    },
    {
        id: 'clientPaymentExperience',
        answer: '#TEXT <a href="/proposal-software">#LINK_TEXT#</a> #TEXT# <a href="//intercom.help/honeybook-5891e2a409ff/using-honeybook-files/client-side-file-experience/what-your-client-sees-when-they-pay">#LINK_TEXT#</a>.'
    },
    {
        id: 'fraudProtection'
    },
    {
        id: 'integrateQuickbooks'
    },
    {
        id: 'howInstantDeposit',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3570117">#LINK_TEXT#</a>'
    }
];

const blogLinks = [
    {
        titleId: 'onlinePaymentSoftwarePage.blogLinks.blogs.revenue.title',
        imagePath: 'marketing_site/online-payments/online_invoice_blog.png',
        url: '/risingtide/invoice/'
    },
    {
        titleId: 'onlinePaymentSoftwarePage.blogLinks.blogs.email.title',
        imagePath:
            'marketing_site/online-payments/Screen_Shot_2019-04-06_at_9.43.49.png',
        url: 'https://youtu.be/HCMWmHQTlbk',
        mediaFlags: {
            crop: 'scale',
            width: 320,
            height: 180
        }
    },
    {
        titleId: 'onlinePaymentSoftwarePage.blogLinks.blogs.boosted.title',
        imagePath: 'marketing_site/online-payments/blog_post_3.png',
        url: '/risingtide/see-canyon-fruit-ranch-wedding-venue-business/'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/online-payment-software',
    title: 'Online Payment Software for Small Businesses',
    description:
        'Get paid faster with online payment software for small businesses. We accept all major cards and bank transfers for a seamless client payment experience.'
};

function OnlinePaymentSoftwareTemplate() {
    const { t } = useTranslation();

    return (
        <div className="online-payment-software">
            <Layout ogTags={ogTags}>
                <OnlinePaymentSoftwareHero />

                <FeatureBlockSection
                    lightBackground
                    customClass="online-payment-software__bookings-text-block"
                >
                    <FeatureBlockContent>
                        <FeatureBlockText
                            fullWidth
                            preTitle={t(`onlinePaymentSoftwarePage.preTitle`)}
                            primary={t(`onlinePaymentSoftwarePage.title`)}
                        />
                        <HBMediaElement
                            name="marketing_site/online-payments/data-point.svg"
                            forceImgTag
                            alt={t(`onlinePaymentSoftwarePage.imgAltText`)}
                            lazyLoad={false}
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>

                <FrictionlessPaymentBlock />

                <FeatureBlockDivider />

                <TimelyPaymentBlock />

                <InstantPaymentsBlock />

                <AcceptOnlinePaymentsBlock />

                <PaymentsBlock />

                <TabbedFeaturesBlock />

                <FAQBlock
                    title={t('onlinePaymentSoftware.faqTitle')}
                    faqs={faqs}
                />

                <BlogLinkBlock
                    title={t('onlinePaymentSoftwarePage.blogLinks.title')}
                    blogs={blogLinks}
                />

                <WereFirstClassBlock />
            </Layout>
        </div>
    );
}

export default OnlinePaymentSoftwareTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import HBButton from '@honeybook-ui/HBButton/HBButton';
import WhiteHero from '../components/Hero/WhiteHero/WhiteHero';
import RegistrationForm from '../components/RegistrationForm/RegistrationForm';
import TemplateGallery from '../components/FeatureBlocks/TemplateGallery/TemplateGallery';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import PictureAndTextWrapBlock from '../components/FeatureBlocks/PictureAndTextWrapBlock/PictureAndTextWrapBlock';
import InvoiceGeneratorFreeInvoiceBlock from '../components/FeatureBlocks/InvoiceGeneratorFreeInvoiceBlock/InvoiceGeneratorFreeInvoiceBlock';
import InvoiceGeneratorOtherFeaturesBlock from '../components/FeatureBlocks/InvoiceGeneratorOtherFeaturesBlock/InvoiceGeneratorOtherFeaturesBlock';
import NeedInvoiceTemplatesBlock from '../components/FeatureBlocks/NeedInvoiceTemplatesBlock/NeedInvoiceTemplatesBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import UtilsService from '../Services/UtilsService';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import { useABTestContext } from '../ab-tests/context/useABTestContext';
import { Link } from '@gatsbyjs/reach-router';

import '../styles/invoice-hub.scss';

const faqs = [
    {
        id: 'createFreeInvoice'
    },
    {
        id: 'createSimpleInvoice',
        answer: '#TEXT# <a href="/invoice-template">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'createInvoicePDF'
    },
    {
        id: 'basicInvoiceLook',
        answer: `
      <p>#TEXT#</p>
      <p>#TEXT#</p>
      <p>#TEXT#</p>
    `
    },
    {
        id: 'howInvoiceSomeone',
        answer: '#TEXT# <a href="/blog/how-to-send-an-invoice">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'howCreateInvoiceTemplate',
        answer: `
    <p>#TEXT# <a href="/online-invoices">#LINK_TEXT#</a> #TEXT#</p>
    <p>#TEXT#</p>
    <p>#TEXT#</p>
    <p>#TEXT#</p>
    <p>#TEXT#</p>
    <ul>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
    </ul>
    `
    },
    {
        id: 'includeInInvoice',
        answer: `
    <p>#TEXT#</p>
    <p>#TEXT#</p>
    <ul>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
    </ul>
    `
    },
    {
        id: 'invoiceReceipt',
        answer: '#TEXT# <a href="/blog/invoice-vs-receipt">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'differentInvoiceTypes',
        answer: `
    <p>#TEXT#</p>
    <ul>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
      <li>#LIST_ITEM#</li>
    </ul>
    `
    },
    {
        id: 'whatIsInvoicingSoftware',
        answer: `#TEXT#`
    },
    {
        id: 'whyNeedInvoicingSoftware',
        answer: '#TEXT# <a href="/blog/invoice-payments">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'bestInvoiceSoftware',
        answer: '#TEXT# <a href="/blog/best-invoice-app">#LINK_TEXT#</a> #TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'anyoneInvoice',
        answer: '#TEXT# <a href="/invoice-template">#LINK_TEXT#</a> #TEXT#'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/free-invoice-templates/',
    title: 'Customize a Free Invoice Template - Browse by Industry',
    description:
        'With HoneyBook, you can create invoices faster using a customizable invoice templates and get paid sooner by sending client invoices online.'
};

function InvoiceHub(): JSX.Element {
    const { t } = useTranslation();

    const {
        variantPerTest: { invoice_template_hero_cta }
    } = useABTestContext();

    const displaySecondaryCTA = invoice_template_hero_cta === 'hero_cta';

    const imageTextElements = [
        {
            title: t(`InvoiceHub.templateBenefits.one.title`),
            text: t(`InvoiceHub.templateBenefits.one.description`),
            imagePath: 'marketing_site/smart-files/best-practices-01.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`InvoiceHub.templateBenefits.two.title`),
            text: t(`InvoiceHub.templateBenefits.two.description`),
            imagePath: 'marketing_site/invoice-hub/Checkmark.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        },
        {
            title: t(`InvoiceHub.templateBenefits.three.title`),
            text: t(`InvoiceHub.templateBenefits.three.description`),
            imagePath: 'marketing_site/smart-files/best-practices-03.png',
            mediaFlags: {
                width: 127,
                height: 127
            }
        }
    ];

    const handleHeroButtonClick = () => {
        AnalyticsService.trackClick(AnalyticsEvents.explore_our_free_invoices);
        UtilsService.scrollToElementId('template-gallery-wrapper');
    };

    const handleABTestAnalytics = () => {
        AnalyticsService.trackClick(AnalyticsEvents.invoice_hub_hero_cta_test);
    };

    return (
        <section className="invoice-hub">
            <Layout ogTags={ogTags}>
                <WhiteHero
                    image="marketing_site/invoice-hub/example-invoice-template-rebrand"
                    width={800}
                    preTitle={t('InvoiceHub.hero.pretitle')}
                    mainTitle={t('InvoiceHub.hero.title')}
                    imageAltTag={t('InvoiceHub.hero.title')}
                    description={t('InvoiceHub.hero.description')}
                    showRegistration={false}
                >
                    <div className="invoice-hub__cta-container">
                        <HBButton
                            onClick={handleHeroButtonClick}
                            text={
                                displaySecondaryCTA
                                    ? t('InvoiceHub.hero.ctaABTest')
                                    : t('InvoiceHub.hero.cta')
                            }
                        />
                        {displaySecondaryCTA && (
                            <Link
                                to="/invoice-template"
                                onClick={handleABTestAnalytics}
                                className="hbui-button hbui-button--medium hbui-button--secondary  "
                            >
                                {t('InvoiceHub.hero.ctaSecondary')}
                            </Link>
                        )}
                    </div>
                </WhiteHero>

                <div id="template-gallery-wrapper">
                    <TemplateGallery templateTypeId="invoice" />
                </div>

                <ImageTextElementsBlock
                    customClass="invoice-hub__benefits"
                    title={t('InvoiceHub.templateBenefits.title')}
                    imageTextElements={imageTextElements}
                />

                <PictureAndTextWrapBlock
                    lightBackground
                    customClass="invoice-hub__picture-text-block"
                    primary={t('InvoiceHub.pictureTextWrapBlock.title')}
                    image="marketing_site/invoice-hub/pipeline-illustration_2x"
                    imageWidth={800}
                    imageAltTag={t('InvoiceHub.pictureTextWrapBlock.title')}
                >
                    <p className="invoice-hub__picture-text-block-text">
                        {t(`InvoiceHub.pictureTextWrapBlock.body.p1`)}
                    </p>
                    <p className="invoice-hub__picture-text-block-text">
                        {t(`InvoiceHub.pictureTextWrapBlock.body.p2`)}
                    </p>
                    <RegistrationForm
                        source="best invoicing software"
                        customClass="invoice-hub__picture-text-block-button"
                        buttonText={t('InvoiceHub.pictureTextWrapBlock.cta')}
                    />
                </PictureAndTextWrapBlock>

                <InvoiceGeneratorFreeInvoiceBlock
                    registrationSource="Invoicing Software module"
                    contentKey="generic"
                />

                <InvoiceGeneratorOtherFeaturesBlock
                    contentKey="generic"
                    lightBackground
                />

                <NeedInvoiceTemplatesBlock />

                <FAQBlock
                    title={t(`invoiceGeneratorPages.generic.faqTitle`)}
                    faqs={faqs}
                />

                <FreeToTryBlockRebrand />
            </Layout>
        </section>
    );
}

export default InvoiceHub;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "common"
                        "invoiceHub"
                        "templateGallery"
                        "faqs"
                        "invoiceGenerator"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

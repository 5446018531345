import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import IntegrationsSection from '../../IntegrationsSection/IntegrationsSection';

export interface IntegrationItem {
    id: string;
    imgUrl: string;
}

const integrationsData: IntegrationItem[] = [
    {
        id: 'quickbooks',
        imgUrl: 'marketing_site/integrations_module/quickbooks.svg'
    },
    {
        id: 'calendly',
        imgUrl: 'marketing_site/features_page/integrations/calendly.png'
    },
    {
        id: 'zapier',
        imgUrl: 'marketing_site/integrations_module/zapier.svg'
    },
    {
        id: 'zoom',
        imgUrl: 'marketing_site/features_page/integrations/zoom2.png'
    },
    {
        id: 'gcalendar',
        imgUrl: 'marketing_site/integrations_module/google-cal.svg'
    },
    {
        id: 'gmail',
        imgUrl: 'marketing_site/features_page/integrations/gmail.png'
    },
    {
        id: 'icloud',
        imgUrl: 'marketing_site/features_page/integrations/icloud-mail.png'
    },
    {
        id: 'yahoo',
        imgUrl: 'marketing_site/features_page/integrations/yahoo-mail.png'
    },
    {
        id: 'outlook',
        imgUrl: 'marketing_site/features_page/integrations/outlook-mail.png'
    },
    {
        id: 'aol',
        imgUrl: 'marketing_site/features_page/integrations/aol-mail.png'
    }
];

function IntegrationsBlock(): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection customClass="integrations-block">
            <FeatureBlockContent>
                <IntegrationsSection
                    title={t('integrationsBlock.title')}
                    subtitle={t('integrationsBlock.subtitle')}
                    integrations={integrationsData}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

export default memo(IntegrationsBlock);

import StorageService from '../Services/StorageService';

const IS_REFERRAL_STORAGE_KEY = 'hb_isReferral';

const useReferral = () => {
    const setIsReferral = (isReferral: boolean) => {
        StorageService.setItem({
            key: IS_REFERRAL_STORAGE_KEY,
            data: isReferral,
            storageType: 'sessionStorage'
        });
    };
    const isReferral = StorageService.getItem(
        IS_REFERRAL_STORAGE_KEY,
        'sessionStorage'
    );
    return {
        setIsReferral,
        isReferral
    };
};

export default useReferral;

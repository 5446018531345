import { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import UtilsService from '../Services/UtilsService';
import InviteHero from '../components/Hero/InvitesHero/InvitesHero';

import IntegrationsBlock from '../components/FeatureBlocks/IntegrationsBlock/IntegrationsBlock';
import FeatureValuePropsBlock from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlock';
import TestimonialCardsBlock from '../components/FeatureBlocks/TestimonialCardsBlock/TestimonialCardsBlock';
import SmarterSystemBlock from '../components/FeatureBlocks/SmarterSystemBlock/SmarterSystemBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import useReferral from '../hooks/useReferral';

import '../styles/invites-page.scss';

const ogTags = {
    url: 'https://www.honeybook.com/invites',
    title: 'Client Management Software for Small Businesses',
    description:
        "Get clients faster with our all-in-one project, invoicing and payments management tool. HoneyBook's robust features help small businesses manage everything from brochures and proposals to contracts and online payments. Free 7-day Trial - No Credit Card Required. Voted #1 Small Business Client Management Platform."
};

const InvitePage = () => {
    const { t } = useTranslation();

    const code = UtilsService.getParameterByName('code');
    const [heroTitle, setHeroTitle] = useState('');
    const [promoBlockLeftTitle, setPromoBlockLeftTitle] = useState('');
    const [promoBlockLeftSubtitle, setPromoBlockLeftSubtitle] = useState('');
    const { setIsReferral } = useReferral();

    const fallbackHeroTitle = t('invitesPage.hero.withoutCode.title');

    const fallbackPromoBlockTitle = t('invitesPage.promoBlock.fallbackTitle');

    const setContent = (name, discount, duration) => {
        setHeroTitle(
            t('invitesPage.hero.withCode', {
                name,
                discount,
                duration
            })
        );
        setPromoBlockLeftTitle(
            <>
                {t('invitesPage.promoBlock.title1')}
                {name}
                <br /> {t('invitesPage.promoBlock.title2')}
            </>
        );
        setPromoBlockLeftSubtitle(
            <>
                {t('invitesPage.promoBlock.subtitle1')} {discount}
                {t('invitesPage.promoBlock.subtitle2')} {duration}
            </>
        );
    };

    const setFallback = () => {
        // test locally (without api call)
        // setContent('Bruce Wayne', '60% off', 'one year');
        setHeroTitle(fallbackHeroTitle);
        setPromoBlockLeftTitle(fallbackPromoBlockTitle);
    };

    useEffect(() => {
        const url = `${process.env.GATSBY_baseApiPrefix}api/referrer_marketing?code=${code}`;

        if (code) {
            if (code === 'weimuzheng4736925') {
                window.location.replace('https://www.honeybook.com');
            }

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.user.full_name != null) {
                        setContent(
                            data.user.full_name,
                            data.discount_amount,
                            data.discount_duration
                        );
                        setIsReferral(true);
                    } else {
                        // Code was invalid
                        setFallback();
                    }
                })
                // Fetch error
                .catch(() => setFallback());
        } else {
            // No code was applied
            setFallback();
        }
    }, []);

    return (
        <Layout ogTags={ogTags}>
            <div className="invites-lp">
                <InviteHero title={heroTitle} />
                <FeatureValuePropsBlock
                    hideCta={true}
                    title={t('featureValueProps.title')}
                />
                <IntegrationsBlock />
                <SmarterSystemBlock
                    title={t('invitesPage.smarterSystems.title')}
                    subtitle={t('invitesPage.smarterSystems.subtitle')}
                    ctaText={t('invitesPage.smarterSystems.ctaText')}
                />
                <TestimonialCardsBlock hideReviewsLink />
                <PromoBlockLeft
                    title={promoBlockLeftTitle}
                    subtitle={promoBlockLeftSubtitle}
                    ctaText={t('invitesPage.promoBlock.ctaText')}
                    customNoCcText={t('invitesPage.promoBlock.customNoCcText')}
                    customClass="promo-block-left--yellow promo-block-left--invites"
                    source="promo block left - yellow"
                    buttonStyle="primary--dark"
                />
            </div>
        </Layout>
    );
};

export default InvitePage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "invites"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';

const OpenPosition = ({ link, title, department, location }) => {
    let formattedLocation;

    switch (location) {
        case 'San Francisco' || 'San Francisco, CA':
            formattedLocation = '🇺🇸 San Francisco';
            break;
        case 'Austin, TX or San Francisco, CA' || 'Austin or San Francisco':
            formattedLocation = '🇺🇸 Austin or SF';
            break;
        case 'Tel Aviv' || 'Tel-Aviv':
            formattedLocation = '🇮🇱 Tel Aviv';
            break;
        default:
            formattedLocation = '🇺🇸 San Francisco';
    }

    let formattedDepartment;
    switch (department) {
        case 'Development':
            formattedDepartment = 'Product';
            break;
        case 'Customer Success':
            formattedDepartment = 'Member Experience';
            break;
        default:
            formattedDepartment = department;
    }

    return (
        <a href={link} target="_blank" className="open-position">
            <span className="open-position__text open-position__title">
                {title}
            </span>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.99996 1H13H15V3V11H13L13 4.41418L3.17154 14.2426L1.75732 12.8284L11.5857 3H4.99996V1Z"
                    fill="white"
                />
            </svg>
        </a>
    );
};

OpenPosition.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    department: PropTypes.string,
    location: PropTypes.string
};

const RoleList = ({ location, toggleButtons }) => {
    const { t } = useTranslation();
    const [careersData, setCareersData] = useState();

    const getCareers = () => {
        const apiUrl = `${process.env.GATSBY_baseApiPrefix}api/v2/careers/get_careers`;
        const structuredCareers = {};

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                data.forEach(position => {
                    const location =
                        position.location.name === toggleButtons[0].text
                            ? toggleButtons[0].text
                            : toggleButtons[1].text;
                    const department = position.departments[0].name;

                    structuredCareers[location] ||= {};
                    structuredCareers[location][department] ||= [];

                    structuredCareers[location][department].push({
                        link: position.absolute_url,
                        title: position.title
                    });
                });

                setCareersData(structuredCareers);
            })
            .catch(() => {});
    };

    const shownCareers = useMemo(() => {
        const jobsObj = careersData?.[location] || {};
        const structuredData = Object.entries(jobsObj).map(entry => ({
            name: entry[0],
            jobsList: entry[1]
        }));
        return structuredData;
    }, [careersData, location]);

    useEffect(() => {
        getCareers();
    }, []);

    return (
        <div className="role-list">
            <FeatureBlockSection customClass="open-positions" lightBackground>
                <FeatureBlockContent>
                    <FeatureBlockText
                        customClass="open-positions__title"
                        primary={`Open positions in ${location}`}
                        fullWidth
                    />
                    <div className="open-positions__container">
                        {!!shownCareers.length ? (
                            shownCareers.map(department => (
                                <div className="department-job-list">
                                    <h3 className="department-name">
                                        {department.name}
                                    </h3>
                                    {department.jobsList.map(position => (
                                        <OpenPosition
                                            link={position.link}
                                            title={position.title}
                                        />
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p className="open-positions__no-positions">
                                {t('careersPageRebrand.openRoles.noOpenRoles')}
                            </p>
                        )}
                    </div>
                </FeatureBlockContent>
            </FeatureBlockSection>
        </div>
    );
};

RoleList.propTypes = {
    location: PropTypes.string,
    toggleButtons: PropTypes.array
};

export default RoleList;

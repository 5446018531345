import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import {
    FeatureBlockContent,
    FeatureBlockSection,
    FeatureBlockText
} from '../../FeatureBlockElements';

import './picture-and-text-column-block.scss';

const PictureAndTextColumnBLock = ({
    t,
    customClass = '',
    title,
    description,
    textBlocks,
    image,
    imageAlt,
    lightBackground,
    darkBackground,
    children,
    ...imageProps
}) => {
    return (
        <FeatureBlockSection
            customClass={`picture-and-text-column-block ${customClass}`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <div className="picture-and-text-column-block__container">
                    <div className="picture-and-text-column-block__column picture-and-text-column-block__column--text">
                        <FeatureBlockText
                            primary={title}
                            bodyText={description}
                            customClass="picture-and-text-column-block__feature-text"
                        />
                        {textBlocks?.map(block => {
                            return (
                                <div
                                    key={block.title}
                                    className="picture-and-text-column-block__text-block"
                                >
                                    <h3 className="picture-and-text-column-block__title">
                                        {t(`${block.title}`)}
                                    </h3>

                                    <p className="picture-and-text-column-block__body">
                                        {t(`${block.body}`)}
                                    </p>
                                </div>
                            );
                        })}
                        {children}
                    </div>
                    <div className="picture-and-text-column-block__column picture-and-text-column-block__column--image">
                        <HBMediaElement
                            name={image}
                            alt={imageAlt || title}
                            crop="scale"
                            {...imageProps}
                        />
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

PictureAndTextColumnBLock.propTypes = {
    customClass: PropTypes.string,
    t: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    textBlocks: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    children: PropTypes.node
};

export default withTranslation()(PictureAndTextColumnBLock);

import { useABTestContext } from './../ab-tests/context/useABTestContext';
import {
    MAP_OF_PROMO_CODES_CONFIG,
    DEFAULT_PROMO_CODE,
    Coupons
} from './../constants/promoCodes';
import UtilsService from '../Services/UtilsService';

export const usePromoCode = () => {
    const {
        variantPerTest: { join30_vs_join50_promo }
    } = useABTestContext();

    const userPromoCode =
        UtilsService.getCouponFromQueryParams() ||
        (UtilsService.isBrowser() && UtilsService.getCouponFromCookies());

    const isUserPromoCodeValid =
        !!userPromoCode && !!MAP_OF_PROMO_CODES_CONFIG[userPromoCode];

    const promoCode = isUserPromoCodeValid ? userPromoCode : DEFAULT_PROMO_CODE;

    const isJoin30Exp =
        promoCode === DEFAULT_PROMO_CODE &&
        join30_vs_join50_promo === Coupons.join30;

    return {
        promoCode: isJoin30Exp ? Coupons.join30 : promoCode
    };
};

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';
import UtilsService from '../../../Services/UtilsService';

import './webinar-category-links-block.scss';

const handleClick = webinarId => {
    AnalyticsService.trackClick(AnalyticsEvents.webinarNav, {
        source: webinarId
    });

    UtilsService.scrollToElementId(`webinar-block__${webinarId}`);
};

const WebinarCategoryLinksBlock = ({ t, webinars }) => {
    return (
        <FeatureBlockSection customClass="webinar-category-links">
            <FeatureBlockContent>
                <h3 className="webinar-category-links__title">
                    {t(`productEducationPage.webinars.navBar.title`)}
                </h3>
                <div className="webinar-category-links__link-container">
                    {webinars.map(webinar => {
                        return (
                            <span
                                key={webinar.id}
                                onClick={() => handleClick(webinar.id)}
                                className={`webinar-category-links__link webinar-category-links__link--${webinar.id}`}
                            >
                                {t(
                                    `productEducationPage.webinars.${webinar.id}.title`
                                )}
                            </span>
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

WebinarCategoryLinksBlock.propTypes = {
    t: PropTypes.func,
    webinars: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withTranslation()(WebinarCategoryLinksBlock);

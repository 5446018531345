import type { MouseEvent as ReactMouseEvent, MouseEventHandler } from 'react';
import { memo, useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HBIcon from '../honeybook-ui/HBIcon/HBIcon';
import HBDrawer from '../honeybook-ui/HBDrawer/HBDrawer';
import { NavBarItem, sendAnalytics } from '../NavBar/NavBar';
import { logIn } from './login';
import MobileMenuItem from '../MobileMenuItem/MobileMenuItem';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import HBLogo from '../../svg/honeybook-logo.inline.svg';

import './mobile-menu-drawer.scss';
import HBButton from '../honeybook-ui/HBButton/HBButton';

interface Props {
    items: NavBarItem[];
    isMobileDrawerOpen: boolean;
    toggleMobileDrawer: MouseEventHandler<HTMLSpanElement>;
    isRebrand?: boolean;
}

// TODO: I remove isMobileDrawerOpen
function MobileMenuDrawer({ items, toggleMobileDrawer }: Props): JSX.Element {
    const { t } = useTranslation();

    const [currentOpenItemId, setCurrentOpenItemId] = useState();

    const navRef = useRef();

    let lastScrollY = 0;

    const handleScroll = useCallback((): void => {
        if (typeof window !== 'undefined') {
            lastScrollY = window.scrollY;

            if (navRef.current !== null) {
                if (lastScrollY > navRef.current.offsetHeight) {
                    navRef.current.classList.add('scrolling');
                } else {
                    navRef.current.classList.remove('scrolling');
                }
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleMobileMenuItemClick = useCallback(
        (
            event: ReactMouseEvent<HTMLAnchorElement> &
                ReactMouseEvent<HTMLButtonElement>
        ): void => {
            const { itemId } = event.currentTarget.dataset;

            const clickedItem = items.find(item => item.id === itemId);

            if (typeof clickedItem !== 'undefined') {
                sendAnalytics(event, clickedItem.mobileAnalyticsTarget);

                if (clickedItem.subItems) {
                    if (currentOpenItemId === itemId) {
                        setCurrentOpenItemId('');
                    } else {
                        setCurrentOpenItemId(itemId || '');
                    }
                } else if (typeof clickedItem.onClick === 'function') {
                    clickedItem.onClick(event);
                }
            }
        },
        [currentOpenItemId, items]
    );

    return (
        <HBDrawer customClass="mobile-menu-drawer">
            <div className="mobile-drawer-content-wrapper" role="menu">
                <div ref={navRef} className="top-menu-item-container">
                    <div
                        className="mobile-menu-item top-menu-item"
                        tabIndex={-1}
                        role="button"
                    >
                        <HBLogo />
                    </div>
                    <HBIcon
                        name="nx-close"
                        customClass="close-button"
                        onClick={toggleMobileDrawer}
                    />
                </div>
                {items.map((item, index) => {
                    if (item.id !== 'home') {
                        return (
                            <MobileMenuItem
                                key={item.id}
                                item={item}
                                onClick={handleMobileMenuItemClick}
                                index={index}
                                isCollapseOpen={currentOpenItemId === item.id}
                            />
                        );
                    }

                    return null;
                })}
                <div className="mobile-menu-drawer__button-container">
                    <HBButton
                        text={t(`navBarButtons.logIn`)}
                        onClick={logIn}
                        buttonStyle="secondary"
                    />
                    <RegistrationForm
                        size="large"
                        source="mobile menu drawer CTA"
                        buttonStyle="primary"
                    />
                </div>
            </div>
        </HBDrawer>
    );
}

export default memo(MobileMenuDrawer);

import * as PropTypes from 'prop-types';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import { InputStyles } from '../../honeybook-ui/HBInput/HBInput';
import { useTranslation } from 'react-i18next';
import Hero from '../Hero/Hero';
import HeroTitle from '../HeroTitle/HeroTitle';
import HeroStrip from '../HeroStrip/HeroStrip';
import HeroColumn from '../HeroColumn/HeroColumn';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';

import './horizontal-hero.scss';

const HorizontalHero = ({
    image,
    imageAltTag,
    preTitle,
    mainTitle,
    description,
    imageWidth = 1280,
    mobileImage,
    showRegistration,
    showRegistrationCta,
    customClass = '',
    titleCustomClass = '',
    isVideo,
    children,
    buttonSize,
    buttonText,
    ...imageProps
}) => {
    const { t } = useTranslation();

    return (
        <Hero customClass={`horizontal-hero ${customClass}`}>
            <HeroStrip>
                <HeroColumn
                    customClass={`horizontal-hero__column horizontal-hero__column--text ${
                        showRegistration
                            ? ''
                            : 'horizontal-hero__column--height'
                    }`}
                >
                    <HeroTitle
                        customClass={titleCustomClass}
                        preTitle={preTitle}
                        title={mainTitle}
                        subtitle={description}
                    />
                    {children}
                    {(showRegistration || showRegistrationCta) && (
                        <RegistrationForm
                            inputStyle={InputStyles.SIMPLE_INPUT_DARK}
                            showSSO={!showRegistrationCta}
                            showNoCcRequired={!showRegistrationCta}
                            size={buttonSize}
                            source="hero"
                            direction="row"
                            buttonText={buttonText}
                        />
                    )}

                    <HBMediaElement
                        customClass="hero__img horizontal-hero__media-element"
                        name={image}
                        type={isVideo ? 'video' : 'Image'}
                        forceImgTag
                        alt={t(imageAltTag)}
                        {...imageProps}
                        width={imageWidth}
                        crop="scale"
                        playsInline
                        autoPlay
                        muted
                    />
                    {mobileImage ? (
                        <HBMediaElement
                            customClass="hero__img horizontal-hero__media-element--mobile"
                            name={mobileImage}
                            width={375}
                            crop="scale"
                            alt={t(imageAltTag)}
                            {...imageProps}
                        />
                    ) : null}
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

HorizontalHero.propTypes = {
    buttonSize: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
    buttonText: PropTypes.string,
    preTitle: PropTypes.string,
    mainTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageAltTag: PropTypes.string,
    imageWidth: PropTypes.number,
    mobileImage: PropTypes.string,
    showRegistration: PropTypes.bool,
    showRegistrationCta: PropTypes.bool,
    customClass: PropTypes.string,
    titleCustomClass: PropTypes.string,
    isVideo: PropTypes.bool,
    children: PropTypes.node
};

HorizontalHero.defaultProps = {
    buttonSize: 'medium'
};

export default HorizontalHero;

import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import ProductEducationHero from '../components/Hero/ProductEducationHero/ProductEducationHero';
import MeetOurInstructorsBlock from '../components/FeatureBlocks/MeetOurInstructorsBlockStore/MeetOurInstructorsBlock';
import WebinarCategoryLinksBlock from '../components/FeatureBlocks/WebinarCategoryLinksBlock/WebinarCategoryLinksBlock';
import WebinarBlock from '../components/FeatureBlocks/WebinarBlock/WebinarBlock';

const webinars = [
    {
        id: 'hb101',
        link: '//help.honeybook.com/en/articles/2554463-honeybook-basics-introduction-to-your-new-account-on-demand-webinar',
        overviewKeys: ['one', 'two', 'three', 'four', 'five', 'six', 'seven'],
        webinarSlots: [
            {
                title: 'HB101: HoneyBook Orientation',
                date: 'June 29, 2021',
                startTime: '9:00 AM',
                endTime: '10:00 AM',
                link: '//www.crowdcast.io/e/a1r1jr4m'
            },
            {
                title: 'HB101: HoneyBook Orientation',
                date: 'July 13, 2021',
                startTime: '9:00 AM',
                endTime: '10:00 AM',
                link: '//www.crowdcast.io/e/2s3kc0ya'
            },
            {
                title: 'HB101: HoneyBook Orientation',
                date: 'July 20, 2021',
                startTime: '9:00 AM',
                endTime: '10:00 AM',
                link: '//www.crowdcast.io/e/093vp158'
            },
            {
                title: 'HB101: HoneyBook Orientation',
                date: 'July 27, 2021',
                startTime: '9:00 AM',
                endTime: '10:00 AM',
                link: '//www.crowdcast.io/e/340cxdei'
            },
            {
                title: 'HB101: HoneyBook Orientation',
                date: 'August 3, 2021',
                startTime: '9:00 AM',
                endTime: '10:00 AM',
                link: '//www.crowdcast.io/e/sontqtvm'
            }
        ]
    },
    {
        id: 'hb102',
        recordings: [
            {
                id: 'hb102Contact',
                link: '//help.honeybook.com/articles/3097462-hb-102-contact-forms-workflows-webinar'
            },
            {
                id: 'hb102Brochures',
                link: '//help.honeybook.com/en/articles/3270190-hb-102-brochure-creation-client-side-experience'
            },
            {
                id: 'hb102Transitioning',
                link: '//www.crowdcast.io/e/ohc3nqhp'
            },
            {
                id: 'hb102Payments',
                link: '//help.honeybook.com/en/articles/3117440-hb-102-managing-payments-in-honeybook'
            }
        ]
    }
];

const ogTags = {
    url: '//www.honeybook.com/product-education',
    title: 'Product Education Resources and Help Center',
    description:
        "Access the training to take full advantage of Honeybook's business tools. Maximize their integration into your business with our product education resources."
};

function ProductEducationTemplate() {
    return (
        <div className="product-education-page">
            <Layout ogTags={ogTags}>
                <ProductEducationHero />
                <WebinarCategoryLinksBlock webinars={webinars} />
                <WebinarBlock webinars={webinars} />
                <MeetOurInstructorsBlock />
            </Layout>
        </div>
    );
}

export default ProductEducationTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "productEducation"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

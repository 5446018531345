import { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import UtilsService from '../../Services/UtilsService';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import PromoBanner from '../PromoBanner/PromoBanner';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import { PROMO_BANNER_EXCLUSION_PATHS } from '../../constants/constants';
import { usePromoCode } from '../../hooks/usePromoCode';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MAP_OF_PROMO_CODES_CONFIG } from '../../constants/promoCodes';
import useReferral from '../../hooks/useReferral';

const DiscountBanner = () => {
    const { t } = useTranslation();
    const { isMediumUp } = useWindowSize();
    const { promoCode } = usePromoCode();
    const [isCurrentUrlInBannerList, setIsCurrentUrlInBannerList] =
        useState(false);
    const { isReferral } = useReferral();

    useEffect(() => {
        setIsCurrentUrlInBannerList(
            UtilsService.isCurrentUrlInList(PROMO_BANNER_EXCLUSION_PATHS)
        );
    }, []);

    const onPromoBannerClose = () => {
        AnalyticsService.track(AnalyticsEvents.welcome20_promo_banner_close);
    };

    const title = t(
        `${promoCode}.banner.${isMediumUp ? 'titleDesktop' : 'titleMobile'}`
    );

    const theme = MAP_OF_PROMO_CODES_CONFIG[promoCode].bannerTheme;

    const shouldShowBanner = !isReferral && !isCurrentUrlInBannerList;

    return (
        <PromoBanner
            id="2024-01-hb2024save"
            onClose={onPromoBannerClose}
            userCanDismiss
            shouldShowBanner={shouldShowBanner}
            customClass={`promo-banner promo-banner--${promoCode}`}
            theme={theme}
        >
            <div className="promo-banner__text-container">
                <div className={`promo-banner__title`}>{title}</div>
                <RegistrationForm
                    customClass="promo-banner-cta"
                    buttonStyle="invisible"
                    buttonText="Start free trial"
                    source="promo banner"
                    couponCode={promoCode}
                    size="x-small"
                />
            </div>
        </PromoBanner>
    );
};

export default memo(DiscountBanner);

import { useState, useEffect, memo, PropsWithChildren } from 'react';
import HBIcon from '../../components/honeybook-ui/HBIcon/HBIcon';
import StorageService from '../../Services/StorageService';
import UtilsService from '../../Services/UtilsService';

import './promo-banner.scss';

export type TPromoBannerTheme = 'light' | 'dark' | 'purple';

interface Props {
    id: string;
    onClose: () => void;
    userCanDismiss: boolean;
    customClass?: string;
    theme?: TPromoBannerTheme;
    shouldShowBanner?: boolean;
}

const BANNER_CLOSE_CLASS_NAME = 'promo-banner-close';
const BANNER_OPEN_CLASS_NAME = 'promo-banner-open';

const PromoBanner = ({
    id,
    children,
    onClose,
    userCanDismiss = true,
    customClass,
    theme = 'light',
    shouldShowBanner
}: PropsWithChildren<Props>) => {
    const bannerId = `bannerTriggered-${id}`;

    const [hasDismissedPromo, setHasDismissedPromo] =
        useState(shouldShowBanner);

    const isVisible = !hasDismissedPromo && shouldShowBanner;

    useEffect(() => {
        const dismissedPromoStatus = StorageService.getItem(bannerId) !== null;
        setHasDismissedPromo(dismissedPromoStatus);
    }, []);

    useEffect(() => {
        const bodyClassList = document.body.classList;
        if (isVisible) {
            if (bodyClassList.contains(BANNER_CLOSE_CLASS_NAME)) {
                bodyClassList.replace(
                    BANNER_CLOSE_CLASS_NAME,
                    BANNER_OPEN_CLASS_NAME
                );
            } else {
                bodyClassList.add(BANNER_OPEN_CLASS_NAME);
            }
        } else {
            if (bodyClassList.contains(BANNER_OPEN_CLASS_NAME)) {
                bodyClassList.replace(
                    BANNER_OPEN_CLASS_NAME,
                    BANNER_CLOSE_CLASS_NAME
                );
            } else {
                bodyClassList.add(BANNER_CLOSE_CLASS_NAME);
            }
        }
    }, [isVisible]);

    const handleOnClose = () => {
        StorageService.setItem({ key: bannerId, data: 'true' });
        setHasDismissedPromo(true);
        onClose?.();
    };

    return UtilsService.isBrowser() && !isVisible ? null : (
        <div
            className={`promo-banner ${customClass} ${
                !hasDismissedPromo && shouldShowBanner ? 'show' : ''
            } promo-banner--${theme}`}
        >
            {userCanDismiss && (
                <HBIcon
                    name="nx-close"
                    customClass="promo-banner__close"
                    onClick={handleOnClose}
                />
            )}
            <div className="promo-banner__content">{children}</div>
        </div>
    );
};

export default memo(PromoBanner);

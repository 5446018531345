import PropTypes from 'prop-types';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import './picture-and-text-block.scss';
import HBText from '../../honeybook-ui/HBText/HBText';

const PictureAndTextBlockNew = ({
    image,
    imageMobile,
    primary,
    secondary,
    imageWidth,
    imageAltTag,
    customClass = '',
    children,
    lightBackground,
    darkBackground
}) => {
    const breakpoints = useBreakpoint();

    return (
        <FeatureBlockSection
            customClass={`${customClass} picture-and-text-block`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent customClass="picture-and-text-block__feature-text-container">
                <HBText tag="h2" type="title-m">
                    {primary}
                </HBText>
                {breakpoints.large &&
                    (imageMobile ? (
                        <HBMediaElement
                            width={imageWidth || 570}
                            name={imageMobile}
                            alt={imageAltTag}
                        />
                    ) : (
                        <HBMediaElement
                            width={imageWidth || 800}
                            name={image}
                            alt={primary}
                        />
                    ))}
                <HBText tag="p" type="body-m">
                    {secondary}
                </HBText>
                {children}
            </FeatureBlockContent>
            <FeatureBlockContent customClass="picture-and-text-block__feature-image-container">
                {breakpoints.largeUp && (
                    <HBMediaElement
                        crop="scale"
                        width={imageWidth || 800}
                        name={image}
                        alt={imageAltTag || primary}
                    />
                )}
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

PictureAndTextBlockNew.defaultProps = {
    customClass: ''
};

PictureAndTextBlockNew.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    image: PropTypes.string,
    imageMobile: PropTypes.string,
    imageAltTag: PropTypes.string,
    imageWidth: PropTypes.number,
    customClass: PropTypes.string,
    children: PropTypes.node,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default PictureAndTextBlockNew;

import { memo, ReactNode, ReactElement } from 'react';

import './nav-submenu-item.scss';

import type { MouseEventHandler } from 'react';
import NavigateLink from '../Link/NavigateLink';

interface Props {
    title: string;
    description: string;
    link: string;
    icon?: ReactNode;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function NavSubMenuItem({
    title,
    description,
    onMouseEnter,
    onClick,
    link,
    icon
}: Props): ReactElement {
    return (
        <NavigateLink
            source="navbar_menu"
            link={link}
            className="nav-submenu-item"
            onClick={onClick}
            role="menuitem"
            tabIndex={-1}
            onMouseEnter={onMouseEnter}
        >
            {icon && (
                <div className="nav-submenu-item__icon-container">
                    <div className="media-element svg-content">{icon}</div>
                </div>
            )}
            <div className="nav-submenu-item__text-container">
                <div className="nav-submenu-item__text-container__title">
                    {title}
                </div>
                <div className="nav-submenu-item__text-container__description">
                    {description}
                </div>
            </div>
        </NavigateLink>
    );
}

export default memo(NavSubMenuItem);

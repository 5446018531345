import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../FeatureBlockElements';

import HBText from '../../honeybook-ui/HBText/HBText';
import IconCircleAnimation from '../../IconCircleAnimation/IconCircleAnimation';
import './feature-integration-block.scss';

const defaultIntegrationsIcons = {
    outer: [
        'marketing_site/online-contracts/integration/outer_1_2x.png',
        'marketing_site/online-contracts/integration/outer_2_2x.png',
        'marketing_site/online-contracts/integration/outer_3_2x.png',
        'marketing_site/online-contracts/integration/outer_4_2x.png',
        'marketing_site/online-contracts/integration/outer_5_2x.png',
        'marketing_site/online-contracts/integration/outer_6_2x.png'
    ],
    inner: [
        'marketing_site/online-contracts/integration/inner_1_2x.png',
        'marketing_site/online-contracts/integration/inner_2_2x.png',
        'marketing_site/online-contracts/integration/inner_3_2x.png',
        'marketing_site/online-contracts/integration/inner_4_2x.png'
    ]
};

interface Props {
    title: string;
    subtitle: string;
    icons?: { [key: string]: string[] };
}

const FeatureIntegrationBlock = ({
    title,
    subtitle,
    icons = defaultIntegrationsIcons
}: Props) => {
    return (
        <FeatureBlockSection customClass="feature-integration-block">
            <FeatureBlockContent customClass="feature-integration-block__content">
                <div className="feature-integration-block__text-container">
                    <HBText
                        tag="h2"
                        type="title-l"
                        customClass="feature-integration-block__title"
                    >
                        {title}
                    </HBText>
                    <HBText
                        tag="p"
                        type="body-m"
                        customClass="feature-integration-block__subtitle"
                    >
                        {subtitle}
                    </HBText>
                </div>
                <IconCircleAnimation
                    outerIcons={icons.outer}
                    innerIcons={icons.inner}
                    innerRadius={150}
                    outerRadius={250}
                    customClass={
                        'feature-integration-block__animation-container'
                    }
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureIntegrationBlock;

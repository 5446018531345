const MeetOurInstructorsStore = {
    instructors: [
        {
            title: 'Mac Hughes',
            imagePath: 'marketing_site/webinars/headshot.square.png',
            mediaFlags: {
                width: 180,
                height: 180,
                crop: 'fill',
                gravity: 'face',
                radius: 'max'
            }
        },
        {
            title: 'Ashli Hansen',
            imagePath: 'marketing_site/webinars/IMG_3829.heic',
            mediaFlags: {
                height: 180,
                width: 180,
                crop: 'fill',
                gravity: 'face',
                radius: 'max'
            }
        },
        {
            title: 'Lindsey Burger',
            imagePath: 'marketing_site/webinars/Lindsey.png',
            mediaFlags: {
                height: 180,
                width: 180
            }
        }
    ]
};

export default MeetOurInstructorsStore;

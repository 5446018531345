const TemplatesStore = {
    templateLinkPath: {
        consultantContract: {
            moreInvoices: '/freelance-consulting-contract-template',
            softwareFor: '/crm-for-consultants'
        },
        photoContract: {
            moreInvoices: '/photography-contract-template',
            softwareFor: '/crm-for-photographers'
        },
        doula: {
            moreInvoices: '/free-invoice-templates/doula',
            softwareFor: '/doula-business-software'
        },
        design: {
            moreInvoices: '/free-invoice-templates/designers',
            softwareFor: '/crm-for-designers'
        },
        graphicDesign: {
            moreInvoices: '/free-invoice-templates/graphic-design',
            softwareFor: '/crm-for-graphic-designers'
        },
        interiorDesign: {
            moreInvoices: '/free-invoice-templates/interior-design',
            softwareFor: '/interior-design-business-software'
        },
        consultants: {
            moreInvoices: '/free-invoice-templates/consultants',
            softwareFor: '/crm-for-consultants'
        },
        photographers: {
            moreInvoices: '/free-invoice-templates/photographers',
            softwareFor: '/crm-for-photographers'
        },
        coach: {
            moreInvoices: '/free-invoice-templates/coach',
            softwareFor: '/life-coach-software'
        },
        tutors: {
            moreInvoices: '/free-invoice-templates/tutors',
            softwareFor: '/tutor-software'
        },
        cleaners: {
            moreInvoices: '/free-invoice-templates/cleaners',
            softwareFor: '/software-for-cleaning-business'
        },
        virtualAssistant: {
            moreInvoices: '/free-invoice-templates/virtual-assistant',
            softwareFor: '/virtual-assistant-software'
        }
    },
    templates: [
        {
            id: 'projectManagerVirtualAssistant',
            templateId: '6294528ae6967904337bed31',
            vertical: 'virtualAssistant',
            imagePath:
                'marketing_site/invoice-generator-lp/virtual-assistant/Module%202/Project_Manager.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1613084552/marketing_site/invoice-generator-lp/virtual-assistant/PDF_s/Project_Manager_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'virtualMarketingAssistant',
            templateId: '62945271e6967904337bec89',
            vertical: 'virtualAssistant',
            imagePath:
                'marketing_site/invoice-generator-lp/virtual-assistant/Module%202/marketing.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1613084563/marketing_site/invoice-generator-lp/virtual-assistant/PDF_s/marketing_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'adminVirtualAssistant',
            templateId: '62945259e6967904337bebe1',
            vertical: 'virtualAssistant',
            imagePath:
                'marketing_site/invoice-generator-lp/virtual-assistant/Module%202/admin.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1613084561/marketing_site/invoice-generator-lp/virtual-assistant/PDF_s/admin_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'consultantIndyContract',
            templateId: '62945392e6967904337bf219',
            vertical: 'consultantContract',
            imagePath:
                'marketing_site/contract-templates/Consultants/Module%202/Independent.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1612308528/marketing_site/contract-templates/PDF/Independent-freelance/Independent-freelance_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'consultantGeneralContract',
            templateId: '62945379e6967904337bf135',
            vertical: 'consultantContract',
            imagePath:
                'marketing_site/contract-templates/Consultants/Module%202/General.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1612308525/marketing_site/contract-templates/PDF/General-freelancer/General-freelancer-contract_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'photoGeneralContract',
            templateId: '62945356e6967904337bf051',
            vertical: 'photoContract',
            imagePath:
                'marketing_site/photography-contract/Module%202/General_SmartFile.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1610127367/marketing_site/photography-contract/PDFs/General_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'photoIndyContract',
            templateId: '6294533de6967904337bef5e',
            vertical: 'photoContract',
            imagePath:
                'marketing_site/photography-contract/Module%202/Independent.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1610127409/marketing_site/photography-contract/PDFs/Independent_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'photoShootContract',
            templateId: '62945322e6967904337beede',
            vertical: 'photoContract',
            imagePath:
                'marketing_site/photography-contract/Module%202/Styled_shoot.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1610127392/marketing_site/photography-contract/PDFs/Shoot_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'photoReleaseContract',
            templateId: '62945309e6967904337bee63',
            vertical: 'photoContract',
            imagePath:
                'marketing_site/photography-contract/Module%202/Model_release_SmartFile.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1610127378/marketing_site/photography-contract/PDFs/Model_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'photoEventContract',
            templateId: '629452ece6967904337bedd9',
            vertical: 'photoContract',
            imagePath:
                'marketing_site/photography-contract/Module%202/Event_Smart_File.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1610127372/marketing_site/photography-contract/PDFs/Event_SmartFile.pdf',
            type: 'contract'
        },
        {
            id: 'birthPlan',
            templateId: '6294515be6967904337be671',
            vertical: 'doula',
            imagePath:
                'marketing_site/invoice-generator-lp/doula/BirthPlanNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1596565376/marketing_site/invoice-generator-lp/pdfs/doula/Birth_plan_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'virtualBirthPlan',
            templateId: '62945177e6967904337be72b',
            vertical: 'doula',
            imagePath:
                'marketing_site/invoice-generator-lp/doula/VirtualBirthPlanNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1596565370/marketing_site/invoice-generator-lp/pdfs/doula/Virtual_Birth_plan_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'postPartumPlan',
            templateId: '6294513ce6967904337be5b3',
            vertical: 'doula',
            imagePath:
                'marketing_site/invoice-generator-lp/doula/PostpartumPlanNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1596565369/marketing_site/invoice-generator-lp/pdfs/doula/Postpartum_Plan_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'brandingGraphicDesign',
            templateId: '629363a809a87500583834a5',
            vertical: 'graphicDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/graphic-design/Branding.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462522/marketing_site/invoice-generator-lp/pdfs/Graphic%20Designer/Branding_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'packagingLabelsGraphic',
            templateId: '6293635309a87500583833fd',
            vertical: 'graphicDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/graphic-design/Packaging.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462532/marketing_site/invoice-generator-lp/pdfs/Graphic%20Designer/Packaging_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'webMobileAppDesign',
            templateId: '6293649209a8750058383768',
            vertical: 'graphicDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/graphic-design/Web_Design.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653558885/marketing_site/invoice-generator-lp/pdfs/Graphic%20Designer/Web_design_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'remodelingRenovationFixDesign',
            templateId: '6293640109a875005838354d',
            vertical: 'design',
            imagePath:
                'marketing_site/invoice-generator-lp/interior-design/Remodelling.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653559061/marketing_site/invoice-generator-lp/pdfs/Interior%20Designer/Remodelling_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'brandingDesign',
            templateId: '629363a809a87500583834a5',
            vertical: 'design',
            imagePath:
                'marketing_site/invoice-generator-lp/graphic-design/Branding.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462522/marketing_site/invoice-generator-lp/pdfs/Graphic%20Designer/Branding_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'packagingLabels',
            templateId: '6293635309a87500583833fd',
            vertical: 'design',
            imagePath:
                'marketing_site/invoice-generator-lp/graphic-design/Packaging.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462532/marketing_site/invoice-generator-lp/pdfs/Graphic%20Designer/Packaging_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'privateResidenceDesign',
            templateId: '6293645c09a87500583836ab',
            vertical: 'interiorDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/interior-design/Private_Residence',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653559062/marketing_site/invoice-generator-lp/pdfs/Interior%20Designer/Private_Residence_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'remodelingRenovationFix',
            templateId: '6293640109a875005838354d',
            vertical: 'interiorDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/interior-design/Remodelling.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462546/marketing_site/invoice-generator-lp/pdfs/Interior%20Designer/Remodelling___Renovation_Fix.pdf',
            type: 'invoice'
        },
        {
            id: 'officeSpaceDesign',
            templateId: '6293644109a87500583835f5',
            vertical: 'interiorDesign',
            imagePath:
                'marketing_site/invoice-generator-lp/interior-design/Office_Space.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653559060/marketing_site/invoice-generator-lp/pdfs/Interior%20Designer/Office_Space_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'brandConsulting',
            templateId: '62935f1009a8750058383355',
            vertical: 'consultants',
            imagePath:
                'marketing_site/invoice-generator-lp/consultants/Brand.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462498/marketing_site/invoice-generator-lp/pdfs/Business%20Consultant/Branding_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'socialMediaConsulting',
            templateId: '62935ef309a87500583832a1',
            vertical: 'consultants',
            imagePath:
                'marketing_site/invoice-generator-lp/consultants/Social_Media.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462525/marketing_site/invoice-generator-lp/pdfs/Business%20Consultant/Social_Media_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'seoConsulting',
            templateId: '62935ed209a87500583831f9',
            vertical: 'consultants',
            imagePath:
                'marketing_site/invoice-generator-lp/consultants/SEO.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462503/marketing_site/invoice-generator-lp/pdfs/Business%20Consultant/SEO_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'portraitPhotography',
            templateId: '62945196e6967904337be7e5',
            vertical: 'photographers',
            imagePath:
                'marketing_site/invoice-generator-lp/photographers/Portrait.png',
            imageAlt: 'Portrait Photography Invoice Template',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653559440/marketing_site/invoice-generator-lp/pdfs/Photographer%20/Portrait_photography_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'foodPhotography',
            templateId: '629451b2e6967904337be88d',
            vertical: 'photographers',
            imagePath:
                'marketing_site/invoice-generator-lp/photographers/Food.png',
            imageAlt: 'Food Photography Invoice Template',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462533/marketing_site/invoice-generator-lp/pdfs/Photographer%20/Food_photography.pdf',
            type: 'invoice'
        },
        {
            id: 'eventPhotography',
            templateId: '629451dde6967904337be935',
            vertical: 'photographers',
            imagePath:
                'marketing_site/invoice-generator-lp/photographers/Event.png',
            imageAlt: 'Event Photography Invoice Template',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1653559427/marketing_site/invoice-generator-lp/pdfs/Photographer%20/Wedding_Photograpy_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'healthCoach',
            templateId: '62935e4309a8750058383001',
            vertical: 'coach',
            imagePath:
                'marketing_site/invoice-generator-lp/coach/Health_Coachin.png',
            softwareFor: '/health-coach-software',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462548/marketing_site/invoice-generator-lp/pdfs/Coach/Health_Coaching_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'businessCoach',
            templateId: '62935e9009a8750058383151',
            vertical: 'coach',
            imagePath:
                'marketing_site/invoice-generator-lp/coach/Business_Coachin.png',
            softwareFor: '/life-coach-software',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462500/marketing_site/invoice-generator-lp/pdfs/Coach/Business_Coaching_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'careerCoach',
            templateId: '62935e7409a87500583830a9',
            vertical: 'coach',
            imagePath:
                'marketing_site/invoice-generator-lp/coach/Career_Coachin.png',
            softwareFor: '/life-coach-software',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1598462475/marketing_site/invoice-generator-lp/pdfs/Coach/Career_Coachin_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'homeschool',
            templateId: '6294523ae6967904337beb35',
            vertical: 'tutors',
            imagePath:
                'marketing_site/invoice-generator-lp/tutors/Module%202/Homeschool3.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741284/marketing_site/invoice-generator-lp/pdfs/Tutor/Homeschool_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'onlineTutoring',
            templateId: '6294521ee6967904337bea89',
            vertical: 'tutors',
            imagePath:
                'marketing_site/invoice-generator-lp/tutors/Module%202/Online3.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741287/marketing_site/invoice-generator-lp/pdfs/Tutor/Online_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'elementaryAndMiddleSchool',
            templateId: '62945201e6967904337be9dd',
            vertical: 'tutors',
            imagePath:
                'marketing_site/invoice-generator-lp/tutors/Module%202/Elementary3.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741280/marketing_site/invoice-generator-lp/pdfs/Tutor/Elementary_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'homeCleaning',
            templateId: '62935dfa09a8750058382eb1',
            vertical: 'cleaners',
            imagePath:
                'marketing_site/invoice-generator-lp/cleaners/Module%202/ApartmentNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741315/marketing_site/invoice-generator-lp/pdfs/Cleaner/Apartment_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'officeCleaning',
            templateId: '62935e2209a8750058382f59',
            vertical: 'cleaners',
            imagePath:
                'marketing_site/invoice-generator-lp/cleaners/Module%202/OfficeNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741322/marketing_site/invoice-generator-lp/pdfs/Cleaner/Office_SmartFile.pdf',
            type: 'invoice'
        },
        {
            id: 'movingCleaning',
            templateId: '62935b5d3b6df7006371c35b',
            vertical: 'cleaners',
            imagePath:
                'marketing_site/invoice-generator-lp/cleaners/Module%202/MovingNew.png',
            downloadLink:
                'https://res.cloudinary.com/honeybook/image/upload/v1603741317/marketing_site/invoice-generator-lp/pdfs/Cleaner/Moving_SmartFile.pdf',
            type: 'invoice'
        }
    ]
};

export default TemplatesStore;

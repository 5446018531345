import type { TPromoBannerTheme } from '../components/PromoBanner/PromoBanner';

export enum Coupons {
    welcome20 = 'welcome20',
    welcome50 = 'welcome50',
    joinhb = 'joinhb',
    cyber23 = 'cyber23',
    hb2024save = 'hb2024save',
    start50 = 'start50',
    save50 = 'save50',
    join50 = 'join50',
    join30 = 'join30',
    nopromo = 'nopromo'
}

export const DEFAULT_PROMO_CODE = Coupons.join50;

interface IMapOfPromoCodesConfig {
    [key: string]: {
        discount: {
            monthly: {
                fixedPrice?: number;
                discountPercentage?: number;
            };
            annualMonthly: {
                fixedPrice?: number;
                discountPercentage?: number;
            };
        };
        bannerTheme: TPromoBannerTheme;
        pricingHeroImage: {
            desktop: string;
            mobile: string;
        };
    };
}

export const MAP_OF_PROMO_CODES_CONFIG: IMapOfPromoCodesConfig = {
    [Coupons.join50]: {
        discount: {
            monthly: {
                discountPercentage: 50
            },
            annualMonthly: {
                discountPercentage: 50
            }
        },
        bannerTheme: 'light',
        pricingHeroImage: {
            desktop: 'marketing_site/pricing/growth-arrows-illustration_2x.png',
            mobile: 'marketing_site/pricing/growth-arrows-illustration_mweb.png'
        }
    },
    [Coupons.join30]: {
        discount: {
            monthly: {
                discountPercentage: 30
            },
            annualMonthly: {
                discountPercentage: 30
            }
        },
        bannerTheme: 'light',
        pricingHeroImage: {
            desktop: 'marketing_site/pricing/growth-arrows-illustration_2x.png',
            mobile: 'marketing_site/pricing/growth-arrows-illustration_mweb.png'
        }
    }
};

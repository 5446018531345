"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    invoice_template_hero_cta: {
        variants: {
            control: { ab_test_variation: 'control' },
            hero_cta: {
                ab_test_variation: 'hero_cta'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/free-invoice-templates']
    },
    lp_photographers: {
        variants: {
            control: { ab_test_variation: 'control' },
            webflow: {
                ab_test_variation: 'webflow'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/industry-photographers'],
        webflowPath: '/lp/photographers'
    },
    join30_vs_join50_promo: {
        variants: {
            control: { ab_test_variation: 'control' },
            join30: {
                ab_test_variation: 'join30'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};

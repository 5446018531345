import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { useIsBrowser } from '../hooks/useIsBrowser';
import { Trans, useTranslation } from 'react-i18next';
import { usePromoCode } from '../hooks/usePromoCode';

import Layout from '../components/Layout/Layout';
import FeatureHeroBlock from '../components/FeatureBlocks/FeatureHeroBlock/FeatureHeroBlock';
import AnimatedDollarBlock from '../components/FeatureBlocks/AnimatedDollarBlock/AnimatedDollarBlock';
import FeatureTextBlock from '../components/FeatureBlocks/FeatureTextBlock/FeatureTextBlock';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FeatureSuperchargeBlock from '../components/FeatureBlocks/FeatureSuperchargeBlock/FeatureSuperchargeBlock';
import ExploreFeaturesBlock from '../components/FeatureBlocks/ExploreFeaturesBlock/ExploreFeaturesBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import FeaturePreviewBlock from '../components/FeatureBlocks/FeaturePreviewBlock/FeaturePreviewBlock';
import MobileStickyCTA from '../components/MobileStickyCTA/MobileStickyCTA';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import OnlineInvoiceStore from '../stores/OnlineInvoiceStore.js';
import UtilsService from '../Services/UtilsService';
import { KEYWORD_INSERTION_STORE } from '../stores/keywordInsertionStore';

import '../styles/online-template.scss';

const ogTags = {
    url: 'https://www.honeybook.com/online-invoices',
    title: 'Custom Online Invoice for Small Business Owners',
    description:
        'Get paid faster with a professional online invoice. Use invoice templates that meet your business needs, including automatic payment reminders.'
};

const {
    stepBlocksOne,
    stepBlocksTwo,
    stepBlocksThree,
    integrationsIcons,
    previewContent,
    exploreLinksContent,
    signatureCardContents,
    templateData,
    quotes,
    customSuperchargeData
} = OnlineInvoiceStore;

const faqs = [
    {
        id: 'needOnlineInvoice',
        answer: `#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'whatNeedsToBeIncluded'
    },
    {
        id: 'sendInvoiceFromMobile',
        answer: `#TEXT# <a href="https://www.honeybook.com/">#LINK_TEXT#</a> #TEXT#`
    },
    {
        id: 'provideInvoiceTemplates',
        answer: `#TEXT# <a href="https://www.honeybook.com/free-invoice-templates">#LINK_TEXT</a> #TEXT#`
    },
    {
        id: 'makePdfInvoice',
        answer: `#TEXT# <a href="https://www.honeybook.com/invoice-template">#LINK_TEXT</a> #TEXT#`
    },
    {
        id: 'integrateQuickbooks'
    },
    {
        id: 'isInvoiceReceipt',
        answer: `#TEXT# <a href="https://www.honeybook.com/blog/invoice-payments">#LINK_TEXT</a> #TEXT#`
    },
    {
        id: 'clientPaymentExperience',
        answer: '#TEXT# <a href="https://www.honeybook.com/blog/invoicing">#LINK_TEXT#</a> #TEXT#'
    }
];

function OnlineInvoicesNew(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const { isBrowser } = useIsBrowser();
    const placement = UtilsService.getParameterByName('placement') || '';
    const titleKey = KEYWORD_INSERTION_STORE.onlineInvoices.titles[placement];
    const titleTransKey = titleKey
        ? `featureHeroBlock.keywordInsertionTitles.${titleKey}`
        : `featureHeroBlock.title`;

    const stats = useMemo(
        () => [
            {
                title: (
                    <Trans>{t('invoicesStatsAnimation.signed.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.signed.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('invoicesStatsAnimation.value.title')}</Trans>,
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.value.subtitle')}</Trans>
                )
            },
            {
                title: <Trans>{t('invoicesStatsAnimation.daily.title')}</Trans>,
                subtitle: (
                    <Trans>{t('invoicesStatsAnimation.daily.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    return (
        <Layout ogTags={ogTags} customClass={isBrowser ? '' : 'hidden'}>
            <div className="online-template online-template--invoices">
                <FeatureHeroBlock
                    label={t('featureHeroBlock.label')}
                    title={t(titleTransKey)}
                    subtitle={t('featureHeroBlock.subtitle')}
                    stats={stats}
                    iconUrl1="marketing_site/online-invoices/00_Hero/Desktop/Document.png"
                    iconUrl2="marketing_site/online-invoices/00_Hero/Desktop/Group_48096347.png"
                />
                <FeaturePreviewBlock
                    transId="contractToggle"
                    previewContent={previewContent}
                />
                <AnimatedDollarBlock cardContents={signatureCardContents} />
                <FeatureTextBlock
                    title={t('featureTextBlock.title')}
                    imageUrl="marketing_site/online-invoices/02_Steps/Desktop/Document.png"
                />
                <StepFeatureBlock
                    customClass="online-template__step1"
                    transId="stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    reverse
                    transId="stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t('stepsFeatureBlock.linkText')}
                />
                <FeatureTemplateGallery
                    customTemplateData={templateData}
                    ctaSource="Invoices template gallery footer"
                />
                <TestimonialCarouselCardBlock
                    testimonials={quotes}
                    autoScroll={false}
                />
                <FeatureSuperchargeBlock contentData={customSuperchargeData} />
                <FeatureIntegrationBlock
                    title={t('featureIntegrationBlock.title')}
                    subtitle={t('featureIntegrationBlock.subtitle')}
                    icons={integrationsIcons}
                />
                <FAQBlock title={t('onlineInvoicesFaqs.title')} faqs={faqs} />
                <ExploreFeaturesBlock
                    title={t('exploreFeaturesBlock.title')}
                    linksContent={exploreLinksContent}
                />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
                <MobileStickyCTA />
            </div>
        </Layout>
    );
}

export default OnlineInvoicesNew;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "translations", "onlineInvoices", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { ReactNode, useEffect, memo } from 'react';
import { CloudinaryContext } from 'cloudinary-react';
import UtilsService from '../../Services/UtilsService';
//  Layout.tsx should be imported first in every file that uses it (css ordering issues)
import '../../styles/_global.scss';
import './layout.scss';

import Footer from '../Footer/Footer';
import FooterSlim from '../../components/FooterSlim/FooterSlim';

import NavBar from '../NavBar/NavBar';

import OGTags from '../../Services/OGTagsService';

import PageLoadService from '../../Services/PageLoadService';

import NavBarTools from '../NavBar/NavBarTools';
import type { TOGTags } from '../../types/util-types';
import ABTestToolService from '../../Services/ABTestToolService';
import ABTestToolServiceV2 from '../../Services/ABTestToolService.v2';
import DiscountBanner from '../DiscountBanner/DiscountBanner';
import CloudflareBannerService from '../../Services/CloudflareBannerService';
import { ABTestFallbackVariants } from '../../ab-tests/ABTestFallbackVariants';

interface Props {
    ogTags: TOGTags;
    navBarTools?: boolean;
    lang?: 'en' | 'es'; // TODO: add more languages then required
    children?: ReactNode;
    slimNav?: boolean;
    noFooter?: boolean;
    slimFooter?: boolean;
    noPromo?: boolean;
    customClass?: string;
    noCta?: boolean;
}

function Layout({
    children,
    ogTags,
    navBarTools = false,
    lang,
    slimNav = false,
    noFooter = false,
    slimFooter = false,
    noPromo = false,
    customClass,
    noCta = false
}: Props): React.ReactElement {
    const handleAnalyticsAndPageLoad = () => {
        const toolService = UtilsService.shouldUseABTestToolV2()
            ? ABTestToolServiceV2
            : ABTestToolService;
        toolService?.fireAbTestAnalytics(window.location.pathname);
        PageLoadService.handlePageAnalytics();
    };

    useEffect(() => {
        UtilsService.reloadOTBanner();
        UtilsService.storeInitialUtms();
        UtilsService.storeCoupon();
        UtilsService.handleFullStory();
        handleAnalyticsAndPageLoad();
        CloudflareBannerService.hideAndTrackBanner();
    }, []);

    return (
        <div className={`${customClass ?? ''}`}>
            <ABTestFallbackVariants />
            <CloudinaryContext cloudName="honeybook" secure>
                <OGTags {...ogTags} lang={lang} />
                {!noPromo && <DiscountBanner />}
                {navBarTools ? (
                    <NavBarTools />
                ) : (
                    <NavBar slimNav={slimNav} noCta={noCta} />
                )}
                {children}
                {!noFooter && !slimFooter && <Footer />}
                {slimFooter && <FooterSlim />}
            </CloudinaryContext>
        </div>
    );
}

export default memo(Layout);

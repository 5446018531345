"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATHS_TO_EXCLUDE = exports.SHOULD_RUN_AB_TEST_TOOL_V2 = exports.HOME_PREFIX = exports.PATH_VARIANTS_SEPARATOR = exports.AB_TESTS_STORAGE_KEY = exports.FALLBACK_VARIANT_VALUE = exports.QA_URL = exports.STATSIG_ID_COOKIE = exports.TESTS_SEPARATOR = exports.TEST_PROPERTIES_SEPARATOR = exports.AB_TEST_COOKIE_KEY = exports.AB_TEST_COOKIE_KEY_STATSIG = void 0;
exports.AB_TEST_COOKIE_KEY_STATSIG = 'hb_marketing_tests';
exports.AB_TEST_COOKIE_KEY = 'segments';
exports.TEST_PROPERTIES_SEPARATOR = ':';
exports.TESTS_SEPARATOR = '&';
exports.STATSIG_ID_COOKIE = 'hb_statsig_stable_id';
exports.QA_URL = 'staging-qa-app.honeybook.com';
exports.FALLBACK_VARIANT_VALUE = 'code_default';
exports.AB_TESTS_STORAGE_KEY = 'exposed_marketing_tests';
exports.PATH_VARIANTS_SEPARATOR = '&';
exports.HOME_PREFIX = '/home';
exports.SHOULD_RUN_AB_TEST_TOOL_V2 = 'shouldRunABTestToolV2';
exports.PATHS_TO_EXCLUDE = [
    '/404',
    '/ala-facilitator',
    '/ala-facilitator-v2',
    '/activation',
    '/unsupported',
];

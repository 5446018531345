import { graphql } from 'gatsby';
import { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import StickySidebar from '../components/StickySidebar/StickySidebar';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import UtilsService from '../Services/UtilsService';

import '../styles/faqs-page.scss';

const ogTags = {
    url: 'https://www.honeybook.com/faqs',
    title: 'Frequently Asked Questions',
    description:
        "Learn more about HoneyBook's powerful features for small businesses. Includes invoices, proposals, contracts, online payments and more. Free 7 day trial."
};

const faqs = [
    {
        id: 'general',
        menuIcon: 'marketing_site/navbar_icons/hb-24.svg',
        faqContent: [
            {
                id: 'whatIs',
                answer: ''
            },
            {
                id: 'getStarted',
                answer: ''
            },
            {
                id: 'support',
                answer: ''
            },
            {
                id: 'whoUses',
                answer: ''
            },
            {
                id: 'features',
                answer: `#TEXT# <a href="/features">#LINK_TEXT#</a> #TEXT#`
            },
            {
                id: 'importTemplates',
                answer: `#TEXT# <a target="_blank" rel="noopener noreferrer" href="https://help.honeybook.com/en/articles/2902659-honeybook-s-free-account-setup">#LINK_TEXT#</a> #TEXT#`
            },
            {
                id: 'crm',
                answer: `#TEXT# <a href="https://help.honeybook.com/integrations/quickbooks-integration/quickbooks-integration-overview-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/calendly-integration-faq">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/calendly-and-zapier-integrations/integrating-with-zapier">#LINK_TEXT#</a> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/syncing-with-gmail">#LINK_TEXT#</a> #TEXT# <span>##AND-placeholder##</span> #TEXT# <a href="https://help.honeybook.com/integrations/gmail-and-google-integration/understanding-your-google-calendar-integration">#LINK_TEXT#</a>`
            }
        ]
    },
    {
        id: 'proposals',
        menuIcon: 'marketing_site/navbar_icons/proposal-24.svg',
        faqContent: [
            {
                id: 'whatsProposal',
                answer: '#TEXT# <a href="/online-payment-software">#TEXT</a> #TEXT#'
            },
            {
                id: 'whyProposal',
                answer: '#TEXT# <a target="_blank" rel="noopener noreferrer" href="//intercom.help/honeybook-5891e2a409ff/en/articles/2537722-video-tutorial-creating-and-sending-a-proposal">#TEXT_LINK#</a>.'
            },
            {
                id: 'proposalTemplates',
                answer: '#TEXT# <a target="_blank" rel="noopener noreferrer" href="//help.honeybook.com/en/articles/2209099-using-templates">#LINK_TEXT#</a>'
            },
            {
                id: 'proposalVsOthers',
                answer: '#TEXT# <a href="/online-invoices">#LINK_TEXT#</a>#TEXT#="_blank" rel="noopener noreferrer" href="//honeybook.com/online-contract">#LINK_TEXT#</=>#TEXT'
            },
            {
                id: 'testProposals'
            },
            {
                id: 'otherFeatures',
                answer: '#TEXT# <a href="/features">#TEXT</a> #TEXT#'
            },
            {
                id: 'HBCost',
                answer: '#TEXT <a href="/pricing">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    {
        id: 'invoices',
        menuIcon: 'marketing_site/navbar_icons/invoice-24.svg',
        faqContent: [
            {
                id: 'needOnlineInvoice',
                answer: `#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT#`
            },
            {
                id: 'sendInvoiceFromMobile',
                answer: `#TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US" target="_blank" rel="nofollow noopener">#LINK_TEXT#</a> #TEXT# <a href="https://apps.apple.com/us/app/honeybook/id${process.env.GATSBY_honeybookAppId}" target="_blank" rel="nofollow noopener">#LINK_TEXT#</a>`
            },
            {
                id: 'provideInvoiceTemplates'
            },
            {
                id: 'integrateQuickbooks'
            },
            {
                id: 'clientPaymentExperience',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3329213-growing-your-business-lesson-2-5-your-clients-experience-with-files#client-side-experience-with-invoices-contracts--proposals">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    {
        id: 'onlinePayments',
        menuIcon: 'marketing_site/navbar_icons/dollar-circle-24.svg',
        faqContent: [
            {
                id: 'requiredPayment'
            },
            {
                id: 'processingFees',
                answer: '#TEXT# <a href="//intercom.help/honeybook-5891e2a409ff/payments-and-bank-info/getting-started-with-payments/getting-paid-through-honeybook#transaction-fees">#TEXT_LINK#</a>.'
            },
            {
                id: 'clientPayments',
                answer: '#TEXT# <a href="//help.honeybook.com/video-tutorials/getting-started-and-account-set-up/video-tutorial-using-honeybooks-bookkeeping-tool">#LINK_TEXT#</a>.'
            },
            {
                id: 'paymentProcessor'
            },
            {
                id: 'passTransactionFee',
                answer: '#TEXT <a href="//www.thebalance.com/can-businesses-charge-a-credit-card-convenience-fee-4155333">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'paymentDispute',
                answer: '#TEXT# <i>italic</i> #TEXT#'
            },
            {
                id: 'clientPaymentExperience',
                answer: '#TEXT <a href="/proposal-software">#LINK_TEXT#</a> #TEXT# <a href="//intercom.help/honeybook-5891e2a409ff/using-honeybook-files/client-side-file-experience/what-your-client-sees-when-they-pay">#LINK_TEXT#</a>.'
            },
            {
                id: 'fraudProtection'
            },
            {
                id: 'inPersonPayment'
            },
            {
                id: 'integrateQuickbooks',
                answer: '#TEXT# <a href="//quickbooks.intuit.com/pricing/">#LINK_TEXT#</a>.<div style="margin-top:10px; text-decoration:underline;">#TEXT#</div><div>#TEXT#</div><div>#TEXT#</div>'
            },
            {
                id: 'howInstantDeposit',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3570117">#LINK_TEXT#</a>'
            }
        ]
    },
    {
        id: 'scheduling',
        menuIcon: 'marketing_site/navbar_icons/calendar-clock-24.svg',
        faqContent: [
            {
                id: 'schedulerWhatIs',
                answer: '#TEXT# <a href="https://www.honeybook.com/" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
            },
            {
                id: 'schedulingSetup',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3614050" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
            },
            {
                id: 'schedulingSessionTypes',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3444494-understanding-managing-your-honeybook-calendar" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'schedulinglinShare',
                answer: '#TEXT# <a href="https://www.honeybook.com/app/templates" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT# <a href="https://www.honeybook.com/app/scheduling" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
            },
            {
                id: 'schedulingExternalCalendar',
                answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209101-syncing-your-google-calendar-to-honeybook" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'schedulingCustomize'
            },
            {
                id: 'schedulingCompare'
            }
        ]
    },
    {
        id: 'automations',
        menuIcon: 'marketing_site/navbar_icons/automation-24.svg',
        faqContent: [
            {
                id: 'workflowNeed'
            },
            {
                id: 'workflowLeads'
            },
            {
                id: 'workflowBusiness'
            },
            {
                id: 'workflowApproval'
            },
            {
                id: 'workflowActions'
            }
        ]
    },
    {
        id: 'contracts',
        menuIcon: 'marketing_site/navbar_icons/contract-24.svg',
        faqContent: [
            {
                id: 'contractCreate',
                answer: '#TEXT# <a href="/risingtide/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'contractGoodBusiness'
            },
            {
                id: 'contractMobile',
                answer: `#TEXT# <a href="https://play.google.com/store/apps/details?id=com.honeybook.alfred&hl=en_US">#LINK_TEXT#</a> #TEXT# <a href="https://apps.apple.com/us/app/honeybook/id${process.env.GATSBY_honeybookAppId}">#LINK_TEXT#</a> #TEXT#`
            },
            {
                id: 'contractSign'
            },
            {
                id: 'contractTemplates'
            }
        ]
    },
    {
        id: 'pricing',
        menuIcon: 'marketing_site/navbar_icons/dollar-list-24.svg',
        faqContent: [
            {
                id: 'transactionFees'
            },
            {
                id: 'plans'
            },
            {
                id: 'switchPlans'
            },
            {
                id: 'freeTrial'
            },
            {
                id: 'starterPlanLimit'
            },
            {
                id: 'plansIncluded'
            },
            {
                id: 'discountCode'
            },
            {
                id: 'moneyBack',
                answer: '#TEXT# <a href="mailto:concierge@honeybook.com">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'howLongToGetStarted'
            },
            {
                id: 'lingeringQuestions',
                answer: '#TEXT# <a href="mailto:sales@honeybook.com">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    }
];

const sectionIds = faqs.map(faq => faq.id);

const handleStickySidebarClick = sectionId => {
    AnalyticsService.track(AnalyticsEvents.lp_features_hub_feature_selected, {
        source: sectionId
    });
    UtilsService.scrollToElementId(sectionId, 55);
};

function FAQTemplate() {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [sectionInView, setSectionInView] = useState(faqs[0].id);

    const sidebarRef = createRef();
    const faqsContainerRef = createRef();

    const trackVisibleSectionsOnScroll = pageElementIds => {
        const scrollPosition = window.pageYOffset;

        pageElementIds.forEach(sectionId => {
            const element = document.getElementById(sectionId);
            const elementTop =
                scrollPosition + element.getBoundingClientRect().top;
            const elementHeight = element.offsetHeight || 0;
            const elementBottom = elementTop + elementHeight;
            const topNavHeight =
                document.getElementsByClassName('navbar')[0].offsetHeight || 0;

            if (
                elementTop <= scrollPosition + topNavHeight + 200 &&
                scrollPosition + topNavHeight + 400 <= elementBottom
            ) {
                setSectionInView(sectionId);
            }
        });
    };

    useEffect(() => {
        // Only if sidebar is displayed (desktop only)
        if (sidebarRef.current) {
            trackVisibleSectionsOnScroll(sectionIds);

            window.addEventListener(
                'scroll',
                UtilsService.throttle(() => {
                    trackVisibleSectionsOnScroll(sectionIds);
                }, 100)
            );
        }
    });

    return (
        <div className="faqs-page">
            <Layout ogTags={ogTags}>
                <div className="faqs-page-body">
                    <div
                        className="faqs-page-body__features-container"
                        ref={faqsContainerRef}
                    >
                        {breakpoints.mediumUp && (
                            <aside
                                className="faqs-page-body__sticky-sidebar"
                                ref={sidebarRef}
                            >
                                <StickySidebar
                                    menuItems={faqs}
                                    onClick={handleStickySidebarClick}
                                    highlightMenuItem={sectionInView}
                                    transKey="faqsPage"
                                />
                            </aside>
                        )}

                        <div className="faqs-page-body__features-list">
                            <h1 className="faqs-page-body__title">
                                Frequently Asked Questions
                            </h1>
                            {faqs.map(faq => (
                                <div id={faq.id} key={faq.id}>
                                    <FAQBlock
                                        title={t(`faqsPage.${faq.id}.title`)}
                                        faqs={faq.faqContent}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default FAQTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import { ReactNode, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { useWindowSize } from '../../hooks/useWindowSize';
import HBIcon from '../honeybook-ui/HBIcon/HBIcon';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';

import './tooltip.scss';

interface Props {
    customClass?: string;
    children: ReactNode;
    tooltipText: string;
    tooltipId: string;
    selectedTooltipId: string;
    setSelectedTooltipId: (id: string) => void;
}

const Tooltip = ({
    customClass = '',
    children,
    tooltipText,
    tooltipId,
    selectedTooltipId,
    setSelectedTooltipId
}: Props) => {
    const { isMediumUp } = useWindowSize();

    const targetRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);

    const { styles, attributes, forceUpdate } = usePopper(
        targetRef.current,
        tooltipRef.current,
        {
            placement: isMediumUp ? 'right' : 'top-start',

            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 16]
                    }
                },
                {
                    name: 'arrow',
                    options: {
                        element: arrowRef.current
                    }
                }
            ]
        }
    );

    useEffect(() => {
        forceUpdate?.();
    }, [selectedTooltipId]);

    const isShown = useMemo(() => {
        return selectedTooltipId === tooltipId;
    }, [selectedTooltipId]);

    const tooltipClassName = useMemo(() => {
        return classNames('tooltip-container__tooltip', {
            'tooltip-container__tooltip--shown': isShown,
            'tooltip-container__tooltip--mweb': !isMediumUp
        });
    }, [isShown, isMediumUp]);

    const showTooltip = () => {
        AnalyticsService.trackClick(AnalyticsEvents.tooltip_shown, {
            source: tooltipId,
            device: isMediumUp ? 'desktop' : 'mobile'
        });
        setSelectedTooltipId(tooltipId);
    };

    const hideTooltip = () => {
        setSelectedTooltipId('');
    };

    return (
        <div className={`tooltip-container ${customClass} `}>
            <div
                className="tooltip-container__target"
                ref={targetRef}
                onMouseEnter={isMediumUp ? showTooltip : () => {}}
                onMouseLeave={isMediumUp ? hideTooltip : () => {}}
                onTouchStart={showTooltip}
            >
                {children}
            </div>
            <div
                className={tooltipClassName}
                role="tooltip"
                ref={tooltipRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {!isMediumUp && (
                    <HBIcon
                        name="nx-close"
                        customClass="tooltip-container__close-btn"
                        onClick={hideTooltip}
                    />
                )}
                {tooltipText}
                <div
                    className="tooltip-container__arrow"
                    data-popper-arrow
                    ref={arrowRef}
                ></div>
            </div>
        </div>
    );
};

export default Tooltip;

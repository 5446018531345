import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../components/FeatureBlockElements';

import '../styles/sitemap.scss';

const sitemapPages = [
    {
        categoryTitleID: 'product',
        pages: [
            {
                id: 'features',
                link: '/features'
            },
            {
                id: 'contractTemplates',
                link: '/online-contract',
                subLink: true
            },
            {
                id: 'proposals',
                link: '/proposal-software',
                subLink: true
            },
            {
                id: 'onlinePayments',
                link: '/online-payment-software',
                subLink: true
            },
            {
                id: 'onlineSignatureGenerator',
                link: '/online-signature-generator',
                subLink: true
            },
            {
                id: 'automation',
                link: '/automations',
                subLink: true
            },
            {
                id: 'allInOne',
                link: '/all-in-one-business-platform',
                subLink: true
            },
            {
                id: 'meetingScheduler',
                link: '/meeting-scheduler',
                subLink: true
            },
            {
                id: 'invoices',
                link: '/online-invoices',
                subLink: true
            },
            {
                id: 'clientPortal',
                link: '/client-portal-software',
                subLink: true
            },
            {
                id: 'paymentReminders',
                link: '/payment-reminders',
                subLink: true
            },
            {
                id: 'accountMigration',
                link: '/free-account-migration'
            },
            {
                id: 'reviews',
                link: '/reviews'
            },
            {
                id: 'pricing',
                link: '/pricing'
            },
            {
                id: 'canada',
                link: '/canada'
            }
        ]
    },
    {
        categoryTitleID: 'resources',
        pages: [
            {
                id: 'resources',
                link: '/resources'
            },
            {
                id: 'productEducation',
                link: '/product-education'
            },
            {
                id: 'helpCenter',
                link: 'https://help.honeybook.com/'
            },
            {
                id: 'vsDubsado',
                link: '/honeybook-vs-dubsado'
            },
            {
                id: 'vsFreshbooks',
                link: '/honeybook-vs-freshbooks'
            },
            {
                id: 'vs17hats',
                link: '/honeybook-vs-17hats'
            },
            {
                id: 'vsMonday',
                link: '/honeybook-vs-monday'
            },
            {
                id: 'vsBonsai',
                link: '/honeybook-vs-bonsai'
            },
            {
                id: 'vsVcita',
                link: '/honeybook-vs-vcita'
            },
            {
                id: 'vsPipedrive',
                link: '/honeybook-vs-pipedrive'
            }
        ]
    },
    {
        categoryTitleID: 'community',
        pages: [
            {
                id: 'corona',
                link: '/blog/coronavirus-small-businesses'
            },
            {
                id: 'referral',
                link: '/referrals'
            },
            {
                id: 'risingTide',
                link: '/blog'
            },
            {
                id: 'educators',
                link: '/educators'
            }
        ]
    },
    {
        categoryTitleID: 'honeybook',
        pages: [
            {
                id: 'about',
                link: '/about'
            },
            {
                id: 'story',
                link: '/about',
                subLink: true
            },
            {
                id: 'careers',
                link: '/careers',
                subLink: true
            },
            {
                id: 'contact',
                link: '/contact-us'
            },
            {
                id: 'press',
                link: '/press'
            },
            {
                id: 'news',
                link: '//news.honeybook.com'
            },
            {
                id: 'tos',
                link: '/terms/terms-of-service'
            },
            {
                id: 'privacy',
                link: '/terms/privacy',
                subLink: true
            },
            {
                id: 'affiliate',
                link: '/lp/referrals-tos'
            },
            {
                id: 'fees',
                link: '/lp/premium-program',
                subLink: true
            }
        ]
    },
    {
        categoryTitleID: 'tools',
        pages: [
            {
                id: 'emailSignature',
                link: '/email-signature'
            },
            {
                id: 'invoiceGenerator',
                link: '/invoice-template'
            },
            {
                id: 'taxCalculator',
                link: '/tools/self-employment-tax-calculator'
            },
            {
                id: 'taxCalculatorCalifornia',
                link: '/tools/self-employment-tax-calculator-california'
            },
            {
                id: 'taxCalculatorTexas',
                link: '/tools/self-employment-tax-calculator-texas'
            },
            {
                id: 'taxCalculatorFlorida',
                link: '/tools/self-employment-tax-calculator-florida'
            },
            {
                id: 'taxCalculatorNewYork',
                link: '/tools/self-employment-tax-calculator-new-york'
            },
            {
                id: 'taxCalculatorPennsylvania',
                link: '/tools/self-employment-tax-calculator-pennsylvania'
            },
            {
                id: 'taxCalculatorIllinois',
                link: '/tools/self-employment-tax-calculator-illinois'
            },
            {
                id: 'taxCalculatorOhio',
                link: '/tools/self-employment-tax-calculator-ohio'
            },
            {
                id: 'taxCalculatorGeorgia',
                link: '/tools/self-employment-tax-calculator-georgia'
            },
            {
                id: 'taxCalculatorNorthCarolina',
                link: '/tools/self-employment-tax-calculator-north-carolina'
            },
            {
                id: 'taxCalculatorMichigan',
                link: '/tools/self-employment-tax-calculator-michigan'
            },
            {
                id: 'missionStatement',
                link: '/mission-statement'
            },
            {
                id: 'businessPersonalityTest',
                link: '/business-personality-test'
            }
        ]
    },
    {
        categoryTitleID: 'customers',
        pages: [
            {
                id: 'dj',
                link: '/dj-booking-software'
            },
            {
                id: 'venue',
                link: '/venue-management-software'
            },
            {
                id: 'florist',
                link: '/software-for-florists'
            },
            {
                id: 'consultant',
                link: '/crm-for-consultants'
            },
            {
                id: 'freelancers',
                link: '/crm-for-freelancers'
            },
            {
                id: 'photographers',
                link: '/crm-for-photographers'
            },
            {
                id: 'healthCoach',
                link: '/health-coach-software'
            },
            {
                id: 'caterer',
                link: '/catering-management-software'
            },
            {
                id: 'doula',
                link: '/doula-business-software'
            },
            {
                id: 'eventPlanner',
                link: '/software-for-event-planners'
            },
            {
                id: 'marketingPR',
                link: '/marketing-consultant-software'
            },
            {
                id: 'designers',
                link: '/crm-for-designers'
            },
            {
                id: 'graphicDesign',
                link: '/crm-for-graphic-designers'
            },
            {
                id: 'lawn',
                link: '/crm-for-landscapers'
            },
            {
                id: 'contractor',
                link: '/contractor-billing-software'
            },
            {
                id: 'education',
                link: '/tutor-software'
            },
            {
                id: 'virtualAssistant',
                link: '/virtual-assistant-software'
            },
            {
                id: 'realEstate',
                link: '/real-estate-crm-software'
            },
            {
                id: 'socialMedia',
                link: '/social-media-crm'
            },
            {
                id: 'accounting',
                link: '/software-for-home-accountants'
            },
            {
                id: 'interiorDesign',
                link: '/interior-design-business-software'
            },
            {
                id: 'digitalMarketing',
                link: '/tools-for-digital-marketers'
            },
            {
                id: 'copywriting',
                link: '/software-for-copywriters'
            },
            {
                id: 'lifeCoach',
                link: '/life-coach-software'
            },
            {
                id: 'cleaning',
                link: '/software-for-cleaning-business'
            },
            {
                id: 'onlineCoaching',
                link: '/software-for-online-coaching'
            },
            {
                id: 'dogTrainers',
                link: '/software-for-dog-trainers'
            },
            {
                id: 'visualArtists',
                link: '/software-for-visual-artists'
            },
            {
                id: 'PRCompanies',
                link: '/software-for-pr-companies'
            },
            {
                id: 'bandManagers',
                link: '/software-for-band-managers'
            },
            {
                id: 'webDesigners',
                link: '/software-for-web-designers'
            },
            {
                id: 'agencies',
                link: '/software-for-agencies'
            },
            {
                id: 'franchises',
                link: '/software-for-franchises'
            }
        ]
    },
    {
        categoryTitleID: 'invoiceTemplates',
        pages: [
            {
                id: 'invoiceHub',
                link: '/free-invoice-templates/'
            },
            {
                id: 'doulaInvoices',
                link: '/free-invoice-templates/doula'
            },
            {
                id: 'designInvoices',
                link: '/free-invoice-templates/designers'
            },
            {
                id: 'graphicDesignInvoices',
                link: '/free-invoice-templates/graphic-design'
            },
            {
                id: 'interiorDesignInvoices',
                link: '/free-invoice-templates/interior-design'
            },
            {
                id: 'consultantInvoices',
                link: '/free-invoice-templates/consultants'
            },
            {
                id: 'coachInvoices',
                link: '/free-invoice-templates/coach'
            },
            {
                id: 'photographerInvoices',
                link: '/free-invoice-templates/photographers'
            },
            {
                id: 'tutorInvoices',
                link: '/free-invoice-templates/tutors'
            },
            {
                id: 'cleanerInvoices',
                link: '/free-invoice-templates/cleaners'
            },
            {
                id: 'virtualAssistantInvoices',
                link: '/free-invoice-templates/virtual-assistant'
            }
        ]
    },
    {
        categoryTitleID: 'contractTemplates',
        pages: [
            {
                id: 'contractsHub',
                link: '/free-contract-templates'
            },
            {
                id: 'photoContracts',
                link: '/photography-contract-template'
            },
            {
                id: 'consultantContracts',
                link: '/freelance-consulting-contract-template'
            },
            {
                id: 'designerContracts',
                link: '/designer-contract-template'
            }
        ]
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/sitemap',
    title: 'Sitemap'
};

function SitemapTemplate() {
    const { t } = useTranslation();

    return (
        <div className="sitemap">
            <Layout ogTags={ogTags}>
                <FeatureBlockSection>
                    <FeatureBlockContent>
                        <h1 className="feature-block__text-title">
                            {t('sitemapPage.title')}
                        </h1>
                        <div className="sitemap__content-wrapper">
                            {sitemapPages.map(links => (
                                <ul
                                    className="sitemap__list"
                                    key={links.categoryTitleID}
                                >
                                    <li className="sitemap__list-item sitemap__list-item--header">
                                        {t(
                                            `sitemapPage.categoryTitles.${links.categoryTitleID}`
                                        )}
                                    </li>
                                    {links.pages.map(link => (
                                        <li
                                            className={`sitemap__list-item ${
                                                link.subLink
                                                    ? 'sitemap__list-item--sub'
                                                    : ''
                                            }`}
                                            key={link.id}
                                        >
                                            <a
                                                href={link.link}
                                                className="sitemap__link"
                                            >
                                                {t(
                                                    `sitemapPage.links.${link.id}`
                                                )}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                    </FeatureBlockContent>
                </FeatureBlockSection>
            </Layout>
        </div>
    );
}

export default SitemapTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "sitemap"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

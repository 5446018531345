import { LegacyRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {
    FeatureBlockContent,
    FeatureBlockSection
} from '../../FeatureBlockElements';
import Card from '../../HBCard/Card';
import HBMediaElement from '../../honeybook-ui/HBMediaElement/HBMediaElement';
import HBText from '../../honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import UtilsService from '../../../Services/UtilsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import './animated-dollar-block.scss';
import { INITIAL_DOLLAR_AMOUNT } from '../../../constants/constants';

interface Card {
    iconUrl: string;
    transId: string;
}
interface Props {
    cardContents: Card[];
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function AnimatedDollarBlock({ cardContents }: Props): JSX.Element {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const sectionRef: LegacyRef<any> = useRef();
    const titleRef: LegacyRef<any> = useRef();
    const [dollarAmount, setDollarAmount] = useState(INITIAL_DOLLAR_AMOUNT);

    const [viewRef, inView] = useInView({
        threshold: breakpoints.mediumUp ? 0.6 : 0.2,
        triggerOnce: true
    });

    const scrollHandler = () => {
        const additionalIncrementSpeed = 11;
        setDollarAmount(
            dollarAmount => Math.round(dollarAmount) + additionalIncrementSpeed
        );
        if (inView) {
            const sectionBoundingBox =
                sectionRef.current?.getBoundingClientRect();
            if (sectionBoundingBox) {
                const sectionYPos = sectionBoundingBox.top / window.innerHeight;
                const propertyCalc = 120 - (100 * sectionYPos) / 0.75 + '%';

                if (sectionYPos < 0 || sectionYPos > 1) return;

                titleRef.current.style.setProperty(
                    '--marker-width',
                    propertyCalc
                );
            }
        }
    };

    useEffect(() => {
        window.addEventListener(
            'scroll',
            UtilsService.throttle(() => {
                scrollHandler();
            }, 50)
        );
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [inView]);

    return (
        <div ref={viewRef}>
            <div ref={sectionRef}>
                <FeatureBlockSection customClass="animated-dollar-block">
                    <FeatureBlockContent customClass="animated-dollar-block__content">
                        <div
                            className={`animated-dollar-block__title-wrapper ${
                                inView
                                    ? 'animated-dollar-block__title-wrapper--show'
                                    : ''
                            }`}
                            ref={titleRef}
                        >
                            <HBText
                                customClass="animated-dollar-block__title"
                                tag="h2"
                                type="title-l"
                            >
                                {t('AnimatedDollarBlock.title')}
                            </HBText>
                        </div>
                        <div
                            className={`animated-dollar-block__cards-container ${
                                inView
                                    ? 'animated-dollar-block__cards-container--show'
                                    : ''
                            }`}
                        >
                            {cardContents.map(cardContent => (
                                <Card
                                    customClass="animated-dollar-block__card"
                                    key={cardContent.transId}
                                >
                                    <HBMediaElement
                                        name={cardContent.iconUrl}
                                    />
                                    <HBText
                                        tag="h3"
                                        type="title-m"
                                        customClass="animated-dollar-block__card__title"
                                    >
                                        {t(
                                            `AnimatedDollarBlock.cards.${cardContent.transId}.title`
                                        )}
                                    </HBText>
                                    <HBText
                                        tag="p"
                                        type="body-m"
                                        customClass="animated-dollar-block__card__description"
                                    >
                                        {t(
                                            `AnimatedDollarBlock.cards.${cardContent.transId}.description`
                                        )}
                                    </HBText>
                                </Card>
                            ))}
                        </div>

                        <div
                            className={`animated-dollar-block__dollar-amount ${
                                inView
                                    ? 'animated-dollar-block__dollar-amount--show'
                                    : ''
                            }`}
                        >
                            <span
                                className={`animated-dollar-block__dollar-sign ${
                                    inView
                                        ? 'animated-dollar-block__dollar-sign--animate'
                                        : ''
                                }`}
                            >
                                ${numberWithCommas(dollarAmount)}
                            </span>
                        </div>

                        <RegistrationForm
                            customClass="animated-dollar-block__button"
                            source="contract signature block"
                            buttonStyle="primary--dark"
                            size="large"
                            showNoCcRequired
                        />
                    </FeatureBlockContent>
                </FeatureBlockSection>
            </div>
        </div>
    );
}

export default AnimatedDollarBlock;

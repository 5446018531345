const FeatureGridBlockStore = {
    featureGrid: [
        {
            transId: 'featureGrid.allInOne',
            imagePath: 'marketing_site/pricing/icons/ALL-IN-ONE-hover_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/features'
        },
        {
            transId: 'featureGrid.tracking',
            imagePath: 'marketing_site/pricing/icons/track-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/all-in-one-business-platform'
        },
        {
            transId: 'featureGrid.invoices',
            imagePath: 'system_web/templates/invoice-icon.svg',
            mediaFlags: {
                width: 35,
                height: 35,
                forceImgTag: true
            },
            url: '/invoice'
        },
        {
            transId: 'featureGrid.proposals',
            imagePath: 'marketing_site/pricing/icons/proposal-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/proposal-software'
        },
        {
            transId: 'featureGrid.contracts',
            imagePath: 'marketing_site/pricing/icons/illu-agreement_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/online-contract'
        },
        {
            transId: 'featureGrid.scheduling',
            imagePath: 'marketing_site/pricing/icons/icon-calendar_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/meeting-scheduler'
        },
        {
            transId: 'featureGrid.onlinePayments',
            imagePath:
                'marketing_site/pricing/icons/online-payments-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/online-payment-software'
        },
        {
            transId: 'featureGrid.workflows',
            imagePath: 'marketing_site/pricing/icons/auto-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/automations'
        },
        {
            transId: 'featureGrid.accountMigration',
            imagePath: 'marketing_site/pricing/icons/icon-home-projects_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/free-account-migration'
        },
        {
            transId: 'featureGrid.clientPortal',
            imagePath: 'marketing_site/pricing/icons/proposal-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/client-portal-software'
        },
        {
            transId: 'featureGrid.paymentReminders',
            imagePath:
                'marketing_site/pricing/icons/online-payments-icon_2x.png',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: '/payment-reminders'
        },
        {
            transId: 'featureGrid.mobile',
            imagePath: 'marketing_site/pricing/icons/mobile.svg',
            mediaFlags: {
                width: 40,
                height: 40
            },
            url: 'https://honeybook.onelink.me/850908038/kgmrfodc'
        }
    ]
};

export default FeatureGridBlockStore;

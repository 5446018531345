import RegistrationForm from '../RegistrationForm/RegistrationForm';
import HBIcon from '@honeybook-ui/HBIcon/HBIcon';

import './registration-form-as-text.scss';

interface Props {
    linkText: string;
    source: string;
    templateId?: string;
}

const RegistrationFormAsText = ({ linkText, source, templateId }: Props) => (
    <div className="registration-form-as-text">
        <RegistrationForm
            buttonText={linkText}
            source={source}
            showNoCcRequired={false}
            templateId={templateId}
        />
        <HBIcon name="nx-arrow-right-16" />
    </div>
);

export default RegistrationFormAsText;

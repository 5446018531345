import { Component, memo } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import HBButton, { TButtonStyle } from '../honeybook-ui/HBButton/HBButton';
import HBSSOButton from '../honeybook-ui/HBSSOButton/HBSSOButton';
import GeolocationService, { GeoData } from '../../Services/GeolocationService';
import RegistrationService from '../../Services/RegistrationService';
import NonUsaWarningModal from '../Modals/NonUsaWarningModal/NonUsaWarningModal';
import InternationalAvailabilityModal from '../Modals/InternationalAvailabilityModal/InternationalAvailabilityModal';
import InternationalAcceptModal from '../Modals/InternationalAcceptModal/InternationalAcceptModal';
import './registration-form.scss';
import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';
import StorageService from '../../Services/StorageService';
import { CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY } from '../../constants/constants';
import ABTestToolServiceV2 from '../../Services/ABTestToolService.v2';

interface Props {
    showSSO?: boolean;
    showNoCcRequired?: boolean;
    direction?: 'row' | 'column';
    size?: 'x-small' | 'small' | 'medium' | 'large';
    customClass?: string;
    source: string;
    templateId?: string;
    buttonText?: string;
    dataForIntakeFormTest?: Record<string, any>;
    buttonStyle?: TButtonStyle;
    couponCode?: string;
    testId?: string;
}

interface State {
    isOpenNonUsaModal: boolean;
    isOpenInternationalAvailabilityModal: boolean;
    isOpenInternationalAcceptModal: boolean;
    internationalUserEmail: string;
    internationalUserCountry: string;
    isValidGeolocation: boolean;
    isLoading: boolean;
    isFacebookVariation: boolean;
}

export const analyticsEventsForFacebookSSO = {
    signup_with_facebook: AnalyticsEvents.signup_with_facebook,
    already_signup_with_facebook: AnalyticsEvents.already_signup_with_facebook,
    signup_with_facebook_successful:
        AnalyticsEvents.signup_with_facebook_successful,
    signup_with_facebook_error: AnalyticsEvents.signup_with_facebook_error
};

export const analyticsEventsForGoogleSSO = {
    signup_with_google: AnalyticsEvents.signup_with_google,
    signup_with_google_loading_popup:
        AnalyticsEvents.signup_with_google_loading_popup,
    signup_with_google_user_approval:
        AnalyticsEvents.signup_with_google_user_approval,
    already_signup_with_google: AnalyticsEvents.already_signup_with_google,
    signup_with_google_successful:
        AnalyticsEvents.signup_with_google_successful,
    signup_with_google_error: AnalyticsEvents.signup_with_google_error,
    signup_with_google_user_closed_popup:
        AnalyticsEvents.signup_with_google_user_closed_popup,
    signup_with_google_user_closed_email_chooser:
        AnalyticsEvents.signup_with_google_user_closed_email_chooser
};

class RegistrationForm extends Component<Props & WithTranslation, State> {
    geoLocationData: GeoData;

    isOpenNonUsaModal: boolean;

    isOpenInternationalAvailabilityModal: boolean;

    isOpenInternationalAcceptModal: boolean;

    internationalUserEmail: string;

    internationalUserCountry: string;

    isValidGeolocation: boolean;

    isAlive = false;
    buttonText = '';

    static defaultProps = {
        size: 'medium',
        direction: 'column'
    };

    constructor(props: Props & WithTranslation) {
        super(props);
        this.isAlive = true;
        this.state = {
            isOpenNonUsaModal: false,
            isOpenInternationalAvailabilityModal: false,
            isOpenInternationalAcceptModal: false,
            internationalUserEmail: '',
            internationalUserCountry: '',
            isValidGeolocation: true,
            isLoading: false,
            isFacebookVariation: false
        };

        this.buttonText =
            this.props.buttonText ||
            this.props.t('registrationForm.startFreeTrial');

        GeolocationService.isFromValidCountriesPromise()
            .then(async (isValid: boolean): Promise<void> => {
                if (this.isAlive) {
                    this.setIsValidGeolocation(isValid);
                }
                return;
            })
            .catch((error: Error) => {
                console.error(error);

                // TODO: handle error or rethrow?
            });

        GeolocationService.getLocationPromise()
            .then(async (data: GeoData): Promise<void> => {
                this.geoLocationData = data;

                return;
            })
            .catch((error: Error) => {
                console.error(error);

                // TODO: handle error or rethrow?
            });
    }

    componentWillUnmount() {
        this.isAlive = false;
    }

    setIsOpenNonUsaModal = (isShow: boolean): void => {
        this.setState({ isOpenNonUsaModal: isShow });
    };

    setIsOpenInternationalAvailabilityModal = (isShow: boolean): void => {
        this.setState(() => ({ isOpenInternationalAvailabilityModal: isShow }));
    };
    setIsOpenInternationalAcceptModal = (isShow: boolean): void => {
        this.setState({ isOpenInternationalAcceptModal: isShow });
    };

    setIsValidGeolocation = (isValid: boolean): void => {
        this.setState({ isValidGeolocation: isValid });
    };

    setInvoiceId = (invoiceID: string): void => {
        StorageService.setItem({
            key: 'HB_FLOW_TEMPLATES',
            data: { id: [invoiceID] }
        });
    };

    onSecondaryActionNonUsaModal = () => {
        this.setIsOpenNonUsaModal(false);
        this.setIsOpenInternationalAvailabilityModal(true);
    };

    onConfirmNonUsaModal = () => {
        this.completeRegistrationForm();
        this.setIsOpenNonUsaModal(false);
    };

    onConfirmInternationalAvailabilityModal = (userEmail, userCountry) => {
        const eventProps = {
            email: userEmail,
            country: userCountry
        };

        AnalyticsService.reportIterableEvent(
            'international_user_capture',
            eventProps,
            eventProps
        );

        this.setState({
            internationalUserEmail: userEmail,
            internationalUserCountry: userCountry
        });

        this.setIsOpenInternationalAvailabilityModal(false);
        this.setIsOpenInternationalAcceptModal(true);
    };
    onCloseInternationalAcceptModal = () => {
        this.setIsOpenInternationalAcceptModal(false);
    };

    onCloseNonUsaModal = () => {
        this.setIsOpenNonUsaModal(false);
    };

    onCloseInternationalAvailabilityModal = () => {
        this.setIsOpenInternationalAvailabilityModal(false);
    };

    getSignupLandingPage = (locationPathname: string): string => {
        let signupLandingPage = locationPathname.split('?')[0];

        if (signupLandingPage[0] === '/') {
            signupLandingPage = signupLandingPage.substring(1);
        }

        if (signupLandingPage === '') {
            signupLandingPage = 'home';
        }

        return signupLandingPage;
    };

    trackAnalytics = (eventName: string): void => {
        AnalyticsService.track(eventName);
    };

    handleSubmit = () => {
        this.setState(() => ({
            isLoading: true
        }));

        const { source } = this.props;
        const { isValidGeolocation } = this.state;

        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.cta_click, {
            click_text: this.buttonText,
            section: source
        });

        const additionalDataFromSessionStorage: Record<string, unknown> =
            StorageService.getItem(
                CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
                'sessionStorage'
            ) || {};

        const globalAdditionalDataPerPath =
            additionalDataFromSessionStorage[window.location.pathname] || {};

        //flat all data per path to one object
        const globalAdditionalData = Object.values(
            globalAdditionalDataPerPath
        ).reduce<Record<string, unknown>>(
            (data, current) => ({
                ...data,
                ...current
            }),
            {}
        );

        AnalyticsService.trackClick(AnalyticsEvents.start_trial, {
            source,
            signup_landing_page: this.getSignupLandingPage(
                window.location.pathname
            ),
            platform: 'gatsby',
            start_trial_variant: 'single-cta',
            urgency_test_variant:
                ABTestToolServiceV2.getTestDetails('test_urgency')?.variant,
            ...globalAdditionalData
        });

        AnalyticsService.reportEvent('Intake Form Started');

        if (!isValidGeolocation) {
            this.setIsOpenNonUsaModal(true);

            this.setState(() => ({
                isLoading: false
            }));

            AnalyticsService.track(
                AnalyticsEvents.on_boarding_non_usa_warning_view
            );

            return;
        }

        this.completeRegistrationForm();
    };

    completeRegistrationForm = () => {
        const { templateId, couponCode, dataForIntakeFormTest } = this.props;
        // for single cta:
        if (templateId) {
            this.setInvoiceId(templateId);
        }

        const data = RegistrationService.handleRegistrationWithoutFields(
            this.geoLocationData
        );
        if (data && !data.promo && couponCode) {
            data.promo = couponCode;
        }

        if (dataForIntakeFormTest) {
            data.dataForIntakeFormTest = dataForIntakeFormTest;
        }

        RegistrationService.onRegistrationSuccessWithoutFields(data);
    };

    render() {
        const {
            t,
            showSSO,
            showNoCcRequired,
            size,
            buttonStyle,
            direction,
            customClass,
            testId
        } = this.props;
        const {
            isFacebookVariation,
            isLoading,
            isOpenNonUsaModal,
            isOpenInternationalAvailabilityModal,
            isOpenInternationalAcceptModal,
            internationalUserEmail,
            internationalUserCountry
        } = this.state;

        const registrationInputClasses = `registration-form__container registration-form__container--${direction} ${
            customClass || ''
        }`;

        return (
            <section className={registrationInputClasses}>
                <NonUsaWarningModal
                    isOpen={isOpenNonUsaModal}
                    onClose={this.onCloseNonUsaModal}
                    onConfirm={this.onConfirmNonUsaModal}
                    onSecondaryAction={this.onSecondaryActionNonUsaModal}
                />

                <InternationalAvailabilityModal
                    isOpen={isOpenInternationalAvailabilityModal}
                    onClose={this.onCloseInternationalAvailabilityModal}
                    onConfirm={this.onConfirmInternationalAvailabilityModal}
                />

                <InternationalAcceptModal
                    isOpen={isOpenInternationalAcceptModal}
                    onClose={this.onCloseInternationalAcceptModal}
                    email={internationalUserEmail}
                    country={internationalUserCountry}
                />
                <div className="registration-form__form-container">
                    <HBButton
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        size={size}
                        data-testid={testId || 'start-trial--button'}
                        type="submit"
                        text={this.buttonText}
                        buttonStyle={buttonStyle}
                        onClick={this.handleSubmit}
                    />
                    {showNoCcRequired && (
                        <div className="registration-form__no-cc-required">
                            {t('registrationForm.noCcRequired')}
                        </div>
                    )}
                </div>

                {false && showSSO && isFacebookVariation && (
                    <div className="registration-form__sso-container">
                        <HBSSOButton
                            successCallback={RegistrationService.successSSO}
                            type="facebook"
                            trackAnalytics={this.trackAnalytics}
                            analyticsEvents={analyticsEventsForFacebookSSO}
                        />
                        <div className="registration-form__sso-separator">
                            {t('registrationForm.or')}
                        </div>
                    </div>
                )}

                {false && showSSO && (
                    <div className="registration-form__sso-container">
                        <div className="registration-form__sso-separator">
                            {t('registrationForm.or')}
                        </div>
                        <HBSSOButton
                            successCallback={RegistrationService.successSSO}
                            type="google"
                            trackAnalytics={this.trackAnalytics}
                            analyticsEvents={analyticsEventsForGoogleSSO}
                        />
                    </div>
                )}
            </section>
        );
    }
}
const MemoisedRegistrationForm = memo(RegistrationForm);
export default withTranslation()(MemoisedRegistrationForm);

import { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FeatureBlockSection from '../FeatureBlockElements/FeatureBlockSection/FeatureBlockSection';
import FeatureBlockContent from '../FeatureBlockElements/FeatureBlockContent/FeatureBlockContent';
import FeatureBlockText from '../FeatureBlockElements/FeatureBlockText/FeatureBlockText';

import './blog-link-block.scss';

import BlogLink from '../BlogLink/BlogLink';

import type { MediaFlags } from '../BlogLink/BlogLink';
import HBText from '../honeybook-ui/HBText/HBText';

interface Blog {
    titleId: string;
    url: string;
    imagePath: string;
    mediaFlags?: MediaFlags;
}

interface Props {
    blogs: Blog[];
    customClass?: string;
    title?: string;
    subtitle?: string;
    lightBackground?: boolean;
    darkBackground?: boolean;
}

function BlogLinkBlock({
    lightBackground,
    darkBackground,
    customClass = '',
    blogs,
    title,
    subtitle
}: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <FeatureBlockSection
            customClass={`blog-link-block ${customClass}`.trimEnd()}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <HBText
                    customClass="blog-link-block__title"
                    tag="h2"
                    type="title-m"
                >
                    {title}
                </HBText>

                <HBText
                    customClass="blog-link-block__subtitle"
                    tag="h3"
                    type="body-l"
                >
                    {subtitle}
                </HBText>

                <div className="blog-link-block__container">
                    {blogs.map(
                        ({
                            titleId,
                            url,
                            imagePath,
                            mediaFlags
                        }: Blog): JSX.Element => (
                            <BlogLink
                                key={titleId}
                                url={url}
                                imagePath={imagePath}
                                mediaFlags={mediaFlags}
                                title={t(titleId)}
                            />
                        )
                    )}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

BlogLinkBlock.propTypes = {
    customClass: PropTypes.string,
    blogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool
};

export default memo(BlogLinkBlock);

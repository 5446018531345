import { LegacyRef, memo, useEffect, useRef } from 'react';
import HBMediaElement from '../honeybook-ui/HBMediaElement/HBMediaElement';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './icon-circle-animation.scss';

interface Props {
    innerIcons: string[];
    outerIcons: string[];
    customClass: string;
    innerRadius?: number;
    outerRadius?: number;
}

const IconCircleAnimation = ({
    innerIcons,
    outerIcons,
    customClass,
    innerRadius = 150,
    outerRadius = 250
}: Props) => {
    const innerRef: LegacyRef<any> = useRef();
    const outerRef: LegacyRef<any> = useRef();
    const breakpoint = useBreakpoint();

    useEffect(() => {
        let size;
        if (breakpoint.largeUp) {
            size = 1;
        } else if (breakpoint.mediumUp) {
            size = 0.75;
        } else {
            size = 0.65;
        }

        innerRef.current.style.setProperty(
            '--radius',
            `${innerRadius * size}px`
        );
        outerRef.current.style.setProperty(
            '--radius',
            `${outerRadius * size}px`
        );
    });
    return (
        <div
            className={`icon-circle-animation__animation-container ${customClass}`}
        >
            <div
                className="icon-circle-animation__animation-container__circle icon-circle-animation__animation-container__circle--outer"
                ref={outerRef}
            >
                {outerIcons &&
                    outerIcons.map(iconUrl => (
                        <HBMediaElement
                            key={iconUrl}
                            name={iconUrl}
                            width={'auto'}
                            customClass="icon-circle-animation__animation-container__icon"
                        />
                    ))}
            </div>

            <div
                className="icon-circle-animation__animation-container__circle icon-circle-animation__animation-container__circle--inner"
                ref={innerRef}
            >
                {innerIcons &&
                    innerIcons.map(iconUrl => (
                        <HBMediaElement
                            key={iconUrl}
                            name={iconUrl}
                            width={'auto'}
                            customClass="icon-circle-animation__animation-container__icon"
                        />
                    ))}
            </div>
        </div>
    );
};

export default memo(IconCircleAnimation);

import { useTranslation } from 'react-i18next';
import HBMediaElement from '@honeybook-ui/HBMediaElement/HBMediaElement';
import Hero from '../Hero/Hero';
import HeroTitle from '../../Hero/HeroTitle/HeroTitle';
import HeroStrip from '../../Hero/HeroStrip/HeroStrip';
import HeroColumn from '../../Hero/HeroColumn/HeroColumn';

import './product-education-hero.scss';

const ProductEducationHero = () => {
    const { t } = useTranslation();

    return (
        <Hero customClass="product-education-hero">
            <HeroStrip>
                <HeroColumn
                    columnWidth={1}
                    customClass="product-education-hero__content-left"
                >
                    <HeroTitle
                        title={t('productEducationPage.hero.title')}
                        subtitle={t('productEducationPage.hero.subtitle')}
                    />
                </HeroColumn>
                <HeroColumn
                    columnWidth={1}
                    customClass="product-education-hero__content-right"
                >
                    <HBMediaElement
                        width="700"
                        name="marketing_site/product-education/hero-prod-educatio_3x.jpg"
                        lazyLoad={false}
                    />
                </HeroColumn>
            </HeroStrip>
        </Hero>
    );
};

export default ProductEducationHero;

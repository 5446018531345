import { memo, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InvoiceTemplateElement from '../../InvoiceTemplateElement/InvoiceTemplateElement';
import {
    FeatureBlockSection,
    FeatureBlockContent,
    FeatureBlockText
} from '../../FeatureBlockElements';
import AnalyticsService, {
    AnalyticsEvents
} from '../../../Services/AnalyticsService';

import './invoice-templates-block.scss';
import NavigateLink from '../../Link/NavigateLink';

function InvoiceTemplatesBlock({
    title,
    subtitle,
    subtitleLink,
    invoices,
    lightBackground,
    darkBackground,
    showLinks,
    showVertical,
    templateTypeId,
    customClass = '',
    hasGatedDownload
}): JSX.Element {
    const { t } = useTranslation();

    const trackClickOnlineInvoices = useCallback(e => {
        AnalyticsService.trackClick(
            AnalyticsEvents.navigate_to_online_invoices,
            {
                source: 'free hb invoice template module'
            }
        );
    }, []);

    return (
        <FeatureBlockSection
            customClass={`invoice-templates-block ${customClass}`}
            lightBackground={lightBackground}
            darkBackground={darkBackground}
        >
            <FeatureBlockContent>
                <FeatureBlockText
                    fullWidth
                    customClass="invoice-templates-block__title"
                    primary={title}
                />
                {subtitle && (
                    <p className="invoice-templates-block__subtitle">
                        {subtitle}{' '}
                        <NavigateLink
                            source="invoice_template_block"
                            className="invoice-templates-block__subtitle-link"
                            onClick={trackClickOnlineInvoices}
                            link="/online-invoices"
                        >
                            {subtitleLink}
                        </NavigateLink>
                    </p>
                )}

                <div className="invoice-templates-block__container">
                    {invoices.map(invoice => {
                        return (
                            <div
                                key={invoice}
                                className="invoice-templates-block__item"
                            >
                                <InvoiceTemplateElement
                                    showLinks={showLinks}
                                    showVertical={showVertical}
                                    invoiceId={invoice}
                                    templateTypeId={templateTypeId}
                                    hasGatedDownload={hasGatedDownload}
                                    mediaFlags={{
                                        alt: t(invoice),
                                        width: 500,
                                        crop: 'scale'
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
}

InvoiceTemplatesBlock.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleLink: PropTypes.string,
    lightBackground: PropTypes.bool,
    darkBackground: PropTypes.bool,
    showLinks: PropTypes.bool,
    showVertical: PropTypes.bool,
    customClass: PropTypes.string,
    templateTypeId: PropTypes.string,
    hasGatedDownload: PropTypes.bool
};

export default memo(InvoiceTemplatesBlock);

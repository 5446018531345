import * as PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';

import InvoiceGeneratorHero from '../components/Hero/InvoiceGeneratorHero/InvoiceGeneratorHero';
import InvoiceGeneratorFreeInvoiceBlock from '../components/FeatureBlocks/InvoiceGeneratorFreeInvoiceBlock/InvoiceGeneratorFreeInvoiceBlock';
import InvoiceTemplatesBlock from '../components/FeatureBlocks/InvoiceTemplatesBlock/InvoiceTemplatesBlock';
import CTABlock from '../components/FeatureBlocks/CTABlock/CTABlock';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import InvoiceGeneratorTypesBlock from '../components/FeatureBlocks/InvoiceGeneratorTypesBlock/InvoiceGeneratorTypesBlock';
import InvoiceGeneratorImportance from '../components/FeatureBlocks/InvoiceGeneratorImportance/InvoiceGeneratorImportance';
import PictureAndTextWrapBlock from '../components/FeatureBlocks/PictureAndTextWrapBlock/PictureAndTextWrapBlock';
import FreeToTryBlockRebrand from '../components/FeatureBlocks/FreeToTryBlockRebrand/FreeToTryBlockRebrand';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import InvoiceGeneratorOtherFeaturesBlock from '../components/FeatureBlocks/InvoiceGeneratorOtherFeaturesBlock/InvoiceGeneratorOtherFeaturesBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import { TRANSLATION_COMPONENTS } from '../constants/constants';

import '../styles/invoice-generator.scss';
import NeedMissionStatementBlock from '../components/FeatureBlockElements/NeedMissionStatementBlock/NeedMissionStatementBlock';

const data = {
    doula: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/doula',
            title: 'Free Doula Invoice Template',
            description:
                'Use our free doula invoice template to enhance your doula business. Customize your invoice template and get paid with ease.'
        },
        benefits: true,
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            translationWithHTML:
                "#TEXT# <a href='/doula-business-software'>#LINK_TEXT#</a> #TEXT#</a>",
            ctaLink: '/doula-business-software'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.doula.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.doula.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.doula.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.doula.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.doula.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.doula.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        invoiceIds: ['birthPlan', 'virtualBirthPlan', 'postPartumPlan'],
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/group-28-copy_2x.png',
            translationWithHTML:
                "#TEXT# <a href='/'>#LINK_TEXT#</a> #TEXT#</a> <a href='/blog/how-to-create-an-invoice'>#LINK_TEXT#</a> #TEXT#"
        },
        faqs: [
            {
                id: 'doulaInvoiceContents'
            },
            {
                id: 'doulaClients',
                answer: '#TEXT# <a href="/all-in-one-business-platform" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/doula-business-software">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'doulaStarting',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'doulaCustom'
            },
            {
                id: 'doulaInvoiceTypes'
            },
            {
                id: 'doulaNeed',
                answer: '#TEXT# <a href="/doula-business-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'doulaCharge'
            }
        ]
    },
    design: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/designers',
            title: 'The Best Designer Invoice Template',
            description:
                'Create a free designer invoice template in seconds. Send online with automatic payment reminders to get paid faster and focus more on your design.'
        },
        benefits: true,
        invoiceIds: [
            'packagingLabels',
            'brandingDesign',
            'remodelingRenovationFixDesign'
        ],
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/designers/Module%203/Pipeline.png',
            ctaLink: '/crm-for-graphic-designers'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.design.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.design.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.design.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.design.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.design.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.design.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/designers/Module%205/Packaging.png',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'designInvoiceOverview'
            },
            {
                id: 'designInvoiceGetStarted',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designInvoiceTypes',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designInvoiceNeed',
                answer: '#TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designInvoiceCharge'
            },
            {
                id: 'designInvoiceBest'
            },
            {
                id: 'designInvoiceInclude'
            },
            {
                id: 'designInvoiceProLook'
            },
            {
                id: 'designInvoicePrep',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designInvoiceHowTo'
            },
            {
                id: 'designInvoiceCustom',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="https://www.honeybook.com/crm-for-designers" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'designInvoiceWork'
            }
        ]
    },
    graphicDesign: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/graphic-design',
            title: 'Graphic Design Invoice Templates: easy, free, professional',
            description:
                'Create and send custom invoices using free graphic design invoice templates. Being more organized helps you get faster payments and provide better services.'
        },
        invoiceIds: [
            'brandingGraphicDesign',
            'packagingLabelsGraphic',
            'webMobileAppDesign'
        ],
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            ctaLink: '/crm-for-graphic-designers'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.graphicDesign.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.graphicDesign.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.graphicDesign.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.graphicDesign.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.graphicDesign.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.graphicDesign.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/graphic-design/howTo-graphicDesign.png',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'graphicDesignInvoice'
            },
            {
                id: 'graphicDesignStarting'
            },
            {
                id: 'graphicDesignInvoiceTypes'
            },
            {
                id: 'graphicDesignNeedSoftware',
                answer: '#TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'graphicDesignServices'
            },
            {
                id: 'graphicDesignBestInvoice'
            },
            {
                id: 'graphicDesignHowToInvoice'
            },
            {
                id: 'graphicDesignCustomInvoice',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'graphicDesignWork',
                answer: '#TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'graphicDesignInvoiceBasic'
            }
        ]
    },
    interiorDesign: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/interior-design',
            title: 'Invoice Templates for Interior Designers',
            description:
                'Create free invoice templates for interior designers. Send online with automatic payment reminders to get paid faster and focus more on your  work.'
        },
        benefits: true,
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            ctaLink: '/interior-design-business-software'
        },
        invoiceIds: [
            'privateResidenceDesign',
            'remodelingRenovationFix',
            'officeSpaceDesign'
        ],
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.interiorDesign.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.interiorDesign.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.interiorDesign.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.interiorDesign.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.interiorDesign.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.interiorDesign.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/interior-design/interiorHowTo.png',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'interiorDesignInvoiceContent'
            },
            {
                id: 'interiorDesignGetStarted'
            },
            {
                id: 'interiorDesignInvoiceTypes'
            },
            {
                id: 'interiorDesignReason',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/">#LINK_TEXT#</a> #TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'interiorDesignCharge'
            },
            {
                id: 'intCommonMistakes'
            },
            {
                id: 'interiorDesignIncluded'
            },
            {
                id: 'interiorDesignInvoiceLook'
            },
            {
                id: 'interiorDesignPopular',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'interiorDesignGettingStarted',
                answer: '#TEXT# <a href="/all-in-one-business-platform" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'interiorDesignQuote',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    consultants: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/consultants',
            title: 'Consulting Invoice Templates to Get you Paid Fast',
            description:
                'Create professional invoices for consultants with HoneyBook’s consulting invoice templates. Plus, send them online to get paid faster.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            ctaLink: '/crm-for-consultants'
        },
        invoiceIds: [
            'brandConsulting',
            'socialMediaConsulting',
            'seoConsulting'
        ],
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.consultants.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.consultants.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.consultants.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.consultants.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.consultants.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.consultants.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/consultants/consultantInvoiceGen.png',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'consultantInvoiceContents',
                answer: '#TEXT# <a href="/online-invoices">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'consultantFindClients',
                answer: '#TEXT# <a href="/crm-for-consultants">#LINK_TEXT#</a> #TEXT# <a href="/crm-for-consultants">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'consultantMakeInvoice',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'consultantsBill'
            },
            {
                id: 'consultantInvoiceTypes'
            },
            {
                id: 'consultantNeedInvoice',
                answer: '#TEXT# <a href="/blog/invoice-payments">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'consultantBestInvoice'
            },
            {
                id: 'consultantComponents'
            },
            {
                id: 'consultantBestProgram',
                answer: '#TEXT# <a href="/proposal-software">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    photographers: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/photographers',
            title: 'Photography Invoice Templates',
            description:
                'Get your hands on free photography invoice templates. Create beautiful, custom invoices in seconds and share them online to get paid faster with HoneyBook.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            imageAlt: 'HoneyBook’s photography CRM software',
            translationWithHTML:
                "#TEXT# <a href='/doula-business-software'>#LINK_TEXT#</a> #TEXT#</a>",
            ctaLink: '/crm-for-photographers'
        },
        invoiceIds: [
            'portraitPhotography',
            'foodPhotography',
            'eventPhotography'
        ],
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.photographers.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.photographers.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.photographers.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.photographers.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.photographers.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.photographers.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'https://res.cloudinary.com/honeybook/image/upload/v1598400472/marketing_site/invoice-generator-lp/photographers/photo-howTo.png',
            imageAlt: 'Customize Your Photography Invoice Template',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'photographerHowToMake',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photographerInvoiceContents'
            },
            {
                id: 'photographerStart',
                answer: '#TEXT# <a href="/crm-for-photographers" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/invoice-template" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photographerInvoiceTypes'
            },
            {
                id: 'photographerInvoiceNeed',
                answer: '#TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photographerCharge'
            },
            {
                id: 'photographerPaid'
            },
            {
                id: 'photographerPaymentDetails'
            },
            {
                id: 'photographerPayments',
                answer: '#TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'photographerSittingFee'
            }
        ]
    },
    coach: {
        ogTags: {
            url: '/free-invoice-templates/coach',
            title: 'Free Coaching Invoice Templates',
            description:
                'Create free coaching invoice templates in seconds. Send online with automatic payment reminders to get paid faster and focus more on coaching.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/pipeline-illustration_2x.png',
            ctaLink: '/life-coach-software'
        },
        invoiceIds: ['healthCoach', 'businessCoach', 'careerCoach'],
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.coach.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.coach.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.coach.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.coach.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.coach.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.coach.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/coach/coachHowTo.png',
            translationWithHTML: '#TEXT#'
        },
        faqs: [
            {
                id: 'coachInvoiceContents'
            },
            {
                id: 'coachStart',
                answer: '#TEXT# <a href="/crm-for-consultants" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/crm-for-consultants" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachFindClient',
                answer: '#TEXT# <a href="/all-in-one-business-platform" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachInvoiceTypes'
            },
            {
                id: 'coachNeed',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachBestTempalte'
            },
            {
                id: 'coachInvoiceMake',
                answer: '#TEXT# <a href="/blog/?p=25846" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachInvoiceReason'
            },
            {
                id: 'coachBestProgram',
                answer: '#TEXT# <a href="/life-coach-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachInvoiceFormat',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachContract',
                answer: '#TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'coachComponents',
                answer: '#TEXT# <a href="/invoice-template" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    tutors: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/tutors',
            title: 'Free Tutoring Invoice Templates',
            description:
                'Create free tutoring invoice templates in seconds. Send online with automatic payment reminders to get paid faster and focus more on tutoring.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/tutors/Module%203/Pipeline.png',
            translationWithHTML:
                "#TEXT# <a href='/tutor-software'>#LINK_TEXT#</a> #TEXT#</a>",
            ctaLink: '/tutor-software'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.tutors.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.tutors.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.tutors.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.tutors.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.tutors.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.tutors.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        invoiceIds: [
            'homeschool',
            'onlineTutoring',
            'elementaryAndMiddleSchool'
        ],
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/tutors/Module%205/Homeschooling.png',
            translationWithHTML:
                "#TEXT# <a href='/'>#LINK_TEXT#</a> #TEXT#</a> <a href='/blog/how-to-create-an-invoice'>#LINK_TEXT#</a> #TEXT#"
        },
        faqs: [
            {
                id: 'tutorInvoiceOverview'
            },
            {
                id: 'tutorInvoiceHowTo'
            },
            {
                id: 'tuturInvoiceWriting'
            },
            {
                id: 'tutorInvoiceQuote'
            },
            {
                id: 'tutorInvoiceContents'
            },
            {
                id: 'tutorInvoiceFindClients',
                answer: '#TEXT# <a href="/all-in-one-business-platform" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorInvoiceStarting',
                answer: '#TEXT# <a href="/proposal-software">#LINK_TEXT#</a> #TEXT# <a href="/">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorInvoiceTypes'
            },
            {
                id: 'tutorInvoiceBest'
            },
            {
                id: 'tutorInvoiceContract',
                answer: '#TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorInvoiceBestProgram'
            },
            {
                id: 'tutorInvoiceWhy',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'tutorInvoiceBilling'
            },
            {
                id: 'tutorInvoiceBestWay'
            }
        ]
    },
    cleaners: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/cleaners',
            title: 'Invoice Templates for Cleaning Services',
            description:
                'Try our invoice templates for cleaning services, where you can customize your contracts and organize your client communications and payments in one place.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/cleaners/Module%203/Pipeline.png',
            ctaLink: '/software-for-cleaning-business'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.cleaners.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.cleaners.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.cleaners.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.cleaners.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.cleaners.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.cleaners.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        invoiceIds: ['homeCleaning', 'officeCleaning', 'movingCleaning'],
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/cleaners/Module%205/Office.png',
            translationWithHTML:
                "#TEXT# <a href='/'>#LINK_TEXT#</a> #TEXT#</a> <a href='/blog/how-to-create-an-invoice' target=\"_blank\">#LINK_TEXT#</a> #TEXT#"
        },
        faqs: [
            {
                id: 'clearnerInvoiceOverview',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleanerInvoiceHowTo'
            },
            {
                id: 'cleaningInvoiceQuote'
            },
            {
                id: 'cleaningInvoiceContents'
            },
            {
                id: 'cleanerInvoiceWriting'
            },
            {
                id: 'cleanerInvoiceFind',
                answer: '#TEXT# <a href="/software-for-cleaning-business" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleanerInvoiceStart',
                answer: '#TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleanerInvoiceTypes',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleanerInvoiceBest',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'cleanerInvoiceContract'
            },
            {
                id: 'cleanerInvoiceBestProgram'
            },
            {
                id: 'cleanerInvoiceWhy'
            },
            {
                id: 'cleanerInvoiceComponents'
            },
            {
                id: 'cleanerInvoiceNeeed',
                answer: '#TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            }
        ]
    },
    virtualAssistant: {
        ogTags: {
            url: 'https://www.honeybook.com/free-invoice-templates/virtual-assistant',
            title: 'Virtual Assistant Invoice Templates',
            description:
                'Virtual Assistant invoice templates. Create and send custom invoices in seconds for free by using our invoice generator, software, or our full CRM system.'
        },
        bestSoftware: {
            image: 'marketing_site/invoice-generator-lp/virtual-assistant/Module%203/Pipeline.png',
            ctaLink: '/virtual-assistant-software'
        },
        easySteps: {
            imageTextElements: [
                {
                    title: 'invoiceGeneratorPages.virtualAssistant.easySteps.step1.title',
                    text: 'invoiceGeneratorPages.virtualAssistant.easySteps.step1.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/download_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.virtualAssistant.easySteps.step2.title',
                    text: 'invoiceGeneratorPages.virtualAssistant.easySteps.step2.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/tutors/Module%206/details_illustration.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                },
                {
                    title: 'invoiceGeneratorPages.virtualAssistant.easySteps.step3.title',
                    text: 'invoiceGeneratorPages.virtualAssistant.easySteps.step3.description',
                    imagePath:
                        'marketing_site/invoice-generator-lp/calculate_illustration2.svg',
                    mediaFlags: { forceImgTag: true, height: 130 }
                }
            ]
        },
        invoiceIds: [
            'projectManagerVirtualAssistant',
            'virtualMarketingAssistant',
            'adminVirtualAssistant'
        ],
        importance: {
            textBlocks: [
                'getPaid',
                'customExperience',
                'simplify',
                'backToWork'
            ]
        },
        howTo: {
            image: 'marketing_site/invoice-generator-lp/virtual-assistant/Module%205/Project_Manager.png',
            translationWithHTML:
                "#TEXT# <a href='/'>#LINK_TEXT#</a> #TEXT#</a> <a href='/blog/how-to-create-an-invoice'>#LINK_TEXT#</a> #TEXT#"
        },
        faqs: [
            {
                id: 'virtualInvoiceOverview'
            },
            {
                id: 'virtualInvoiceService'
            },
            {
                id: 'virtualInvoiceOwn',
                answer: '#TEXT# <a href="/blog/how-to-create-an-invoice">#LINK_TEXT#</a> #TEXT# <a href="/virtual-assistant-software">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceJob'
            },
            {
                id: 'virtualInvoiceContents'
            },
            {
                id: 'virtualInvoiceStart',
                answer: '#TEXT# <a href="/proposal-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceTrackHours'
            },
            {
                id: 'virtualInvoiceFind',
                answer: '#TEXT# <a href="/virtual-assistant-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/online-payment-software" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/blog/how-to-accept-payments-online" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceTypes',
                answer: '#TEXT# <a href="/invoice" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceBestTemplate'
            },
            {
                id: 'virtualInvoiceWrite',
                answer: '#TEXT# <a href="/" target="_blank">#LINK_TEXT#</a> #TEXT# <a href="/online-contract" target="_blank">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceBestProgram'
            },
            {
                id: 'virtualInvoiceBill'
            },
            {
                id: 'virtualInvoiceNeed'
            },
            {
                id: 'virtualInvoiceProgram',
                answer: '#TEXT# <a href="/all-in-one-business-platform">#LINK_TEXT#</a> #TEXT#'
            },
            {
                id: 'virtualInvoiceOffer'
            }
        ]
    }
};

const handleBestSoftwareAnalytics = () => {
    AnalyticsService.trackClick(
        AnalyticsEvents.lp_invoice_generator_best_software_cta
    );
};

interface Props {
    pageContext: {
        contentKey: string;
    };
}

const BLOCK_TEXT_DATA = ['trust', 'time', 'streamline'];

function InvoiceGeneratorTemplate({
    pageContext: { contentKey }
}: Props): JSX.Element {
    const { t, i18n } = useTranslation();

    const {
        ogTags,
        bestSoftware,
        easySteps,
        importance,
        howTo,
        invoiceIds,
        faqs
    } = data[contentKey];

    const invoiceTemplateElementsBlockTitle = `${t(
        `invoiceGeneratorPages.generic.invoiceTemplateBlock.pre`
    )} ${t(`TemplateElements.${contentKey}.titlePlural`).toLowerCase()}${t(
        `invoiceGeneratorPages.generic.invoiceTemplateBlock.post`
    )}`;

    return (
        <Layout ogTags={ogTags}>
            <div
                className={`invoice-generator-lp invoice-generator-lp--${contentKey}`}
            >
                <InvoiceGeneratorHero contentKey={contentKey} />

                {!!data[contentKey]?.benefits && (
                    <NeedMissionStatementBlock
                        transId={`invoiceGeneratorPages.${contentKey}.benefits`}
                        blockTextTransIds={BLOCK_TEXT_DATA}
                    />
                )}

                <InvoiceGeneratorFreeInvoiceBlock
                    contentKey={contentKey}
                    registrationSource={`invoice generator lp - ${contentKey}`}
                />

                <InvoiceTemplatesBlock
                    title={invoiceTemplateElementsBlockTitle}
                    lightBackground
                    invoices={invoiceIds}
                    templateTypeId="invoice"
                    hasGatedDownload
                />

                <PictureAndTextWrapBlock
                    customClass="invoice-generator-lp__best-software"
                    primary={t(
                        `invoiceGeneratorPages.${contentKey}.bestSoftware.title`
                    )}
                    image={bestSoftware.image}
                    imageWidth={800}
                    lightBackground
                    imageAltTag={
                        bestSoftware.imageAlt ||
                        t(
                            `invoiceGeneratorPages.${contentKey}.bestSoftware.title`
                        )
                    }
                >
                    <Trans
                        i18nKey={`invoiceGeneratorPages.${contentKey}.bestSoftware.body`}
                        components={TRANSLATION_COMPONENTS}
                    />
                    <a
                        href={bestSoftware.ctaLink}
                        onClick={handleBestSoftwareAnalytics}
                        className="hbui-button hbui-button--medium hbui-button--primary"
                    >
                        {t(
                            `invoiceGeneratorPages.generic.bestSoftware.ctaText`
                        )}{' '}
                        {t(
                            `invoiceGeneratorPages.${contentKey}.bestSoftware.ctaText`
                        )}
                    </a>
                </PictureAndTextWrapBlock>

                <CTABlock
                    imageUrl="marketing_site/invoice-generator-lp/clipboard.png"
                    primary={t('invoiceGeneratorPages.generic.CTABlock.title')}
                    buttonText={t(
                        'invoiceGeneratorPages.generic.CTABlock.CTAText'
                    )}
                    showNoCcRequired
                    mediaFlags={{
                        width: 165,
                        alt: t(
                            'invoiceGeneratorPages.generic.CTABlock.imageAltTag'
                        )
                    }}
                />

                <PictureAndTextWrapBlock
                    lightBackground
                    customClass="invoice-generator-lp__how-to"
                    primary={t(
                        `invoiceGeneratorPages.${contentKey}.howTo.title`
                    )}
                    image={howTo.image}
                    imageWidth={800}
                    imageAltTag={
                        howTo.imageAlt ||
                        t(`invoiceGeneratorPages.${contentKey}.howTo.title`)
                    }
                >
                    <Trans
                        i18nKey={`invoiceGeneratorPages.${contentKey}.howTo.body`}
                        components={TRANSLATION_COMPONENTS}
                    />
                </PictureAndTextWrapBlock>

                <ImageTextElementsBlock
                    customClass="invoice-generator-lp__easy-steps"
                    title={t(
                        `invoiceGeneratorPages.${contentKey}.easySteps.title`
                    )}
                    imageTextElements={easySteps.imageTextElements}
                />

                <InvoiceGeneratorTypesBlock
                    contentKey={contentKey}
                    lightBackground
                />

                <InvoiceGeneratorImportance
                    transKey={`invoiceGeneratorPages.${contentKey}.importance`}
                    contentKey={contentKey}
                    textBlocks={importance.textBlocks}
                />

                <InvoiceGeneratorOtherFeaturesBlock
                    contentKey={contentKey}
                    lightBackground
                />

                <FAQBlock
                    title={
                        i18n.exists(
                            `invoiceGeneratorPages.${contentKey}.faqTitle`
                        )
                            ? t(`invoiceGeneratorPages.${contentKey}.faqTitle`)
                            : t(`invoiceGeneratorPages.generic.faqTitle`)
                    }
                    faqs={faqs}
                />

                <FreeToTryBlockRebrand />
            </div>
        </Layout>
    );
}

InvoiceGeneratorTemplate.propTypes = {
    contentKey: PropTypes.string
};

export default InvoiceGeneratorTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "invoiceGenerator"
                        "templateGallery"
                        "common"
                        "faqs"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import * as queryString from 'query-string';
import fetch from 'node-fetch';

import type { RequestInfo, RequestInit } from 'node-fetch';
import type { GeoData } from './GeolocationService';

export interface ValidateOnboardingResponse {
    is_valid: boolean;
    domain_not_valid: boolean;
    redirect_to_url: string;
}

export interface RegistrationData {
    vendor_email?: string;
    vendor_name?: string;
    registration_variant:
        | 'paywall'
        | 'homepage'
        | 'homepage_facebook'
        | 'community';
    geo_data?: GeoData;
    fullName?: string;
    is_sso: boolean;
    sso_type?: string;
    auth_token?: string;
    auth_code?: string;
    profile_img_url?: string;
    pricingPlan?: unknown; // is it boolean?
    server_response?: ValidateOnboardingResponse;
}

export interface SignupUserResponse {
    api_version: string;
    authentication_token: string;
    _id: string;
}

export interface InvoiceGeneratorResponse {
    secure_url: string;
}

export default class ApiService {
    static _apiFetch = <T>(key: RequestInfo, data: RequestInit): Promise<T> => {
        return fetch(key, data).then(response => {
            if (response.status === 204) return '';
            return response.json();
        });
    };

    static validateOnboarding = (registrationData: RegistrationData) => {
        const fetchKey = `${
            process.env.GATSBY_baseApiPrefix
        }api/validate_onboarding? ${queryString.stringify(registrationData)}`;
        return this._apiFetch<ValidateOnboardingResponse>(fetchKey, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });
    };

    static ssoSignupUser = (
        ssoData: Record<string, unknown>
    ): Promise<SignupUserResponse> => {
        return this._apiFetch(
            `${process.env.GATSBY_baseApiPrefix}api/v2/users/oauth/sso_signup_user`,
            {
                method: 'POST',
                body: JSON.stringify(ssoData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ) as Promise<SignupUserResponse>;
    };

    static createEmailSignature = data => {
        interface Res {
            success?: boolean;
            lead_email_signature_id?: string;
            error?: boolean;
            error_message?: string;
            error_type?: string;
            is_timeout?: boolean;
            note?: string;
        }

        return this._apiFetch(
            `${process.env.GATSBY_baseApiPrefix}api/v2/lead_email_signature_generator`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then((res: Res) => {
            if (res.error) {
                throw new Error(res.error_message);
            } else {
                return res;
            }
        });
    };

    static getEmailSignatureById(id: string) {
        const url =
            'https://s3.amazonaws.com/' +
            process.env.S3_email_signature_bucket +
            '/' +
            id +
            '.html';
        return fetch(url, { method: 'GET' }).then(response => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error('failed to fetch email signature');
            }
        });
    }

    static uploadImage = imageData => {
        const url =
            'https://api.cloudinary.com/v1_1/honeybook-landing/image/upload';
        return this._apiFetch(url, {
            method: 'POST',
            body: imageData
        });
    };
}

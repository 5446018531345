import { Link } from 'gatsby';
import type { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import AnalyticsService, {
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';
import { pagesSet } from '../../../pages-list';
import { checkShouldReloadDueToMissingTestsInTargetPage } from '../../ab-tests/ab-test-routing-utils';
import React from 'react';

interface Props {
    id?: string;
    link: string;
    role?: string;
    dataLinkKey?: string;
    tabIndex?: number;
    className?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement>;
    children?: ReactNode;
    newTab?: boolean;
    source:
        | 'logo'
        | 'navbar'
        | 'footer'
        | 'pop_up'
        | 'navbar_menu'
        | 'feature_values'
        | 'invoice_template_block'
        | 'tos'
        | '404_to_home'
        | 'unsupported_to_home'
        | 'online_invoices_hero';
}

const NavigateLink = ({
    id,
    className = '',
    link,
    onClick,
    onMouseEnter,
    role,
    tabIndex,
    dataLinkKey = 'data-item-link',
    children,
    source,
    newTab = false
}: Props) => {
    const _onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.navigation_click, {
            click_url: link,
            section: source
        });
        onClick?.(event);

        const shouldReload =
            checkShouldReloadDueToMissingTestsInTargetPage(link);
        if (shouldReload) {
            window.location.assign(link);
            event.preventDefault();
            return;
        }
    };
    const attributesForLink = {
        className,
        onClick: _onClick,
        onMouseEnter,
        [dataLinkKey]: link,
        tabIndex,
        target: newTab ? '_blank' : '_self'
    };

    if (role) {
        attributesForLink['role'] = role;
    }
    if (id) {
        attributesForLink['data-id'] = id;
    }

    return pagesSet.has(link) ? (
        <Link to={link} {...attributesForLink}>
            {children}
        </Link>
    ) : (
        <a href={link} {...attributesForLink}>
            {children}
        </a>
    );
};

export default NavigateLink;

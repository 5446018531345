import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeatureHeroBlock from '../components/FeatureBlocks/FeatureHeroBlock/FeatureHeroBlock';
import FeaturePreviewBlock from '../components/FeatureBlocks/FeaturePreviewBlock/FeaturePreviewBlock';
import FeatureSignatureBlock from '../components/FeatureBlocks/FeatureSignatureBlock/FeatureSignatureBlock';
import FeatureTextBlock from '../components/FeatureBlocks/FeatureTextBlock/FeatureTextBlock';
import StepFeatureBlock from '../components/FeatureBlocks/StepFeatureBlock/StepFeatureBlock';
import FeatureTemplateGallery from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import FeatureSuperchargeBlock from '../components/FeatureBlocks/FeatureSuperchargeBlock/FeatureSuperchargeBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import MobileStickyCTA from '../components/MobileStickyCTA/MobileStickyCTA';
import { usePromoCode } from '../hooks/usePromoCode';
import BellIcon from '../svg/bell.inline.svg';
import CheckCircleIcon from '../svg/check-circle.inline.svg';
import ClockIcon from '../svg/clock.inline.svg';
import TemplatesIcon from '../svg/templates.inline.svg';
import SparksIcon from '../svg/sparks.inline.svg';
import FeatureIntegrationBlockNew from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlockNew';

import '../styles/online-template.scss';

const ogTags = {
    url: 'https://www.honeybook.com/get-online-contract',
    title: 'Online Contract Templates',
    description:
        'Quickly access online contract templates to easily create, edit, and send secure online contracts from your desktop or mobile device. Try free for 7 days.',
    noIndex: true
};

const stepBlocksOne = [
    {
        subTransId: 'browse',
        iconSVG: <TemplatesIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Browse_legally-vetted_templates.png'
    },
    {
        subTransId: 'autoFill',
        iconSVG: <SparksIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Auto-fill_client_details.png'
    }
];

const stepBlocksTwo = [
    {
        subTransId: 'schedule',
        iconSVG: <ClockIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Send_at_the_perfect_moment.png'
    },
    {
        subTransId: 'clientSuccess',
        iconUrl: 'marketing_site/online-contracts/templates/Team_avatars.png',
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Manage_client_access.png'
    }
];

const stepBlocksThree = [
    {
        subTransId: 'statusUpdates',
        iconSVG: <CheckCircleIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Receive_status_updates.png'
    },
    {
        subTransId: 'reminders',
        iconSVG: <BellIcon />,
        imageUrl:
            'marketing_site/online-contracts/templates/desktop/v2/Automate_gentle_reminders.png'
    }
];

const previewContent = {
    client: {
        imgUrl: 'marketing_site/online-contracts/preview/Clientview.png',
        mobileImgUrl:
            'marketing_site/online-contracts/preview/client_view_mobile.png'
    },
    your: {
        imgUrl: 'marketing_site/online-contracts/preview/Yourview.png',
        mobileImgUrl:
            'marketing_site/online-contracts/preview/your_view_mobile.png'
    }
};

const signatureCardContents = [
    {
        iconUrl: 'marketing_site/online-contracts/signature/Signature.svg',
        transId: 'effortless'
    },
    {
        iconUrl: 'marketing_site/online-contracts/signature/Phone.svg',
        transId: 'onTheGo'
    },
    {
        iconUrl: 'marketing_site/online-contracts/signature/Stars.svg',
        transId: 'impression'
    }
];

function OnlineContract(): JSX.Element {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.signed.title')}</Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('contractsStatsAnimation.signed.subtitle')}
                    </Trans>
                )
            },
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.value.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('contractsStatsAnimation.value.subtitle')}</Trans>
                )
            },
            {
                title: (
                    <Trans>{t('contractsStatsAnimation.daily.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('contractsStatsAnimation.daily.subtitle')}</Trans>
                )
            }
        ],
        []
    );

    const linkTransKey = 'linkText';
    const transKey = `stepsFeatureBlock.${linkTransKey}`;

    return (
        <Layout ogTags={ogTags} slimNav slimFooter>
            <div className="online-template">
                <FeatureHeroBlock
                    label={t('featureHeroBlock.label')}
                    title={t('featureHeroBlock.title')}
                    subtitle={t('featureHeroBlock.subtitle')}
                    stats={stats}
                />
                <FeaturePreviewBlock
                    transId="contractToggle"
                    previewContent={previewContent}
                />
                <FeatureSignatureBlock
                    cardContents={signatureCardContents}
                    ctaSource="contract signature block"
                />
                <FeatureTextBlock
                    title={t('featureTextBlock.title')}
                    imageUrl="marketing_site/online-contracts/Contract.png"
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step1"
                    stepSubBlocks={stepBlocksOne}
                    linkText={t(transKey)}
                />
                <StepFeatureBlock
                    reverse
                    transId="stepsFeatureBlock.step2"
                    stepSubBlocks={stepBlocksTwo}
                    linkText={t(transKey)}
                />
                <StepFeatureBlock
                    transId="stepsFeatureBlock.step3"
                    stepSubBlocks={stepBlocksThree}
                    linkText={t(transKey)}
                />
                <FeatureTemplateGallery ctaSource="Contract template gallery footer" />
                <TestimonialCarouselCardBlock />
                <FeatureSuperchargeBlock />
                <FeatureIntegrationBlockNew
                    title={t('featureIntegrationBlockNew.title')}
                    subtitle={t('featureIntegrationBlockNew.subtitle')}
                />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - yellow"
                    promoCode={promoCode}
                />
                <MobileStickyCTA />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "translations", "onlineContract", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default OnlineContract;

import HBText from '@honeybook-ui/HBText/HBText';
import RegistrationForm from '../../RegistrationForm/RegistrationForm';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ContractTemplate from '../../ContractTemplate/ContractTemplate';

import { FeatureBlockSection, FeatureBlockContent } from '..';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';

import AnalyticsService from '../../../Services/AnalyticsService';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './feature-template-gallery.scss';

export interface CustomTemplateData {
    templateId: string;
    transId: string;
    imageUrl: string;
}

interface Props {
    title?: string;
    subTitle?: string;
    customTemplateData?: CustomTemplateData[];
    ctaSource: string;
}

const defaultTemplateData = [
    {
        templateId: '61633ab31dd45702a0c16b8f',
        transId: 'templates.clientService',
        imageUrl: 'marketing_site/online-contracts/templates/1.png'
    },
    {
        templateId: '64660a7462710100294c3cba',
        transId: 'templates.signOff',
        imageUrl: 'marketing_site/online-contracts/templates/2.png'
    },
    {
        templateId: '64660a7ec55cd3002ab03ecb',
        transId: 'templates.photoRelease',
        imageUrl: 'marketing_site/online-contracts/templates/3.png'
    },
    {
        templateId: '64660a863a91d1002c24759c',
        transId: 'templates.cancel',
        imageUrl: 'marketing_site/online-contracts/templates/4.png'
    },
    {
        templateId: '616336771dd45702a0c16b07',
        transId: 'templates.simpleContract',
        imageUrl: 'marketing_site/online-contracts/templates/5.png'
    },
    {
        templateId: '64660a8bf76fe0002c468f0c',
        transId: 'templates.liabilityWaiver',
        imageUrl: 'marketing_site/online-contracts/templates/6.png'
    }
];

const FeatureTemplateGallery = ({
    title,
    subTitle,
    customTemplateData,
    ctaSource
}: Props) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const templateData = customTemplateData || defaultTemplateData;

    const DesktopLayout = () => {
        return (
            <div className="feature-template-gallery__container">
                {templateData.map(template => {
                    return (
                        <ContractTemplate
                            {...template}
                            key={template.templateId}
                        />
                    );
                })}
            </div>
        );
    };

    const MobileLayout = () => {
        return (
            <Swiper
                className="mySwiper"
                slidesPerView={1}
                initialSlide={2}
                loop={true}
                pagination={true}
                mousewheel={false}
                draggable={false}
                centeredSlides
                slideToClickedSlide
                autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true
                }}
                keyboard={true}
                modules={[
                    Navigation,
                    Pagination,
                    Mousewheel,
                    Keyboard,
                    Autoplay
                ]}
            >
                {templateData.map(template => {
                    return (
                        <SwiperSlide key={template.templateId}>
                            <ContractTemplate {...template} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    };

    return (
        <FeatureBlockSection customClass="feature-template-gallery">
            <FeatureBlockContent>
                <div className="feature-template-gallery__text-container">
                    <HBText
                        customClass="feature-template-gallery__title"
                        tag="h1"
                        type="title-l"
                    >
                        {title || t('featureTemplateGallery.title')}
                    </HBText>
                    <HBText
                        customClass="feature-template-gallery__subtitle"
                        tag="h2"
                        type="body-l"
                    >
                        {subTitle || t('featureTemplateGallery.subTitle')}
                    </HBText>
                </div>

                {breakpoints.mediumUp ? <DesktopLayout /> : <MobileLayout />}

                <RegistrationForm
                    customClass="feature-template-gallery__cta"
                    showNoCcRequired
                    source={ctaSource}
                />
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};

export default FeatureTemplateGallery;
